import React from 'react';
import styled from 'styled-components';
import mixins from '../mixins';
interface CircleStyleProps {
    bgColor?: string;
}

const CircleStyle = styled.div<CircleStyleProps>`
    width: 2.5em;
    height: 2.5em;
    text-align: center;
    ${mixins.flex}
    border-radius: 50%;
    background: ${({ theme, bgColor }) => bgColor || theme.colors.backgrounds_muted_honey};
`;

interface Props {
    number: string | number;
    bgColor?: string;
}

const CircleNumber: React.FC<Props> = ({ number, bgColor }) => {
    return (
        <CircleStyle className="CircleNumber-container" bgColor={bgColor}>
            {number}
        </CircleStyle>
    );
};

export default CircleNumber;
