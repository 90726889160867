import Icon from 'components/styles/general-elements/Icon';
import SocialIcon from 'components/styles/general-elements/SocialIcon';
import { bbTheme } from 'components/styles/theme';
import Link from 'next/link';
import React, { ReactNode } from 'react';
import ReactGA from 'react-ga';
// import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import { slugify } from 'utils/stringUtils';
import { ButtonProps, ButtonStyle } from './Buttons';
import usePointer from 'hooks/usePointer';
import dynamic from 'next/dynamic';
import { devLog } from 'utils/logs';

const ReactTooltip = dynamic(() => import('react-tooltip'), {
    ssr: false,
});

const LinkStyle = styled(ButtonStyle)`
    --icon-rotate: ${({ icon, back }) => (back ? '180deg' : 0)};
    border-color: ${({ theme, btnStyle }) => (btnStyle === 'clear' ? 'transparent' : 'currentColor')};
    display: inline-flex;
    align-items: center;
    justify-content: center;

    p & {
        font-family: inherit;
        padding: 0;
        color: inherit;
        i {
            display: none;
        }
        span {
            font-family: inherit;
            font-size: inherit;
            font-weight: inherit;
            font-style: inherit;
            text-decoration: underline;
            color: currentColor;
        }
    }
    svg {
        width: 2rem;
    }
`;

interface LinkProps extends ButtonProps {
    hrefProp: string;
    children?: ReactNode;
    back?: boolean;
    as?: string;
    scroll?: boolean;
    external?: boolean;
    target?: '_self' | '_blank';
    rel?: string;
    eventLabel?: string | 'To Checkout';
    trackerNames?: string[];
}

interface ElementLinkProps extends LinkProps {
    passThrough?: boolean;
}

export const MyLink: React.FC<LinkProps> = ({
    text,
    hrefProp,
    children,
    scroll,
    as,
    className,
    func,
    back,
    disabled,
    layoutId,
    btnStyle,
    title,
    icon,
    socialIcon,
    circle,
    tooltip,
    id,
    asElement = 'a',
    external,
    target,
    rel,
    eventLabel,
    trackerNames,
    tabIndex,
}) => {
    const ref = React.useRef(null);
    const mouse = usePointer(ref, {
        enterDelay: 100,
        leaveDelay: 100,
    });
    return (
        <Link
            href={external ? hrefProp : /^\//.test(hrefProp) ? hrefProp : `/${hrefProp}`}
            as={as}
            scroll={scroll}
            passHref
        >
            <LinkStyle
                tabIndex={tabIndex}
                layoutId={layoutId}
                ref={ref}
                text={text}
                onClick={(e: any) => {
                    external
                        ? ReactGA.outboundLink(
                              {
                                  label: eventLabel || title || text || 'Clicked Outbound Link',
                              },
                              function () {
                                  devLog('Clicked Outbound Link');
                              },
                              trackerNames,
                          )
                        : null;

                    func ? func({ e, mouse }) : null;
                }}
                className={`${className || ''} ${btnStyle || ''}`}
                disabled={disabled}
                btnStyle={btnStyle}
                title={title || text || `Open link${target === '_blank' && external ? ' in a new window' : ''}`}
                icon={icon}
                back={back}
                circle={circle}
                data-tip={tooltip}
                data-for={slugify(tooltip || '')}
                id={id}
                as={asElement}
                target={target}
                rel={asElement === 'a' && target === '_blank' ? 'noopener noreferrer' : undefined}
            >
                {icon || socialIcon ? (
                    <span className="wrapper">
                        <span className="text-wrapper">
                            <span className={`${btnStyle === 'clear' ? '' : 'heading-6'}  currentColor`}>
                                <> {text || children}</>
                            </span>
                        </span>
                        {icon && <Icon title={title || text} name={icon.name} fill={'currentColor'} />}
                        {socialIcon && (
                            <SocialIcon title={title || text} name={socialIcon.name} fill={'currentColor'} />
                        )}
                    </span>
                ) : (
                    <span className={`${btnStyle === 'clear' ? '' : 'heading-6'}  currentColor`}>
                        {text || children}
                    </span>
                )}

                {tooltip && (
                    <ReactTooltip
                        id={slugify(tooltip)}
                        backgroundColor={bbTheme.colors.white}
                        textColor={bbTheme.colors.black}
                        border
                        borderColor={bbTheme.colors.black}
                    />
                )}
            </LinkStyle>
        </Link>
    );
};
MyLink.defaultProps = { icon: { name: 'arrow' } };

export const ElementLink: React.FC<ElementLinkProps> = ({
    text,
    className,
    func,
    hrefProp,
    children,
    back,
    passThrough,
    as,
    tabIndex,
}) => {
    const ref = React.useRef(null);
    const mouse = usePointer(ref, {
        enterDelay: 100,
        leaveDelay: 100,
    });
    if (passThrough) {
        return <>{children}</>;
    }

    return (
        <Link href={hrefProp} as={as} passHref>
            <a
                href="#0"
                ref={ref}
                onClick={(e: any) => (func ? func({ e, mouse }) : null)}
                className={className || ''}
                tabIndex={tabIndex}
            >
                {children || text}
            </a>
        </Link>
    );
};
