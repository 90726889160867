import MyErrorBoundary from 'components/analytics/util-components/ErrorBoundary';
import Image, { ImageProps } from 'next/image';
import { useRouter } from 'next/router';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { MyAppContext } from '../../state/context/global';
import rmUndef from '../../utils/rmUndef';
import { ImageSrc } from 'components/Layout/masonry/ImageGallery';
import { shopifyImageLoader } from 'components/shop/helpers/shopifyImageLoader';
import { defaultImageLoader } from 'components/journal/helperFunctions';
import { MyLink } from './Link';
import { device } from 'components/styles/mixins';

const ImageWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    overflow-y: hidden;
    .photo-credits {
        height: 20%;
        max-height: 20rem;
        width: 100%;
        position: absolute;
        bottom: 0px;
        right: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        transition: ${({ theme }) => theme.transitions.base};
        /* transition-delay: 400ms; */
        opacity: 0;
        background: linear-gradient(
            0deg,
            hsla(240, 100%, 2%, 0.01) 2%,
            hsla(240, 100%, 2%, 0.6) 12%,
            hsla(240, 100%, 2%, 0.6) 68%,
            hsla(0deg, 0%, 100%, 0.01) 90%
        );

        & * {
            /* pointer-events: auto; */
            padding-top: 0;

            color: ${({ theme }) => theme.colors.white};
        }
    }
    &:hover .photo-credits {
        transition: ${({ theme }) => theme.transitions.base};
        opacity: 1;
    }

    .photo-credits-web,
    .photo-credits-ig {
        position: relative;
        height: 35%;
        max-height: 15rem;
    }
    &:hover .photo-credits-web,
    &:hover .photo-credits-ig {
        transform: translateY(-${({ theme }) => theme.spacing.xs});
    }

    &:hover .thumbnail-container {
        transition: ${({ theme }) => theme.transitions.base};
        opacity: 1;
    }

    .thumbnail-container {
        opacity: 0;
        @media (hover: none) and (pointer: coarse) {
            opacity: 1;
        }
        position: absolute;
        bottom: 0;
        padding: 1rem;
        background: linear-gradient(
            0deg,
            hsla(240, 100%, 2%, 0.01) 2%,
            hsla(240, 100%, 2%, 0.6) 12%,
            hsla(240, 100%, 2%, 0.6) 68%,
            hsla(0deg, 0%, 100%, 0.01) 90%
        );
        display: flex;
        justify-content: space-evenly;
        width: 100%;
        margin: 0 auto;
        @media ${device().medium} {
            top: 0;
            left: 0;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            width: 8rem;
            height: 100%;
            margin: 0 auto;
        }

        /* max-height: 70px; */
        img {
            &:hover {
                cursor: pointer;
                opacity: 0.9;
            }
            object-fit: cover;
        }
    }
`;

export interface MyImageProps {
    images?: ImageSrc['src'][];
    currentImage?: number;
    // filename?: ImageSrc['filename'];
    // options?: { w?: string | number; h?: string | number };
    // altText?: string;
    clickZoom?: boolean;
    thumbnails?: ImageSrc['thumbnail'][];
    link?: string;
    linkPassThrough?: boolean;
    // transformations?: string;
    loader?: 'shopify' | 'sanity' | 'cloudinary';
    className?: string;
    // src?: ImageSrc['src'];
    nextImageProps: ImageProps;
    photoCredits?: {
        creditName: string;
        creditUrl?: string;
        creditInstagram?: string;
    };
}

interface ThumbnailProps {
    thumbnails: string[];
    handler: (e: React.MouseEvent<HTMLImageElement, MouseEvent>, idx: number) => void;
}

const ImageThumbnails: React.FC<ThumbnailProps> = ({ thumbnails, handler }) => {
    // No. of thumbnails must <= N. of images in query
    return (
        <div className="thumbnail-container">
            {thumbnails?.map((img, idx) => (
                <React.Fragment key={img}>
                    {idx < 4 && (
                        <Image
                            onClick={(e) => {
                                handler(e, idx);
                            }}
                            loader={shopifyImageLoader}
                            className="thumbnail"
                            src={img}
                            width={70}
                            height={70}
                            layout="intrinsic"
                        />
                    )}
                </React.Fragment>
            ))}
        </div>
    );
};

const MyImage: React.FC<MyImageProps> = ({
    images,
    currentImage,
    // filename,
    // options,
    // altText,
    thumbnails,
    clickZoom,
    link,
    linkPassThrough,
    loader = 'cloudinary',
    className,
    photoCredits,
    // src,
    // Next Image Props
    nextImageProps,
    // layout = 'intrinsic',
    // priority,
}) => {
    const myApp = useContext(MyAppContext);
    // const imgRef = useRef<HTMLImageElement | null>(null);
    const router = useRouter();
    function setSrc(e: React.SyntheticEvent<HTMLPictureElement, MouseEvent>, currImgIdx = 0): void {
        if (!myApp || !images) return;

        try {
            myApp.toggleAppModal(rmUndef(images), currImgIdx || currentImage);
        } catch (e) {
            console.error(e);
        }
    }
    const currLoader = loader === 'shopify' ? shopifyImageLoader : loader === 'sanity' ? defaultImageLoader : undefined;
    return (
        <ImageWrapper className="MyImage-container">
            <Image
                {...nextImageProps}
                alt={nextImageProps.alt || ''}
                loader={currLoader}
                onClick={(e) => (link && !linkPassThrough ? router.push(link) : clickZoom ? setSrc(e) : null)}
                className={`base-image ${className || ''} ${clickZoom ? 'is-interactive' : ''}`}
            />
            {photoCredits && (
                <div className="photo-credits">
                    {photoCredits.creditInstagram && (
                        <MyLink
                            className="photo-credits-ig"
                            hrefProp={`${'https://www.instagram.com/' + photoCredits.creditInstagram}`}
                            text={'@' + photoCredits.creditInstagram}
                            btnStyle="clear"
                            icon={{ name: 'none' }}
                            external
                            target="_blank"
                        />
                    )}
                    <MyLink
                        className="photo-credits-web"
                        hrefProp={`${photoCredits.creditUrl ? photoCredits.creditUrl : ''}`}
                        text={photoCredits.creditName}
                        btnStyle="clear"
                        icon={{ name: 'none' }}
                        external
                        target="_blank"
                    />
                </div>
            )}
            <MyErrorBoundary>
                {thumbnails ? <ImageThumbnails thumbnails={thumbnails} handler={setSrc} /> : null}
            </MyErrorBoundary>
        </ImageWrapper>
    );
};

export default MyImage;

/**
 * Optimizations
 *
 * Create Cloudinary URL builder for large images
 *https://res.cloudinary.com/dwa5hefth/image/upload/v1593217690/BumblebeeflowerFarm-Static/coastWedding-min.jpg
 https://res.cloudinary.com/dwa5hefth/image/upload/profile-pic-min.jpg
 https://res.cloudinary.com/demo/image/upload/c_thumb,g_face,h_150,w_150/r_20/e_sepia/l_cloudinary_icon,g_south_east,x_5,y_5,w_50,o_60,e_brightness:200/a_10/front_face.png
 * Use CL images for normal viewing
 *
 * pass constructed urls to Lightbox
 */
