import { keyframes } from 'styled-components';

export const KFLoading = keyframes`
  from {
    opacity:0;
    transform: scale(.4);
    /* rotate: 0; */
  }

  to {
    opacity: 1;
   transform: scale(1);
    /* rotate: 360deg; */
  }
`;

export const KFSuccess = keyframes`
from {
  opacity: 1;
   transform: scale(1);
}

to {
  opacity: 0;
   transform: scale(1.2);
}

`;

export const KFFadeIn = keyframes`
  from {
    opacity: 0;
    transform: translate(0, 20px);
  }
  to {
    opacity: 1;
    transform: translate(0, 20px);
  }
`;
