import Link from 'next/link';
import React, { useContext, useState } from 'react';
import { CartContext } from '../../../state/context/global';
import { truncate } from '../../../utils/stringFunctions';
import Button from '../../Layout/Buttons';
import MyImage from '../../Layout/MyImage';
import { CartItemProductNode } from '../interfaces';
import { optionsToQueryString } from '../helpers/queryStringFunctions';
import QuantitySelector from '../QuantitySelector';
import Title from 'components/styles/Title';
import { Row } from 'components/Layout/grid/Row';
import { Column } from 'components/Layout/grid/Column';
import { CartProductStyles } from './CartProductStyles';
import { bbTheme } from 'components/styles/theme';
import { createSelectedOptionInput } from '../helpers/createOptions';

export interface CartLocationProps {
    isCheckoutPage?: boolean | undefined;
    isModalCart?: boolean | undefined;
    isExpanded?: boolean;
}

interface SingleCartProductProps extends CartLocationProps {
    product: CartItemProductNode;
    idx: number;
}

/**
 * Image
 * Title
 * Variant info
 * Qty
 */

const CartProduct: React.FC<SingleCartProductProps> = ({ product, isCheckoutPage, idx, isModalCart, isExpanded }) => {
    const cart = useContext(CartContext);
    const [isEditEnabled, setIsEditEnabled] = useState(false);
    if (!cart) return <div />;

    function removeFromCart(item: CartItemProductNode): void {
        if (!cart || !cart.items) return;
        cart.removeItem(item, cart.items);
    }

    function updateQuantity(action: string) {
        if (!cart || !cart.items) return;
        cart.updateCartQuantity(product, cart.items, action);
    }

    function calcTotal(item: CartItemProductNode): string {
        const { quantity } = item.customization;
        const amount = item.customization.currentVariant.store.price;

        return (quantity * amount).toFixed(2);
    }

    return (
        <CartProductStyles>
            <div className="cart-image">
                {!!product.store.previewImageUrl && (
                    <MyImage
                        images={[product.store.previewImageUrl]}
                        // maxSrc={variant.image?.originalSrc}
                        // src={
                        //     variant.image?.transformedSrc || product.store?.images?.edges[0].node.transformedSrc
                        // }
                        className={``}
                        clickZoom
                        loader="shopify"
                        nextImageProps={{
                            alt: product.customization.currentVariant.store.title,
                            src: product.store.previewImageUrl,
                            width: 100,
                            height: 100,
                            layout: 'intrinsic',
                            // objectFit: 'cover',
                        }}
                    />
                )}
            </div>

            <div className="product-details">
                <Link
                    href={`/shop/products/${product.store.slug.current}?${optionsToQueryString(
                        createSelectedOptionInput(product, product.customization.currentVariant),
                    )}`}
                    passHref
                >
                    <a title={product.store.title} href="#0">
                        <Title as="h5" className="product-title">
                            {truncate(
                                product.store.title,
                                2,
                                isCheckoutPage ? isExpanded : isModalCart ? isModalCart : false,
                            )}
                        </Title>
                    </a>
                </Link>
                <Row className="variant-details no-margin">
                    <Column widths={[5]} spacing={4}>
                        <p className="quantity caption-text micro light">Qty: {product.customization.quantity}</p>
                    </Column>
                    {createSelectedOptionInput(product, product.customization.currentVariant).map((option, idx) => (
                        <Column key={option.name + idx + option.value} widths={[5]} spacing={4}>
                            <p className={`option option-${idx} caption-text micro light`}>
                                {/Default\sTitle/i.test(option.value) ? (
                                    ''
                                ) : (
                                    <>
                                        {' '}
                                        {option.name}: {option.value}
                                    </>
                                )}
                            </p>
                        </Column>
                    ))}
                </Row>

                <div className="cart-buttons">
                    <Button
                        func={() => setIsEditEnabled(!isEditEnabled)}
                        text={isEditEnabled ? 'Close' : 'Edit'}
                        btnStyle="clear"
                    />
                    {' — '}
                    <Button
                        func={() => {
                            if (cart.modalComponent) {
                                (async function () {
                                    new Promise((resolve, reject) => {
                                        removeFromCart(product);
                                        setTimeout(() => {
                                            cart.toggleCartModal(undefined);
                                            resolve(cart.items);
                                        }, 300);
                                    });
                                })();
                            } else {
                                removeFromCart(product);
                            }
                        }}
                        text="Remove"
                        btnStyle="clear"
                    />
                </div>
                {isEditEnabled && (
                    <div className={`edit-options card ${isEditEnabled ? '' : 'success'}`}>
                        <Button
                            text=""
                            title="Close"
                            func={() => setIsEditEnabled(false)}
                            icon={{ name: 'close' }}
                            btnStyle="clear"
                        />
                        <QuantitySelector
                            className="quantity-selector product-ui"
                            quantity={product.customization.quantity}
                            increment={() => updateQuantity('+')}
                            decrement={() => {
                                if (product.customization.quantity <= 1) {
                                    removeFromCart(product);
                                    return;
                                } else {
                                    updateQuantity('-');
                                }
                            }}
                        />
                    </div>
                )}
            </div>

            <div className="price">
                <p className="variant-price caption-text micro light">
                    €{product.customization.currentVariant.store.price}
                </p>
            </div>

            {/* <p className="total-price">€{calcTotal(product)}</p> */}
        </CartProductStyles>
    );
};

export default CartProduct;
