import Head from 'next/head';
import React, { useState, useEffect } from 'react';
// import '../../../public/embed';
interface GramPost {
    url: string;
}
interface GramPostData {
    version: number;
    author_name: string;
    provider_name: string;
    provider_url: string;
    type: string;
    width: number;
    html: string;
    thumbnail_width: number;
    thumbnail_height: number;
}
interface GramPostResponseData {
    success: boolean;
    message: string;
    data?: GramPostData;
}
// https://www.instagram.com/p/CMpX4uLncds/
const IGPost: React.FC<GramPost> = ({ url }) => {
    const [igData, setIgData] = useState<GramPostData | undefined>();

    useEffect(() => {
        (async () => {
            const data: GramPostResponseData = await fetch(`/api/ig-post`, {
                method: 'post',
                body: JSON.stringify({ url }),
            }).then((res) => res.json());

            data.data ? setIgData(data.data) : null;
            setTimeout(() => {
                // @ts-ignore
                const instgrm = window.instgrm || undefined;
                instgrm ? instgrm.Embeds.process() : null;
            }, 1000);
        })();
        // return () => {
        //     cleanup
        // }
    }, [url]);
    if (!igData || !igData.html) return <p>Loading Instagram Post...</p>;

    return (
        <>
            <Head>
                <script async src="/embed.js" />
            </Head>
            <div className="IGPost-container" dangerouslySetInnerHTML={{ __html: igData?.html }}></div>
        </>
    );
};

export default IGPost;
