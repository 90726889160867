import { device } from 'components/styles/mixins';
import styled from 'styled-components';

const BaseFormStyles = styled.div`
    form {
        position: relative;
        color: ${({ theme }) => theme.colors.black};
        display: flex;
        flex-flow: column wrap;
    }
    .message {
        margin: 0 0 0 1rem;
    }

    .form-field {
        position: relative;
        display: inline-flex;
        flex-flow: column nowrap;
        max-width: 100%;
        box-shadow: 0 0 2px 5px transparent;
        flex: 0 0 100%;
        @media ${device().small} {
            flex: 1 1 45%;
        }

        &.single {
            flex: 1;
        }
    }

    .form-success-message {
        flex: 1;
    }

    input[name='firstName'] {
    }
    input[name='lastName'] {
    }
    input[name='email'] {
    }
    input[name='address1'] {
    }
    input[name='address2'] {
    }
    input[name='city'] {
    }
    input[name='zip'] {
    }
    input[name='phone'] {
    }
    .county-select,
    .country-select {
    }

    button[type='submit'] {
    }

    .secondary-btn {
        float: right;
    }

    .error {
    }

    .field-error input {
    }

    .note-to-seller {
    }
`;

export default BaseFormStyles;
