import 'intersection-observer';
import { RewriteFrames } from '@sentry/integrations';
import * as Sentry from '@sentry/node';
// import { AnimatePresence } from 'framer-motion';
import { NextComponentType, NextPageContext } from 'next';
import App from 'next/app';
import getConfig from 'next/config';
import Router from 'next/router';
import { parseCookies } from 'nookies';
import React, { ReactNode } from 'react';
import 'react-day-picker/lib/style.css';
import 'nprogress/nprogress.css';
import 'react-image-gallery/styles/css/image-gallery.css';
import { ThemeProvider } from 'styled-components';
import { isBrowser } from 'utils/booleans';
import { getLayout as getSiteLayout } from '../components/Layout/SiteLayout';
import NavDrawer from '../components/navigation/NavDrawer';
import { SidebarNav } from '../components/navigation/Navigation';
import Cart from '../components/shop/Cart';
import { CartItemProductNode } from '../components/shop/interfaces';
import BaseStyles from '../components/styles/Base';
import BaseLayout from '../components/styles/BaseLayout';
import { bbTheme } from '../components/styles/theme';
import { dfGiftCardStatus, dfUserDetails, dfUserNote } from '../core/defaults';
import { _ContextMenuType, User, UserNote } from '../src/types/globalTypes';
import { CartContext, MyAppContext, UserContext } from '../state/context/global';
import { CookieStatus, initGA, logAddToCart, logPageView } from '../utils/analytics';
import { devLog } from 'utils/logs';

type AppProps = {
    pageProps: any;
    // eslint-disable-next-line @typescript-eslint/ban-types
    Component: NextComponentType<NextPageContext, any, {}> & { getLayout?: (page: any) => any };
    router: any;
};

// Constants
export const isDev = process.env.NODE_ENV === 'development';
export const isStaging = isBrowser ? /hazetheai|vercel/.test(window.location.href) : false;

export const isProd = process.env.NODE_ENV === 'production';

// GA Page View
isProd && Router.events.on('routeChangeComplete', logPageView);

// Sentry

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
    const config = getConfig();
    const distDir = `${config.serverRuntimeConfig.rootDir}/.next`;
    Sentry.init({
        enabled: process.env.NODE_ENV === 'production',
        integrations: [
            new RewriteFrames({
                iteratee: (frame) => {
                    frame.filename = frame.filename ? frame.filename.replace(distDir, 'app:///_next') : '';
                    return frame;
                },
            }),
        ],
        dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    });
}

class MyApp extends App {
    state = {
        cart: {
            isOpen: false,
            hasItems: false,
            items: [],
            modalComponent: null,
            total: '0',
            discountCode: '',
            giftCards: [dfGiftCardStatus],
        },
        user: {
            details: dfUserDetails,
            isLoggedIn: false,
            note: dfUserNote,
            userDiscountCode: '',
            giftCards: [dfGiftCardStatus],
            cookieStatus: '' as CookieStatus,
        },
        app: {
            loaded: true,
            isModalOpen: false,
            isNavOpen: false,
            images: [''],
            currentImage: 0,
            modalCount: 0,
            modalComponent: null,
            checkoutId: null,
            isBannerDismissed: false,
            popupProduct: undefined,
            isPopupProductDismissed: false,
            contextMenu: 'main' as _ContextMenuType,
        },
    };

    componentDidMount = () => {
        const cookies = parseCookies();
        const cookieStatus = cookies && cookies.bbff === 'true' ? 'accept' : '';
        const c = sessionStorage.getItem('cart');
        const u = sessionStorage.getItem('user');
        const a = sessionStorage.getItem('app');
        const cart = c ? JSON.parse(c) : null;
        const user = u ? JSON.parse(u) : null;
        const app = a ? JSON.parse(a) : null;

        try {
            this.setState((prevState) => {
                return {
                    ...prevState,
                    user: user ? { ...user, cookieStatus } : { ...this.state.user, cookieStatus },
                    cart: cart
                        ? { ...cart, isOpen: false, modalComponent: null }
                        : { ...this.state.cart, isOpen: false, modalComponent: null },
                    app: app ? { ...app, loaded: true } : { ...this.state.app, loaded: true },
                };
            });
        } catch (err) {
            console.error('Error setting Initial state', err);
        }
        if (isProd || isDev) {
            // GA;

            try {
                const hasAccepted = cookies.bbff ? true : user ? user['cookieStatus'] : false;

                devLog('cookies.bbff', cookies.bbff);
                devLog('hasAccepted', hasAccepted);
                if (this.state.user.cookieStatus === 'accept' || hasAccepted) {
                    initGA(false);
                    logPageView();
                    Router.events.on('routeChangeComplete', logPageView);
                }
            } catch (err) {
                console.error('Error initializing GA', err);
            }
        }
    };

    componentDidCatch(error, errorInfo) {
        if (isProd) {
            Sentry.withScope((scope) => {
                Object.keys(errorInfo).forEach((key) => {
                    scope.setExtra(key, errorInfo[key]);
                });

                Sentry.captureException(error);
            });
        }
        if (super.componentDidCatch) {
            super.componentDidCatch(error, errorInfo);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        // devLog('prevStates', prevState);

        sessionStorage.setItem('cart', JSON.stringify(this.state.cart));

        sessionStorage.setItem('user', JSON.stringify(this.state.user));

        const a = sessionStorage.getItem('app');
        const appStorage = a ? JSON.parse(a) : null;
        if (
            appStorage &&
            typeof appStorage['checkoutId'] === 'string' &&
            typeof this.state.app.checkoutId !== 'string'
        ) {
            sessionStorage.setItem('app', JSON.stringify({ ...this.state.app, checkoutId: appStorage['checkoutId'] }));
        } else {
            sessionStorage.setItem('app', JSON.stringify(this.state.app));
        }
    }

    /**
     *
     *      App Functions
     *
     */

    setContextMenu = (menuType: _ContextMenuType) => {
        devLog('calling $$setContextMenu$$', menuType);
        this.setState((prevState) => {
            return {
                ...prevState,
                app: {
                    ...this.state.app,
                    contextMenu: menuType,
                    isNavOpen: true,
                },
            };
        });
    };

    setModalSrc = (images: string[] | undefined, currentImage?: number | undefined): void => {
        devLog('calling $$setModalSrc$$', images);

        if (images && images.filter(Boolean).length > 0) {
            this.setState((prevState) => {
                return {
                    ...prevState,
                    app: {
                        ...this.state.app,
                        isModalOpen: this.state.app.isModalOpen,
                        images: images,
                        currentImage: currentImage || 0,
                    },
                };
            });
        }
    };

    toggleAppModal = (images: string[] | undefined, currentImage?: number | undefined) => {
        devLog('calling $$toggleAppModal$$', images);

        this.setState((prevState) => {
            return {
                ...prevState,
                app: {
                    ...this.state.app,
                    isModalOpen: !this.state.app.isModalOpen,
                    images: images || this.state.app.images,
                    currentImage: currentImage || 0,
                },
            };
        });
    };

    toggleNavDrawer = (action?: string): void => {
        devLog('callling  $$toggleNav$$', 'action: ', action);
        if (action === 'open') {
            this.setState((prevState) => {
                return {
                    ...prevState,
                    app: { ...this.state.app, isNavOpen: true },
                };
            });
            return;
        }
        if (action === 'close' || action === undefined) {
            this.setState((prevState) => {
                return {
                    ...prevState,
                    app: { ...this.state.app, isNavOpen: false },
                };
            });
            return;
        }

        this.setState((prevState) => {
            return {
                ...prevState,
                app: { ...this.state.app, isNavOpen: !this.state.app.isNavOpen },
            };
        });
    };

    toggleModalComponent = (modalComponent: ReactNode | undefined): void => {
        devLog('callling  $$toggleModalComponent$$');

        if (this.state.app.modalComponent) {
            this.setState({ app: { ...this.state.app, isModalOpen: false, modalComponent: null } });
            return;
        }

        this.setState({ app: { ...this.state.app, isModalOpen: true, modalComponent: modalComponent } });
    };

    increment = () => {
        devLog('callling  $$increment$$');

        this.setState((prevState) => {
            return {
                ...prevState,
                app: {
                    ...this.state.app,
                    modalCount: this.state.app.modalCount + 1,
                },
            };
        });
    };

    setCheckoutId = (id: string | null) => {
        devLog('callling  $$increment$$');

        this.setState((prevState) => {
            return {
                ...prevState,
                app: { ...this.state.app, checkoutId: id },
            };
        });
    };

    setBannerDismissed = () => {
        this.setState((prevState) => {
            return {
                ...prevState,
                app: { ...this.state.app, isBannerDismissed: true },
            };
        });
    };
    setPopupProductDismissed = () => {
        this.setState((prevState) => {
            return {
                ...prevState,
                app: { ...this.state.app, isPopupProductDismissed: true },
            };
        });
    };

    setPopupProduct = (productHandle: string) => {
        this.setState((prevState) => {
            return {
                ...prevState,
                app: { ...this.state.app, popupProduct: productHandle },
            };
        });
    };

    /**
     *
     *      User Functions
     *
     */

    setUserDetails = (userObj: User): void => {
        devLog('calling setUserDetails', userObj);

        this.setState({
            ...this.state,
            cart: { ...this.state.cart },
            app: { ...this.state.app },
            user: { ...this.state.user, details: { ...this.state.user.details, ...userObj } },
        });
    };

    setUserNote = (note: UserNote): void => {
        devLog('calling setUserNote', note);
        this.setState({
            ...this.state,
            cart: { ...this.state.cart },
            app: { ...this.state.app },
            user: { ...this.state.user, note: { ...this.state.user.note, ...note } },
        });
    };

    setCookieStatus = (action: CookieStatus) => {
        devLog('calling setCookieStatus', action);
        if (action === 'accept') {
            // GA
            initGA(false);
            logPageView();
        }

        this.setState((prevState) => {
            return {
                ...prevState,
                user: { ...this.state.user, cookieStatus: action },
            };
        });
    };

    /**
     *
     *      Cart Functions
     *
     */

    setTotal = (total: string | undefined) => {
        devLog('callling  $$setTotal$$', total);
        this.setState({
            ...this.state,
            cart: { ...this.state.cart, total: total || '0.00' },
            user: { ...this.state.user },
            app: { ...this.state.app },
        });
    };

    toggleCart = (action?: string) => {
        devLog('callling  $$removeItem$$', action);
        if (action === 'open') {
            return this.setState({ ...this.state, cart: { ...this.state.cart, isOpen: true } });
        }
        if (action === 'close') {
            return this.setState({ ...this.state, cart: { ...this.state.cart, isOpen: false } });
        }

        this.setState({ ...this.state, cart: { ...this.state.cart, isOpen: !this.state.cart.isOpen } });
    };

    toggleCartModal = (component: CartItemProductNode | undefined) => {
        devLog('callling  $$Toggle Cart Modal$$', component);

        if (!component && !this.state.cart.modalComponent) {
            return;
        }
        if (!component) {
            this.setState({
                cart: { ...this.state.cart, modalComponent: null },
            });
            return;
        }
        const prevState: CartItemProductNode[] = this.state.cart.items;
        if (
            component !== undefined &&
            !prevState.find(
                (cartItem) =>
                    cartItem.customization.currentVariant.store.gid ===
                    component.customization.currentVariant.store.gid,
            )
        ) {
            this.setState({
                cart: { ...this.state.cart, modalComponent: component },
            });
        }

        setTimeout(() => {
            this.setState({ cart: { ...this.state.cart, modalComponent: null } });
        }, 3500);
    };

    addItem = (item: CartItemProductNode) => {
        devLog('Callling  $$addItem$$', item);
        const prevState: CartItemProductNode[] = this.state.cart.items;
        if (
            !prevState.find(
                (cartItem) =>
                    cartItem.customization.currentVariant.store.gid === item.customization.currentVariant.store.gid,
            )
        ) {
            const newState = prevState.concat([item]);
            this.setState({
                ...this.state,
                cart: { ...this.state.cart, items: newState, hasItems: true, modalComponent: item },
            });

            logAddToCart(item, 'addToCart');
        }
    };
    addMultipleItems = (items: CartItemProductNode[]) => {
        devLog('Callling  $$addMultipleItems$$', items);
        const prevState: CartItemProductNode[] = this.state.cart.items;

        if (prevState.length === 0) {
            const newState = prevState.concat(items);
            this.setState({
                ...this.state,
                cart: { ...this.state.cart, items: newState, hasItems: true },
            });
        }
        // If item is the same as existing > find and update
        // If not, add item
        // TODO Can break ???
        if (prevState.length) {
            const newState = prevState.slice(0);
            items.forEach((i) => {
                const sameItem = newState.find(
                    (el) => el.customization.currentVariant.store.gid === i.customization.currentVariant.store.gid,
                );
                if (sameItem) {
                    newState[newState.indexOf(i)].customization.quantity++;
                } else {
                    newState.push(i);
                }
            });
            this.setState({
                ...this.state,
                cart: { ...this.state.cart, items: newState, hasItems: true },
            });
        }

        items.forEach((el) => logAddToCart(el, 'addToCart'));
    };

    removeItem = (item: CartItemProductNode) => {
        devLog('callling  $$removeItem$$', item);
        if (this.checkIfEmpty(this.state.cart.items, item)) {
            return;
        }

        this.setState({
            ...this.state,
            cart: {
                ...this.state.cart,
                items: this.state.cart.items.filter(
                    (cartItem: CartItemProductNode) =>
                        cartItem.customization.currentVariant.store.gid !== item.customization.currentVariant.store.gid,
                ),
                hasItems: !this.checkIfLastItem(this.state.cart.items, item),
                total: this.checkIfLastItem(this.state.cart.items, item) ? '0.00' : this.state.cart.total,
                // isOpen: this.state.cart.isOpen,
            },
        });
    };

    updateCartQuantity = (item: CartItemProductNode, list: CartItemProductNode[], action: string) => {
        devLog('callling  $$Update Cart Quantity$$', action);

        let amt = item.customization.quantity;
        if (action === '+') {
            amt++;
        }
        if (action === '-') {
            amt > 1 ? amt-- : amt === 1 ? this.removeItem(item) : amt;
        }
        const idx = list.indexOf(item);

        const withoutItem = list.filter(
            (listItem) =>
                listItem.customization.currentVariant.store.gid !== item.customization.currentVariant.store.gid,
        );

        const newItem = item;
        newItem.customization.quantity = amt;
        newItem.customization.totalPrice = (newItem.customization.currentVariant.store.price * amt).toFixed(2);
        withoutItem.splice(idx, 0, newItem);
        const newList = withoutItem;
        this.setState((prevState) => {
            return {
                ...prevState,
                cart: {
                    ...this.state.cart,
                    items: newList,
                    hasItems: !this.checkIfEmpty(newList, newItem),
                    isOpen: this.state.cart.isOpen,
                    // checkoutId: this.checkIfEmpty(newList, newItem) ? '' : this.state.cart.checkoutId,
                },
            };
        });
    };

    updateCartVariant = (e: any, item: CartItemProductNode, list: CartItemProductNode[]) => {
        devLog('callling  $$updateCartVariant$$', item);

        const existingQuantity = 0;
        const idx = list.indexOf(item);
        item.customization.currentVariant.store.price = e.amount;
        item.customization.currentVariant.store.title = e.label;
        item.customization.currentVariant.store.gid = e.variantId;
        // If there is another variant here which matches ID to what we want to change to,
        // update that variant quantity to include the current one
        const newList = list.filter((el) => el.customization.currentVariant.store.gid !== e.variantId);
        newList.splice(idx, 0, item);
        this.setState({
            ...this.state,
            cart: {
                ...this.state.cart,
                items: newList,
                hasItems: this.state.cart.hasItems,
                isOpen: this.state.cart.isOpen,
            },
        });
    };

    checkIfEmpty = (arr: CartItemProductNode[], item: CartItemProductNode): boolean => {
        devLog('callling  $$checkIfEmpty$$', item);

        if (arr.length === 0) {
            return true;
        }
        return false;
    };
    checkIfLastItem = (arr: CartItemProductNode[], item: CartItemProductNode): boolean => {
        devLog('callling  $$checkIfLastItem$$', item);
        if (arr.includes(item) && arr.length === 1) {
            return true;
        }
        return false;
    };

    setCheckoutCode = (discountCode = '', giftCards = this.state.cart.giftCards) => {
        devLog('callling  $$setCheckout$$');

        this.setState((prevState) => {
            return {
                ...prevState,

                cart: {
                    ...this.state.cart,
                    discountCode: discountCode || this.state.cart.discountCode,
                    giftCards: giftCards,
                },
            };
        });
    };

    render() {
        const { Component, pageProps, router }: AppProps = this.props;

        const getLayout = Component.getLayout || getSiteLayout;
        return (
            <React.Fragment>
                <MyAppContext.Provider
                    value={{
                        loaded: this.state.app.loaded,
                        images: this.state.app.images,
                        isModalOpen: this.state.app.isModalOpen,
                        currentImage: this.state.app.currentImage,
                        isNavOpen: this.state.app.isNavOpen,
                        modalComponent: this.state.app.modalComponent,
                        modalCount: this.state.app.modalCount,
                        checkoutId: this.state.app.checkoutId,
                        isBannerDismissed: this.state.app.isBannerDismissed,
                        isPopupProductDismissed: this.state.app.isPopupProductDismissed,
                        popupProduct: this.state.app.popupProduct,
                        contextMenu: this.state.app.contextMenu,
                        setContextMenu: this.setContextMenu,
                        setPopupProduct: this.setPopupProduct,
                        setPopupProductDismissed: this.setPopupProductDismissed,
                        setBannerDismissed: this.setBannerDismissed,
                        setCheckoutId: this.setCheckoutId,
                        setModalSrc: this.setModalSrc,
                        increment: this.increment,
                        toggleAppModal: this.toggleAppModal,
                        toggleNavDrawer: this.toggleNavDrawer,
                        toggleModalComponent: this.toggleModalComponent,
                    }}
                >
                    <UserContext.Provider
                        value={{
                            userGiftCards: this.state.user.giftCards,
                            cookieStatus: this.state.user.cookieStatus,
                            details: { ...this.state.user.details },
                            note: this.state.user.note,
                            isLoggedIn: this.state.user.isLoggedIn,
                            userDiscountCode: this.state.user.userDiscountCode,
                            setCookieStatus: this.setCookieStatus,
                            setUser: this.setUserDetails,
                            setNote: this.setUserNote,
                            // setShipping: this.setUserShipping,
                        }}
                    >
                        <CartContext.Provider
                            value={{
                                isOpen: this.state.cart.isOpen,
                                hasItems: this.state.cart.hasItems,
                                items: this.state.cart.items,
                                total: this.state.cart.total,
                                modalComponent: this.state.cart.modalComponent,
                                // checkoutId: this.state.cart.checkoutId,
                                // webUrl: this.state.cart.webUrl,
                                discountCode: this.state.cart.discountCode,
                                giftCards: this.state.cart.giftCards,
                                setTotal: this.setTotal,
                                setCheckoutCode: this.setCheckoutCode,
                                toggleCartModal: this.toggleCartModal,
                                addItem: this.addItem,
                                addMultipleItems: this.addMultipleItems,
                                removeItem: this.removeItem,
                                toggleCart: this.toggleCart,
                                updateCartQuantity: this.updateCartQuantity,
                                updateCartVariant: this.updateCartVariant,
                            }}
                        >
                            <ThemeProvider theme={bbTheme}>
                                <BaseStyles />
                                <BaseLayout />
                                <NavDrawer>
                                    <SidebarNav location={'main'} />
                                </NavDrawer>
                                <Cart />
                                {/* <AnimatePresence>{getLayout(<Component {...pageProps} />)}</AnimatePresence> */}
                                {getLayout(<Component {...pageProps} />)}
                                {/* {devLog('this.state.cart', this.state.cart)} */}
                                {/* {devLog('this.state.app', this.state.app)} */}
                                {/* {devLog('this.state.user', this.state.user)} */}
                                {/* {devLog('this.state', this.state)} */}
                            </ThemeProvider>
                        </CartContext.Provider>
                    </UserContext.Provider>
                </MyAppContext.Provider>
            </React.Fragment>
        );
    }
}

// export default withApollo(MyApp);
export default MyApp;

// function logValue({ name, id, value }) {

// }

// export function reportWebVitals({ id, name, label, value }) {
//     if (isBrowser) {
//         try {
//             const cookies = parseCookies();
//             cookies.bbff &&
//                 logEvent(
//                     label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',
//                     name,
//                     Math.round(name === 'CLS' ? value * 1000 : value),
//                     id,
//                     true,
//                 );
//             // window.ga('send', 'event', {
//             //     eventCategory: ,
//             //     eventAction: name,
//             //     eventValue: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
//             //     eventLabel: id, // id unique to current page load
//             //     nonInteraction: true, // avoids affecting bounce rate.
//             // });
//             // logValue({ id, name, value });
//         } catch (err) {
//             console.error('Error initializing GA for web vitals', err);
//         }
//     }
// }
