import { bbTheme } from '../styles/theme';
import { CSSProperties } from 'react';
import { Styles } from 'react-select';
const border = 'none';
const transition = 'all 300ms ease-in-out';
// @ts-ignore
const reactSelectReset: Partial<Styles> = {
    // option: (provided: CSSProperties, state: any) => ({
    //     ...provided,
    //     border,
    //     backgroundColor: state.isSelected ? bbTheme.colors.black : bbTheme.colors.white,
    //     cursor: 'pointer',
    //     color: state.isSelected ? bbTheme.colors.white : bbTheme.colors.black,
    //     borderRadius: 'none',
    //     transition,
    //     '&:hover': {
    //         backgroundColor: state.isSelected ? bbTheme.colors.black : bbTheme.colors.black,
    //         cursor: 'pointer',
    //         color: state.isSelected ? bbTheme.colors.white : bbTheme.colors.white,
    //     },
    // }),
    // container: (provided: CSSProperties, state: any) => ({
    //     ...provided,
    //     border: state.isFocused ? bbTheme.borders.base : '1px solid transparent',
    //     color: bbTheme.colors.black,
    //     // backgroundColor: state.isSelected ? bbTheme.colors.black : bbTheme.colors.white,
    //     cursor: 'pointer',
    //     borderRadius: 'none',
    //     transition,
    // }),
    control: (provided: CSSProperties, state: any) => ({
        ...provided,
        border: bbTheme.borders.base,
        boxShadow: 'none',
        fontSize: '16px',
        cursor: 'pointer',
        borderRadius: '0',
        outline: 'none',
        transition,
    }),
    // menuList: (provided: CSSProperties, state: any) => ({
    //     ...provided,
    //     border,
    //     boxShadow: 'none',
    //     fontSize: '16px',
    //     cursor: 'pointer',
    //     borderRadius: 'none',
    //     padding: '0',
    //     transition,
    // }),
    // menu: (provided: CSSProperties, state: any) => ({
    //     ...provided,
    //     border,
    //     boxShadow: 'none',
    //     fontSize: '16px',
    //     cursor: 'pointer',
    //     borderRadius: 'none',
    //     padding: '0',
    //     transition,
    // }),

    // indicatorsContainer: (provided: CSSProperties, state: any) => ({
    //     ...provided,
    //     margin: 0,
    //     transition,
    // }),
    // valueContainer: (provided: CSSProperties, state: any) => ({
    //     ...provided,
    //     outline: 'none',
    // }),
};

export default reactSelectReset;
