import ThreeDotsWave from 'components/animations/loading/ThreeDotsLoader';
import Icon, { DefaultIcons } from 'components/styles/general-elements/Icon';
import SocialIcon, { SocialIcons } from 'components/styles/general-elements/SocialIcon';
import { BBTheme } from 'components/styles/interface';
import { bbTheme } from 'components/styles/theme';
import usePointer, { MousePosition } from 'hooks/usePointer';
import dynamic from 'next/dynamic';
import React from 'react';
// import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import { slugify } from 'utils/stringUtils';
import { modifyAlpha } from 'utils/styleFunctions';

const ReactTooltip = dynamic(() => import('react-tooltip'), {
    ssr: false,
});
export type ButtonType = 'primary' | 'secondary' | 'accent' | 'accent-soft' | 'clear';

export interface ButtonStyleProps {
    back?: boolean;
    btnStyle?: ButtonType;
    layoutId?: string;
    disabled?: boolean;
    icon?: DefaultIcons;
    socialIcon?: SocialIcons;
    circle?: boolean;
    text?: string;
    tabIndex?: number;
    autoFocus?: boolean;
    type?: HTMLButtonElement['type'];
}

/**
 * Pass getMousePos result to func in args
 * Use args to calc mousePos in func => pass mousePos to called func
 */
interface ButtonFuncProps {
    e: any;
    mouse: MousePosition;
}

export interface ButtonProps extends ButtonStyleProps {
    title?: string;
    className?: string;
    func?: ({ e, mouse }: ButtonFuncProps) => void;
    tooltip?: string;
    id?: string;
    asElement?: 'a';
    hideTextFor?: keyof BBTheme['breakpoints'];
    children?: React.ReactNode;
    loading?: boolean;
}

export const ButtonStyle = styled.button<ButtonStyleProps>`
    min-width: var(--btn-min-width, ${({ circle }) => (circle ? '6rem' : 'auto')});
    height: var(--btn-height, ${({ circle }) => (circle ? '6rem' : 'auto')});
    padding: var(--btn-padding, 1.2rem 2.4rem);
    transition: all 300ms ease-in-out;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    background-color: ${({ theme }) => 'var(--color-btn,' + theme.colors.black + ' )'};
    border: var(--btn-border, 1px solid currentColor);
    border-radius: ${({ circle }) => (circle ? '50%' : 'none')};

    color: ${({ theme }) => 'var(--color-btn-text,' + theme.colors.white + ' )'};
    font-family: ${({ theme }) => theme.typography.font_family.body};
    text-align: center;
    font-weight: inherit;

    &:hover {
        background-color: ${({ theme }) => 'var(--color-btn-hover,' + modifyAlpha(theme.colors.black, 0.8) + ' )'};
        color: ${({ theme }) => 'var(--color-btn-text-hover,' + theme.colors.white + ' )'};
    }

    /** Button Styles */
    &.primary {
        color: ${({ theme }) => 'var(--color-btn-primary-text,' + theme.colors.white + ' )'};
        background-color: ${({ theme }) => 'var(--color-btn-primary,' + theme.colors.primary_black + ' )'};

        &:hover {
            color: ${({ theme }) => 'var(--color-btn-primary-text-hover,' + theme.colors.white + ' )'};
            background-color: ${({ theme }) =>
                'var(--color-btn-primary-hover,' + modifyAlpha(theme.colors.primary_black, 0.8) + ' )'};

            & i {
            }
        }
    }

    &.secondary {
        color: ${({ theme }) => 'var(--color-btn-secondary-text,' + theme.colors.black + ' )'};
        background-color: ${({ theme }) => 'var(--color-btn-secondary,' + theme.colors.white + ' )'};

        &:hover {
            color: ${({ theme }) => 'var(--color-btn-secondary-text-hover,' + theme.colors.black + ' )'};
            background-color: ${({ theme }) =>
                'var(--color-btn-secondary-hover,' + modifyAlpha(theme.colors.white, 0.8) + ' )'};

            & i {
            }
        }
    }

    &.accent {
        color: ${({ theme }) => 'var(--color-btn-accent-text,' + theme.colors.black + ' )'};
        background-color: ${({ theme }) => 'var(--color-btn-accent,' + theme.colors.backgrounds_muted_honey + ' )'};

        &:hover {
            color: ${({ theme }) => 'var(--color-btn-accent-text-hover,' + theme.colors.black + ' )'};
            background-color: ${({ theme }) =>
                'var(--color-btn-accent-hover,' + modifyAlpha(theme.colors.backgrounds_muted_honey, 0.8) + ' )'};

            & i {
            }
        }
    }
    &.accent-soft {
        color: ${({ theme }) => 'var(--color-btn-accent-soft-text,' + theme.colors.black + ' )'};
        background-color: ${({ theme }) =>
            'var(--color-btn-accent-soft,' + theme.colors.backgrounds_muted_honey + ' )'};

        &:hover {
            color: ${({ theme }) => 'var(--color-btn-accent-soft-text-hover,' + theme.colors.black + ' )'};
            background-color: ${({ theme }) =>
                'var(--color-btn-accent-soft-hover,' + modifyAlpha(theme.colors.backgrounds_muted_honey, 0.8) + ' )'};

            & i {
            }
        }
    }
    &.clear {
        color: ${({ theme }) => 'var(--color-btn-clear-text, currentColor )'};
        background-color: ${({ theme }) => 'var(--color-btn-clear, transparent )'};
        border: none;
        &:hover {
            color: ${({ theme }) => 'var(--color-btn-clear-text-hover,' + theme.colors.black + ' )'};
            background-color: ${({ theme }) => 'var(--color-btn-clear-hover, transparent )'};
            border: none;
            & i {
            }
        }
    }

    .default-wrapper {
        display: flex;
        align-items: center;

        & .three-dots-wave {
            position: relative;
            left: 1rem;
        }
    }

    .form-field + &,
    .form-field & {
        * {
            font-size: 16px;
        }
    }

    .wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        white-space: nowrap;
        width: min-content;
        font-weight: inherit;
        br {
            @media only screen and (min-width: 550px) {
                display: none;
            }
        }

        .text-wrapper ~ i,
        .text-wrapper ~ svg {
            order: ${({ back }) => (back ? -1 : 0)};
            margin: ${({ back, circle, text }) =>
                circle || !text?.length ? '0' : back ? '0 1.8rem 0 0' : '0 0 0 1.8rem'};
        }
    }
`;

const Button: React.FC<ButtonProps> = ({
    type,
    text,
    className,
    func,
    back,
    disabled,
    layoutId,
    btnStyle,
    title,
    icon,
    socialIcon,
    circle,
    tooltip,
    id,
    asElement,
    tabIndex,
    hideTextFor,
    children,
    loading,
    ...htmlProps
}) => {
    const ref = React.useRef(null);
    const mouse = usePointer(ref, {
        enterDelay: 100,
        leaveDelay: 100,
    });

    return (
        <ButtonStyle
            tabIndex={tabIndex}
            ref={ref}
            layoutId={layoutId}
            text={text}
            onClick={(e: any) => (func ? func({ e, mouse }) : null)}
            onKeyDown={(e: any) => e.key === 'Enter' && (func ? func({ e, mouse }) : null)}
            onTouchMove={(e: any) => (func ? func({ e, mouse }) : null)}
            className={`${className || ''} ${btnStyle || ''}`}
            disabled={disabled}
            btnStyle={btnStyle}
            title={title || text}
            icon={icon}
            back={back}
            circle={circle}
            data-tip={tooltip}
            data-for={slugify(tooltip || '')}
            id={id}
            as={asElement}
            type={type}
            {...htmlProps}
        >
            {icon || socialIcon ? (
                <div className="wrapper">
                    <span className={`text-wrapper hide-for-${hideTextFor || ''}`}>
                        <span className={`${btnStyle === 'clear' ? '' : 'heading-6'}  currentColor`}>{text}</span>
                    </span>
                    {icon && <Icon title={title || text} name={icon.name} fill={'currentColor'} />}
                    {socialIcon && <SocialIcon title={title || text} name={socialIcon.name} fill={'currentColor'} />}
                </div>
            ) : (
                <span
                    className={`${btnStyle === 'clear' ? '' : 'heading-6'} default-wrapper  currentColor hide-for-${
                        hideTextFor || ''
                    }`}
                >
                    {text || children} {loading && <ThreeDotsWave fluid />}
                </span>
            )}
            {tooltip && (
                <ReactTooltip
                    id={slugify(tooltip)}
                    backgroundColor={bbTheme.colors.white}
                    textColor={bbTheme.colors.black}
                    border
                    borderColor={bbTheme.colors.black}
                />
            )}
        </ButtonStyle>
    );
};

export default Button;
