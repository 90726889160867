import { MyLink } from 'components/Layout/Link';
import { Pages, siteTopLineNav } from 'core/constants';
import React, { useContext } from 'react';
import { NavLocations } from 'src/types/globalTypes';
import { MyAppContext } from 'state/context/global';
import { SidebarNavStyles } from './NavStyles';

interface NavProps {
    selected?: string;
    location: NavLocations;
}

const allPages = Pages.map((page) => {
    if (page.disable) {
        return null;
    }

    if (siteTopLineNav.find((r) => r.slug === page.slug)) {
        return { ...page, isTopline: true };
    }

    return { ...page, isTopline: false };
}).filter((el) => !!el);

const toplineLeft = siteTopLineNav.slice(0, siteTopLineNav.length / 2).map((page) => ({ ...page, isTopline: true }));
const toplineRight = siteTopLineNav.slice(siteTopLineNav.length / 2).map((page) => ({ ...page, isTopline: true }));

const navs = { 'topline-left': toplineLeft, 'topline-right': toplineRight, main: allPages };

const Nav: React.FC<NavProps> = ({ location }) => {
    const app = useContext(MyAppContext);
    return (
        <ul>
            {navs[location]?.map((navItem) => {
                return (
                    <li
                        key={navItem.slug}
                        className={`${location === 'main' && navItem.isTopline ? 'show-for-medium' : ''}`}
                    >
                        <MyLink
                            tabIndex={location === 'main' && !app?.isNavOpen ? -1 : 0}
                            hrefProp={`/${navItem.slug}`}
                            id={`nav-${location}-${navItem.slug}`}
                            text={navItem?.title || navItem.page}
                            btnStyle="clear"
                            icon={{ name: 'none' }}
                            className="heading-5"
                        />
                    </li>
                );
            })}
        </ul>
    );
};

interface SidebarNav {
    location: NavLocations;
}
export const SidebarNav: React.FC<SidebarNav> = ({ location }) => {
    return (
        <SidebarNavStyles>
            <Nav location={location} />
        </SidebarNavStyles>
    );
};

export default Nav;
