import { Product, ProductVariant } from '../types';
import { SelectedOptionInput } from 'src/types/sf-globalTypes';

export function createSelectOptions(productOption) {
    const opts = productOption.values.map((opt, idx) => ({
        value: opt.value,
        label: opt.value,
        name: productOption.name,
        id: productOption.shopifyOptionId,
    }));
    return opts;
}

export function createSelectedOptionInput(product: Product, variant: ProductVariant): SelectedOptionInput[] {
    return product.store.options.map((opt, idx) => ({
        name: opt.name,
        value: variant.store?.[`option${idx + 1}`],
    }));
}
