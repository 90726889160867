export interface NewsletterFormData {
    email: string;
    firstName: string;
    lastName: string;
    // floristTypes?: string[];
}

function formatNewsletterData(formData: NewsletterFormData) {
    return formData;
}

export interface NewsletterContact {
    contact: NewsletterFormData;
    listIds: number[];
}

export async function postNewsLetterData(data: NewsletterFormData) {
    try {
        return fetch('/api/newsletter', {
            method: 'POST',
            body: JSON.stringify({ contact: formatNewsletterData(data), listIds: [8] }),
        })
            .then((res) => res.json())
            .then((data) => {
                return data;
            });
    } catch (err) {
        console.error(err);
    }
}
