import MyImage from 'components/Layout/MyImage';
import React, { useContext } from 'react';
import { CartContext } from '../../../state/context/global';
import { FixedPlaceholderStyles } from '../../Layout/Header';
import { MyLink } from '../../Layout/Link';
import { CartProductFooterStyles } from './CartProductStyles';
import { CartLocationProps } from './Single';
import { staticImages } from 'images/index';

const CartProductFooter: React.FC<CartLocationProps> = ({ isCheckoutPage, isExpanded, isModalCart }) => {
    const cart = useContext(CartContext);

    return (
        <>
            <CartProductFooterStyles className="">
                {cart.hasItems ? (
                    <>
                        {/* //TODO Primary will also start checkout  */}
                        <div className="flex-gap flex-gap-wrap">
                            <span className="sub-total caption-text micro light">Sub-total</span>
                            <span className="sub-total-value caption-text micro light">€{cart.total}</span>
                            <MyLink
                                btnStyle="clear"
                                func={() => cart.toggleCart()}
                                text="View Cart"
                                hrefProp="/cart"
                                className="to-cart"
                                tabIndex={cart.isOpen ? 0 : -1}
                            />
                            <MyLink
                                btnStyle="primary"
                                func={() => cart.toggleCart()}
                                text="Checkout"
                                hrefProp="/cart"
                                className="to-checkout"
                                tabIndex={cart.isOpen ? 0 : -1}
                            />
                        </div>
                    </>
                ) : (
                    <div className="empty-cart flex-gap mt-l">
                        <div className="empty-cart-images">
                            <div className="image-wrapper-wrapper">
                                <div className="image-wrapper">
                                    <MyImage
                                        nextImageProps={{
                                            src: staticImages.christmasBouquet.filename,
                                            objectFit: 'cover',
                                            layout: 'fill',
                                            priority: true,
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="image-wrapper-wrapper">
                                <div className="image-wrapper">
                                    <MyImage
                                        nextImageProps={{
                                            src: staticImages.castleWedding.filename,
                                            priority: true,
                                            objectFit: 'cover',
                                            layout: 'fill',
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <p className="featured dark">
                            Looks like you can use something here. Try browsing our{' '}
                            <MyLink text="Shop" btnStyle="clear" hrefProp="/shop" tabIndex={cart.isOpen ? 0 : -1} />
                        </p>
                    </div>
                )}
            </CartProductFooterStyles>
        </>
    );
};

export default CartProductFooter;
