import React, { useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import styled from 'styled-components';
import Icon from 'components/styles/general-elements/Icon';
import { BbLogoSmall } from 'components/styles/icons';
import { MyAppContext } from 'state/context/global';

const BreadCrumbStyle = styled.nav`
    & * {
        /* border: 1px solid red; */
    }
    .slash-one {
        padding: 0 0.3rem;
    }
    .beehive {
        display: inline-flex;
        width: 3rem;
        position: relative;
        justify-content: center;
        align-items: center;
        top: 0.3rem;
        svg {
            --svg-font-size: 4rem;
        }
    }
    ol {
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;
        vertical-align: middle;
    }

    li {
        margin-left: 0.5rem;
    }
`;

const convertBreadcrumb = (string) => {
    return (
        string
            .replace(/-/g, ' ')
            // .replace(/oe/g, 'ö')
            // .replace(/ae/g, 'ä')
            // .replace(/#/g, '')
            .replace(/\?.*/, '')
            .toUpperCase()
    );
};

const Breadcrumbs = () => {
    const router = useRouter();
    const [breadcrumbs, setBreadcrumbs] = useState([{ breadcrumb: '', href: '/' }]);
    const app = useContext(MyAppContext);
    useEffect(() => {
        if (router) {
            const linkPath = router.asPath.split('/');
            linkPath.shift();

            const pathArray = linkPath.map((path, i) => {
                return { breadcrumb: path, href: '/' + linkPath.slice(0, i + 1).join('/') };
            });

            setBreadcrumbs(pathArray);
        }
    }, [router]);

    if (!breadcrumbs) {
        return null;
    }

    return (
        <BreadCrumbStyle aria-label="breadcrumbs">
            <ol className="breadcrumb">
                {/* <li>
                    <Link href={'/'} passHref>
                        <a className="heading-6 alt beehive" href="#0">
                            <BbLogoSmall />
                            <span className="slash-one">{' / '}</span>
                        </a>
                    </Link>
                </li> */}
                {breadcrumbs.map((breadcrumb, i, arr) => {
                    return (
                        <li key={breadcrumb.href}>
                            <Link href={breadcrumb.href} passHref>
                                <a
                                    href="#0"
                                    className={i === arr.length - 1 ? 'heading-6 alt' : 'heading-6 alt'}
                                    tabIndex={app.isNavOpen ? 0 : -1}
                                >
                                    {convertBreadcrumb(breadcrumb.breadcrumb)}
                                    {arr.length > 1 && i < arr.length - 1 && ' / '}
                                </a>
                            </Link>
                        </li>
                    );
                })}
            </ol>
        </BreadCrumbStyle>
    );
};

export default Breadcrumbs;
