import { Row } from 'components/Layout/grid/Row';
import { bbTheme } from 'components/styles/theme';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import mixins, { device, hoverDarkBg } from '../../styles/mixins';
import { BBTheme } from 'components/styles/interface';
import { KFLoading, KFSuccess } from 'components/animations/keyframes';
import { CartLocationProps } from './Single';

export const CartProductModalStyles = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, min-content);
    gap: ${({ theme }) => theme.spacing.xs};
    grid-gap: ${({ theme }) => theme.spacing.xs};

    text-align: left;
    .row {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .product-title {
        max-width: 26ch;
    }
`;

export const CartProductStyles = styled.div<CartLocationProps>`
    display: grid;
    position: relative;
    grid-template-columns: 3fr 4fr 2fr;
    gap: ${({ theme }) => theme.spacing.xs};
    grid-gap: ${({ theme }) => theme.spacing.xs};
    @media ${device().medium_small} {
        grid-template-columns: 3fr 7fr 2fr;
        ${CartProductModalStyles} & {
            grid-template-columns: 3fr 6fr 1fr;
        }
    }
    ${CartProductModalStyles} & {
        @media ${device().small} {
            display: grid;
        }
        display: flex;
        flex-direction: column;

        .cart-image {
            height: 15rem;
            @media ${device().small} {
                height: auto;
            }
        }
    }

    .cart-image {
        position: relative;
        grid-column: 1 / 2;
    }

    .product-details {
        grid-column: 2 / 3;
    }

    .variant-details {
        margin: 0;
    }

    .price {
        grid-column: 3 / 4;
        p {
            padding-top: 0.7rem;
        }
    }
    .option {
        white-space: nowrap;
    }
    .cart-buttons {
        font-weight: 100;
        --btn-padding: ${({ theme }) => theme.spacing.xs} 0;
        color: ${({ theme }) => theme.colors.grey_text};
        --color-btn-clear-text: ${({ theme }) => theme.colors.grey_text};
    }

    .edit-options {
        --btn-padding: 0;
        z-index: 10;
        position: absolute;
        top: 50%;
        left: 20%;
        animation: ${KFLoading} 400ms ${({ theme }) => theme.animations.beziers.bounceOut};
        button[title='Close'] {
            float: right;
        }
        &.success {
            animation: ${KFSuccess} 400ms ${({ theme }) => theme.animations.beziers.bounceOut};
        }
    }
`;

export const CartProductExpandedStyles = styled.ul<CartLocationProps>``;

export const CartHeaderStyles = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    --gap: ${({ theme }) => theme.spacing.s};
    --btn-padding: 0;

    &.flex-wrap {
        flex-wrap: nowrap;
    }
    .CircleNumber-container {
        padding: 1rem 2rem;
        border: ${({ theme }) => theme.borders.base};
    }
    .title {
        flex: 1 1 80%;
        text-align: center;
    }
    .close {
        flex: 1 1 10%;
        position: relative;
        top: -5px;
    }
`;

export const CartSummaryStyles = styled.div`
    .flex-gap {
        justify-content: space-between;
    }
    form {
        ${mixins.flex}
        justify-content: space-between;
    }

    & p:last-child {
        text-align: right;
        margin-left: auto;
    }
`;

export const CartProductFooterStyles = styled.div`
    .flex-gap {
        justify-content: space-between;
        --gap: ${({ theme }) => theme.spacing.m};
        @media ${device().medium_large} {
            --gap: ${({ theme }) => theme.spacing.m};
        }
    }
    .sub-total {
        flex: 1 1 70%;
    }
    .sub-total-value {
        flex: 1;
    }
    --btn-padding: ${({ theme }) => theme.spacing.xs} 0;
    .to-cart {
        flex: 1;
    }
    .to-checkout {
        flex: 4;
    }

    /** Empty Cart */

    .empty-cart {
        align-items: center;
    }

    .empty-cart-images {
        position: relative;
        height: 13rem;
    }
    .image-wrapper-wrapper:nth-child(2) {
        position: absolute;
        top: 30px;
        left: 15px;
    }
    .image-wrapper {
        position: relative;
        width: 45px;
        height: 80px;
    }
`;
