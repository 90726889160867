import { Article } from 'src/types/sanity/schema';
import { collections_collections_edges_node_products_edges_node } from '../src/types/shopify/storefront/collections';
import { ProductsData_products_edges_node } from '../src/types/shopify/storefront/ProductsData';
import { isBrowser } from '../utils/booleans';
import { defaultMetaTags, socialLinks } from './constants';
import { Product } from 'components/shop/types';
import blocksToText from 'new-studio/utils/blocksToText';

interface ShareMetaData {
    title: string;
    url: string;
    text: string;
    summary: string;
    source: string;
    handle: string;
}

export function genProductShareData(product: Product): ShareMetaData {
    const shareData = {
        url: `${defaultMetaTags.canonical}/shop/products/${product.store.slug.current}`,
        text: blocksToText(product.description),
        title: product.store.title,
        source: defaultMetaTags.title,
        summary: defaultMetaTags.description,
        handle: product.store.slug.current,
    };

    return shareData;
}
export function genJournalShareData(post: Article): ShareMetaData {
    const shareData = {
        url: `${defaultMetaTags.canonical}/journal/${post.slug?.current || ''}`,
        text: post.title || '',
        title: post.title || '',
        source: defaultMetaTags.title || '',
        summary: defaultMetaTags.description,
        handle: post.slug?.current || '',
    };

    return shareData;
}

interface ShareLinkGen {
    data: ShareMetaData;
    platform: 'facebook' | 'twitter' | 'instagram';
}

export function genShareLink({ data, platform }: ShareLinkGen): string {
    switch (platform) {
        case 'facebook':
            return `https://www.facebook.com/sharer.php?u=${encodeURIComponent(data.url)}`;

        case 'twitter':
            return `https://twitter.com/intent/tweet?url=${encodeURIComponent(data.url)}&text=${encodeURIComponent(
                data.text,
            )}&hashtags=${encodeURIComponent(data.handle.split('-').join(','))}&title=${encodeURIComponent(
                data.title,
            )}`;

        case 'instagram':
            return socialLinks.instagram || '';

        default:
            return '';
    }
}

export function popupWindow(shareLink: string): void {
    if (!isBrowser) return;

    const sw = screen.availWidth || 1024,
        sh = screen.availHeight || 700,
        pw = Math.min(600, sw - 40),
        ph = Math.min(600, sh - 40),
        px = Math.floor((sw - pw) / 2),
        py = Math.floor((sh - ph) / 2);

    const popup = window.open(
        shareLink,
        'social',
        `width=${pw},height=${ph},left=${px},top=${py},\
        location=0,menubar=0,toolbar=0,personalbar=0,status=0,scrollbars=1,resizable=1`,
    );

    if (popup) popup.focus();
    else location.href = shareLink;
}
// TODO Use alerts to debug on mobile
function webShare({ title, text, url }: ShareMetaData): void {
    if (window.navigator.share) {
        window.navigator
            .share({
                title,
                text,
                url,
            })
            .then((res) => res)
            .catch((error) => console.error('Error sharing', error));
    } else {
        alert("It looks like this browser doesn't support web share. A regular post would still work.");
    }
}

export default webShare;

/**
 * Click Social Icon on Product
 * Dialog box shows with
 *  prefilled link to PDP
 *  Product Title
 *  Product Description
 *
 *
 * Social Widgets are in each product
 * product details are passed to each
 * use those details to generate the share details
 * populate appropriate icon with respective url
 *
 */

/**
 * 
 Facebook
https://www.facebook.com/sharer.php?u=http%3A%2F%2Fcss-tricks.com%2F
https://twitter.com/intent/tweet
?url=http%3A%2F%2Fcss-tricks.com%2F
&text=Tips%2C+Tricks%2C+and+Techniques+on+using+Cascading+Style+Sheets.
&hashtags=css,html
 */
