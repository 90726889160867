import { groq } from 'next-sanity';
import { SelectedOptionInput } from 'src/types/sf-globalTypes';
import { constructVariantByOptions } from 'utils/sanity/helpers';
import {
    F_BODY_WITH_ADD_FIELDS,
    F_NEXT_PREV_ARTICLE,
    F_PAGE_FIELDS,
    F_POST_FIELDS,
    F_PRODUCT_FIELDS,
} from './fragments';

/**  Variables { slug }  */
export const Q_POST = groq`
  *[_type == "article" && slug.current == $slug][0] {
    ${F_POST_FIELDS}
  }
`;

/**  Variables { slug }  */
export const Q_PREVIEW_POST_BY_SLUG = groq`
*[_type == "article" && slug.current == $slug] | order(publishedAt desc){
  ${F_POST_FIELDS}
  ${F_BODY_WITH_ADD_FIELDS}
  ${F_NEXT_PREV_ARTICLE}
  'comments': *[
    _type == "comment" && 
    article._ref == ^._id && 
    approved == true] {
    _id, 
    name, 
    email, 
    comment, 
    _createdAt
  }
  }`;

export const Q_ALL_POSTS_WITH_SLUG = groq`*[_type == "article"]{ 'slug': slug.current }`;

export const Q_ALL_POSTS_FOR_HOME = groq`*[_type == "article"] | order(publishedAt desc){
    ${F_POST_FIELDS}
  }`;

/**  Variables { slug }  */
export const Q_POST_AND_MORE_POSTS_1 = groq`*[_type == "article" && slug.current == $slug] | order(_updatedAt desc) {
    ${F_POST_FIELDS}
    ${F_BODY_WITH_ADD_FIELDS}
    ${F_NEXT_PREV_ARTICLE}
    'comments': *[
      _type == "comment" && 
      article._ref == ^._id && 
      approved == true] {
      _id, 
      name, 
      email, 
      comment, 
      _createdAt
    }
  }`;

/**  Variables { slug }  */
export const Q_POST_AND_MORE_POSTS_2 = groq`*[_type == "article" && slug.current != $slug] | order(publishedAt desc, _updatedAt desc){
    ${F_POST_FIELDS}
    ${F_BODY_WITH_ADD_FIELDS}
  }[0...2]`;

export const Q_CATEGORY_ARTICLE_TITLES = groq`
  *[_type == "category"]{
    _id,
    _type,
    title,
    "articles": *[_type == "article" && references(^._id)]{
      title,
      slug
    }
  }
  `;

export const Q_CURRENT_ALERT = groq`
  *[_type == "alertbar" && active == true ][0] {
    active,
    title,
    alertTitle,
    startDate,
    endDate,
    issue,
    solution,
    resolution,
    }`;

export const Q_ALL_PAGES = groq`
    *[_type == 'page' || _type == 'page'] {
      ...,
      ${F_PAGE_FIELDS}
    }
    `;

export const Q_PAGE_BY_SLUG = groq`
    *[_type == "page" && slug.current == $slug][0] {
      ...,
      ${F_PAGE_FIELDS}
    }
    `;
// export const Q_PAGE_BY_ROUTE = groq`
// *[_type == "route" && slug.current == $slug][0]{
//   ...,
//   page-> {
//     ${F_PAGE_FIELDS}
// 	}
// }
//     `;

// export const Q_PREVIEW_PAGE_BY_ROUTE = groq`
// *[_type == "route" && slug.current == $slug][0]{
//   ...,
//   page-> {
//     ${F_PAGE_FIELDS}
// 	}
// }
//     `;
export const Q_PREVIEW_PAGE = groq`
*[_type == "page" && slug.current == $slug][0] {
  ...,
  ${F_PAGE_FIELDS}
}
    `;

export const Q_SHIPPING_INFO = groq`
    *[_type == 'shippingInformation'][0] {
      body,
      subtitle,
      title,
     _updatedAt
      }
    `;

export const Q_RELATED_ARTICLES = groq`
    *[_type=="category" && title in $categories]{
      title,
      "relatedArticles": *[_type=='article' && references(^._id) && slug.current != $slug]{ 
        ${F_POST_FIELDS} 
      }
    }
    `;

export const Q_PREVIEW_PRODUCT = groq`
*[_type == "product" && store.handle == $handle][0]{
    ${F_PRODUCT_FIELDS}
}
    `;

export const Q_ALL_FRONT_PAGE_PRODUCTS = groq`
    *[_type=="product" && store.status == "active" && showOnFrontPage==true][]{
      ...
    }
    `;
export const Q_ALL_PRODUCTS = groq`
    *[_type=="product" && store.status == "active"][]{
      ...
    }
    `;
export const Q_PRODUCT_HANDLES = groq`
    *[_type=="product" && store.status == "active" ]{
      "handle": store.slug.current
    }
    `;
export const Q_PRODUCT_HANDLES_BY_TYPE_INCL = groq`
    *[_type=="product" && store.status == "active" && productType == $productType ]{
      "handle": store.slug.current
    }
    `;
export const Q_PRODUCT_HANDLES_BY_TYPE_EXCL = groq`
    *[_type=="product" && store.status == "active" && !(productType == $productType) ]{
      "handle": store.slug.current
    }
    `;
export const Q_PRODUCT_BY_HANDLE = groq`
    *[_type == "product" && store.slug.current == $handle && store.status == "active"][0]{
      ${F_PRODUCT_FIELDS}
    }
    `;
export const Q_PRODUCTS_BY_TYPE = groq`
    *[_type=="product" && store.status == "active" && productType == $productType ]{
      ...
    }
    `;
export const Q_COLLECTION_HANDLES = groq`
        *[_type=="shopifyCollection" ]{
          "handle": store.slug.current
        }
        `;

export const Q_ALL_COLLECTIONS = groq`
    *[_type=="shopifyCollection"][]{
      ...
    }
    `;
export const Q_COLLECTION_BY_HANDLE = groq`
    *[_type=="shopifyCollection" && store.handle == $handle][0]{
      ... ,
      products[]->
    }
    `;

/**
 * 
 * Variable
 {
  "handle":"bumblebee-bundle-subscription",
  "selectedOptions" : [{"name":"Size","value":"Large"},{"name":"Frequency","value":"Weekly"}]
}
 */
export const Q_VARIANT_BY_OPTIONS = (options: SelectedOptionInput[]): string => {
    const query = groq`
  *[_type=="product" && store.status == "active" && store.slug.current == $handle][0]{
    "variantBySelectedOptions": ${constructVariantByOptions(options)}[0]->{
      ...
    }
  }
  `;

    return query;
};

export const Q_SHOP_CONFIG = groq`
*[_type == "shopConfig" ][0] {
    ...,
    popupProduct {
      ...,
      product->
  },
    fixedCalendarDates {
      ...,
      products[]{
        ...,
        product->
      }
  }
}
`;
export const Q_SHOP_CONFIG_FIXED_CALENDAR = groq`
*[_type == "shopConfig" ][0] {
    fixedCalendarDates {
      ...,
      products[]{
        ...,
        product->
      }
  }
}
`;
export const Q_SHOP_CONFIG_POPUP = groq`
*[_type == "shopConfig" ][0] {
    popupProduct {
      ...,
      product->
  }
}
`;
export const Q_SHOP_CONFIG_SHOPPING_EXCLUSION = groq`
*[_type == "shopConfig" ][0] {
  shippingExclusion
}
`;
export const Q_SHOP_CONFIG_SHIPPING_INFORMATION = groq`
*[_type == "shopConfig" ][0] {
  shippingInformation
}
`;
