import { SelectedOptionInput } from 'src/types/sf-globalTypes';
import { removeObjectProperty } from 'utils/objectFunctions';
import { CartItemProductNode } from '../../interfaces';
import { Product, ProductVariant } from '../../types';

export function getVariantByTitle(title: string, product: Product | CartItemProductNode): ProductVariant | undefined {
    const productExists = product.store.variants.find((el) => {
        return el.store.title === title;
    });

    if (productExists) {
        return productExists;
    }
    return undefined;
}

export function getVariantById(
    id: string,
    product: Product | CartItemProductNode,
    identifier?: 'id' | 'title',
): ProductVariant | undefined {
    const productExists = product.store.variants.find((el) => {
        if (identifier === 'title') {
            return el.store.title === id;
        }

        return el.store.gid === id;
    });

    if (productExists) {
        return productExists;
    }
    return undefined;
}

export function findSelectedVariantByOpts(opts: SelectedOptionInput[], product: Product): ProductVariant {
    const selectedVariant = product.store.variants.find((el) => {
        const title = opts.map((el) => el.value).join(' / ');
        return title === el.store.title;
    });

    return selectedVariant || product.store.variants[0];
}
