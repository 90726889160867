import useFocus from 'hooks/useFocus';
import { useRouter } from 'next/router';
import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { MyAppContext } from '../../state/context/global';
import { removeScrollbars } from '../styles/mixins';
import Breadcrumbs from './BreadCrumbs';
import { DrawerStyles } from './NavStyles';

interface NavDrawerProps {
    children: React.ReactNode;
}

const NavDrawer: React.FC<NavDrawerProps> = ({ children }) => {
    // const [arrowActive, setArrowActive] = useState(false);
    // const [sidebarActive, setSidebarActive] = useState(false);

    // const divRef = useRef<HTMLDivElement | null>(null);
    const [inputRef, setInputFocus] = useFocus();
    const app = useContext(MyAppContext);

    const router = useRouter();

    if (!app) return <div />;

    useEffect(() => {
        // setSidebarActive(false);
        app.isNavOpen && app.toggleNavDrawer('close');
    }, [router]);

    const handleSidebar = (): void => {
        // setSidebarActive(!sidebarActive);

        app.toggleNavDrawer();
        // setArrowActive(!arrowActive);
    };

    function handleClickOutside(e: MouseEvent): void {
        if (null !== inputRef.current && inputRef.current.contains(e.target as Node)) {
            // inside click
            return;
        }

        // outside click

        handleSidebar();
    }

    useEffect(() => {
        if (app.isNavOpen) {
            document.addEventListener('mousedown', handleClickOutside);
            // setTimeout(() => {
            setInputFocus();
            // }, 1000);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [app.isNavOpen]);

    return (
        <>
            <DrawerStyles
                sidebarActive={app.isNavOpen}
                ref={inputRef}
                aria-hidden={!app.isNavOpen}
                aria-label="Full Navigation"
                aria-expanded={app.isNavOpen}

                // tabIndex={app.isNavOpen ? 0 : -1}
            >
                <Breadcrumbs />
                {children}
            </DrawerStyles>
        </>
    );
};

export default NavDrawer;
