export const defaultImage = {
    src: 'https://res.cloudinary.com/dwa5hefth/image/upload/v1593217686/BumblebeeflowerFarm-Static/dahlias-min.jpg',
    filename: 'BumblebeeflowerFarm-Static/dahlias-min.jpg',
    thumbnail:
        'https://res.cloudinary.com/dwa5hefth/image/upload/w_100,h_100,c_lfill/w_100/v1593217686/BumblebeeflowerFarm-Static/dahlias-min.jpg',
};
export const cafeAuLait = {
    src:
        'https://res.cloudinary.com/dwa5hefth/image/upload/v1612639474/BumblebeeflowerFarm-Static/Mags_with_Cafe_au_Lait_Dahlia.png',
    filename: 'BumblebeeflowerFarm-Static/Mags_with_Cafe_au_Lait_Dahlia.jpg',
    thumbnail:
        'https://res.cloudinary.com/dwa5hefth/image/upload/w_100,h_100,c_lfill/w_100/v1593217686/BumblebeeflowerFarm-Static/Mags_with_Cafe_au_Lait_Dahlia.jpg',
};
export const alyBouquet = {
    src:
        'https://res.cloudinary.com/dwa5hefth/image/upload/v1613220163/BumblebeeflowerFarm-Static/Aly-holding-bouquet-min-1.jpg',
    filename: 'BumblebeeflowerFarm-Static/Aly-holding-bouquet-min-1.jpg',
    thumbnail:
        'https://res.cloudinary.com/dwa5hefth/image/upload/w_100,h_100,c_lfill/w_100/BumblebeeflowerFarm-Static/Aly-holding-bouquet-min-1.jpg',
};
export const sunsetImage = {
    src:
        'https://res.cloudinary.com/dwa5hefth/image/upload/v1593217689/BumblebeeflowerFarm-Static/Sunset-at-Bumblebee-Farm-min.jpg',
    filename: 'BumblebeeflowerFarm-Static/Sunset-at-Bumblebee-Farm-min.jpg',
    thumbnail:
        'https://res.cloudinary.com/dwa5hefth/image/upload/w_100,h_100,c_lfill/w_100/v1593217689/BumblebeeflowerFarm-Static/Sunset-at-Bumblebee-Farm-min.jpg',
};
export const weddingBanner = {
    src:
        'https://res.cloudinary.com/dwa5hefth/image/upload/v1597013604/BumblebeeflowerFarm-Static/Old-WEdding-banner-min.jpg',
    filename: 'BumblebeeflowerFarm-Static/Old-WEdding-banner-min.jpg',
    thumbnail:
        'https://res.cloudinary.com/dwa5hefth/image/upload/w_100,h_100,c_lfill/w_100/v1597013604/BumblebeeflowerFarm-Static/Old-WEdding-banner-min.jpg',
};
export const cascadeImg = {
    src:
        'https://res.cloudinary.com/dwa5hefth/image/upload/v1593217687/BumblebeeflowerFarm-Static/Flowing_cascade-min.jpg',
    filename: 'BumblebeeflowerFarm-Static/Flowing_cascade-min.jpg',
    thumbnail:
        'https://res.cloudinary.com/dwa5hefth/image/upload/w_100,h_100,c_lfill/w_100/v1593217687/BumblebeeflowerFarm-Static/Flowing_cascade-min.jpg',
};
export const weddingVaseImg = {
    src:
        'https://res.cloudinary.com/dwa5hefth/image/upload/v1593217689/BumblebeeflowerFarm-Static/Wedding__vase--collage-min.jpg',
    filename: 'BumblebeeflowerFarm-Static/Wedding__vase--collage-min.jpg',
    thumbnail:
        'https://res.cloudinary.com/dwa5hefth/image/upload/w_100,h_100,c_lfill/w_100/v1593217689/BumblebeeflowerFarm-Static/Wedding__vase--collage-min.jpg',
};
export const herbGardenImg = {
    src:
        'https://res.cloudinary.com/dwa5hefth/image/upload/v1593217685/BumblebeeflowerFarm-Static/A-taste-herb-garden-min.jpg',
    filename: 'BumblebeeflowerFarm-Static/A-taste-herb-garden-min.jpg',
    thumbnail:
        'https://res.cloudinary.com/dwa5hefth/image/upload/w_100,h_100,c_lfill/w_100/v1593217685/BumblebeeflowerFarm-Static/A-taste-herb-garden-min.jpg',
};
export const educationImg = {
    src: 'https://res.cloudinary.com/dwa5hefth/image/upload/v1593217687/BumblebeeflowerFarm-Static/education-min.jpg',
    filename: 'BumblebeeflowerFarm-Static/education-min.jpg',
    thumbnail:
        'https://res.cloudinary.com/dwa5hefth/image/upload/w_100,h_100,c_lfill/w_100/v1593217687/BumblebeeflowerFarm-Static/education-min.jpg',
};
export const profilePicImg = {
    src: 'https://res.cloudinary.com/dwa5hefth/image/upload/v1593217689/BumblebeeflowerFarm-Static/profile-pic-min.jpg',
    filename: 'BumblebeeflowerFarm-Static/profile-pic-min.jpg',
    thumbnail:
        'https://res.cloudinary.com/dwa5hefth/image/upload/w_100,h_100,c_lfill/w_100/v1593217689/BumblebeeflowerFarm-Static/profile-pic-min.jpg',
};
export const autumnFloralsImg = {
    src: 'https://res.cloudinary.com/dwa5hefth/image/upload/v1593217687/BumblebeeflowerFarm-Static/Autumn-florals.jpg',
    filename: 'BumblebeeflowerFarm-Static/Autumn-florals.jpg',
    thumbnail:
        'https://res.cloudinary.com/dwa5hefth/image/upload/w_100,h_100,c_lfill/w_100/v1593217687/BumblebeeflowerFarm-Static/Autumn-florals.jpg',
};
export const bouquetLeftImg = {
    src: 'https://res.cloudinary.com/dwa5hefth/image/upload/v1593217685/BumblebeeflowerFarm-Static/9.jpg',
    filename: 'BumblebeeflowerFarm-Static/9.jpg',
    thumbnail:
        'https://res.cloudinary.com/dwa5hefth/image/upload/w_100,h_100,c_lfill/w_100/v1593217685/BumblebeeflowerFarm-Static/9.jpg',
};
export const eddieImg = {
    src:
        'https://res.cloudinary.com/dwa5hefth/image/upload/v1593217686/BumblebeeflowerFarm-Static/Eddie-Attwell-min.jpg',
    filename: 'BumblebeeflowerFarm-Static/Eddie-Attwell-min.jpg',
    thumbnail:
        'https://res.cloudinary.com/dwa5hefth/image/upload/w_100,h_100,c_lfill/w_100/v1593217686/BumblebeeflowerFarm-Static/Eddie-Attwell-min.jpg',
};
export const pinkCocktailImg = {
    src:
        'https://res.cloudinary.com/dwa5hefth/image/upload/v1593217688/BumblebeeflowerFarm-Static/Pink-cocktail-on-a-plate-min.jpg',
    filename: 'BumblebeeflowerFarm-Static/Pink-cocktail-on-a-plate-min.jpg',
    thumbnail:
        'https://res.cloudinary.com/dwa5hefth/image/upload/w_100,h_100,c_lfill/w_100/v1593217688/BumblebeeflowerFarm-Static/Pink-cocktail-on-a-plate-min.jpg',
};
export const alyBumble = {
    src: 'https://res.cloudinary.com/dwa5hefth/image/upload/v1593217685/BumblebeeflowerFarm-Static/aly-bumblebee.jpg',
    filename: 'BumblebeeflowerFarm-Static/aly-bumblebee.jpg',
    thumbnail:
        'https://res.cloudinary.com/dwa5hefth/image/upload/w_100,h_100,c_lfill/w_100/v1593217685/BumblebeeflowerFarm-Static/aly-bumblebee.jpg',
};
export const lavenderGreen = {
    src: 'https://res.cloudinary.com/dwa5hefth/image/upload/v1593217688/BumblebeeflowerFarm-Static/lavender_green.jpg',
    filename: 'BumblebeeflowerFarm-Static/lavender_green.jpg',
    thumbnail:
        'https://res.cloudinary.com/dwa5hefth/image/upload/w_100,h_100,c_lfill/w_100/v1593217688/BumblebeeflowerFarm-Static/lavender_green.jpg',
};
export const autumnMarketBqt = {
    src:
        'https://res.cloudinary.com/dwa5hefth/image/upload/v1593217686/BumblebeeflowerFarm-Static/autumn_market_bouquet.jpg',
    filename: 'BumblebeeflowerFarm-Static/autumn_market_bouquet.jpg',
    thumbnail:
        'https://res.cloudinary.com/dwa5hefth/image/upload/w_100,h_100,c_lfill/w_100/v1593217686/BumblebeeflowerFarm-Static/autumn_market_bouquet.jpg',
};

/**
 * Wedding
 */

export const castleWedding = {
    src:
        'https://res.cloudinary.com/dwa5hefth/image/upload/v1593217690/BumblebeeflowerFarm-Static/castleWedding-min.jpg',
    filename: 'BumblebeeflowerFarm-Static/castleWedding-min.jpg',
    thumbnail:
        'https://res.cloudinary.com/dwa5hefth/image/upload/w_100,h_100,c_lfill/w_100/v1593217690/BumblebeeflowerFarm-Static/castleWedding-min.jpg',
};
export const coastWedding = {
    src:
        'https://res.cloudinary.com/dwa5hefth/image/upload/v1593217690/BumblebeeflowerFarm-Static/coastWedding-min.jpg',
    filename: 'BumblebeeflowerFarm-Static/coastWedding-min.jpg',
    thumbnail:
        'https://res.cloudinary.com/dwa5hefth/image/upload/w_100,h_100,c_lfill/w_100/v1593217690/BumblebeeflowerFarm-Static/coastWedding-min.jpg',
};
export const brideMin = {
    src:
        'https://res.cloudinary.com/dwa5hefth/image/upload/v1593217689/BumblebeeflowerFarm-Static/Bridal_Bouquet-min.jpg',
    filename: 'BumblebeeflowerFarm-Static/Bridal_Bouquet-min.jpg',
    thumbnail:
        'https://res.cloudinary.com/dwa5hefth/image/upload/w_100,h_100,c_lfill/w_100/v1593217689/BumblebeeflowerFarm-Static/Bridal_Bouquet-min.jpg',
};
export const corsageMin = {
    src: 'https://res.cloudinary.com/dwa5hefth/image/upload/v1593217690/BumblebeeflowerFarm-Static/Corsage-min.jpg',
    filename: 'BumblebeeflowerFarm-Static/Corsage-min.jpg',
    thumbnail:
        'https://res.cloudinary.com/dwa5hefth/image/upload/w_100,h_100,c_lfill/w_100/v1593217690/BumblebeeflowerFarm-Static/Corsage-min.jpg',
};
/**
 * Wedding Collage
 */

export const fullDesignVase = {
    src:
        'https://res.cloudinary.com/dwa5hefth/image/upload/v1593217686/BumblebeeflowerFarm-Static/autumn_market_bouquet.jpg',
    filename: 'BumblebeeflowerFarm-Static/fullDesignVase.jpg',
    thumbnail:
        'https://res.cloudinary.com/dwa5hefth/image/upload/w_100,h_100,c_lfill/w_100/v1593217686/BumblebeeflowerFarm-Static/autumn_market_bouquet.jpg',
};
export const groomButtonHole = {
    src: 'https://res.cloudinary.com/dwa5hefth/image/upload/v1593217687/BumblebeeflowerFarm-Static/groomButtonhole.jpg',
    filename: 'BumblebeeflowerFarm-Static/groomButtonhole.jpg',
    thumbnail:
        'https://res.cloudinary.com/dwa5hefth/image/upload/w_100,h_100,c_lfill/w_100/v1593217687/BumblebeeflowerFarm-Static/groomButtonhole.jpg',
};
export const headDressMagda = {
    src:
        'https://res.cloudinary.com/dwa5hefth/image/upload/v1593217688/BumblebeeflowerFarm-Static/head-dress-magda-min.jpg',
    filename: 'BumblebeeflowerFarm-Static/head-dress-magda-min.jpg',
    thumbnail:
        'https://res.cloudinary.com/dwa5hefth/image/upload/w_100,h_100,c_lfill/w_100/v1593217688/BumblebeeflowerFarm-Static/head-dress-magda-min.jpg',
};
export const lateSummerCascade = {
    src:
        'https://res.cloudinary.com/dwa5hefth/image/upload/v1593217688/BumblebeeflowerFarm-Static/lateSummerCascade.jpg',
    filename: 'BumblebeeflowerFarm-Static/lateSummerCascade.jpg',
    thumbnail:
        'https://res.cloudinary.com/dwa5hefth/image/upload/w_100,h_100,c_lfill/w_100/v1593217688/BumblebeeflowerFarm-Static/lateSummerCascade.jpg',
};
export const lateSummerCascade2 = {
    src:
        'https://res.cloudinary.com/dwa5hefth/image/upload/v1593217688/BumblebeeflowerFarm-Static/lateSummerCascade2.jpg',
    filename: 'BumblebeeflowerFarm-Static/lateSummerCascade2.jpg',
    thumbnail:
        'https://res.cloudinary.com/dwa5hefth/image/upload/w_100,h_100,c_lfill/w_100/v1593217688/BumblebeeflowerFarm-Static/lateSummerCascade2.jpg',
};
export const annaRickyImg = {
    src: 'https://res.cloudinary.com/dwa5hefth/image/upload/v1593217685/BumblebeeflowerFarm-Static/annaRicky.jpg',
    filename: 'BumblebeeflowerFarm-Static/annaRicky.jpg',
    thumbnail:
        'https://res.cloudinary.com/dwa5hefth/image/upload/w_100,h_100,c_lfill/w_100/v1593217685/BumblebeeflowerFarm-Static/annaRicky.jpg',
};

export const weddingCollage = [
    fullDesignVase,
    groomButtonHole,
    headDressMagda,
    lateSummerCascade,
    annaRickyImg,
    coastWedding,
    brideMin,
    corsageMin,
];

/**
 * Christmas
 */

export const joinedVideo = {
    src:
        'https://res.cloudinary.com/dwa5hefth/video/upload/v1602438581/BumblebeeflowerFarm-Static/joined_video_f2c446e81a9f407ab15a9a47a5fa48f6.mp4',
    filename: 'BumblebeeflowerFarm-Static/joined_video_f2c446e81a9f407ab15a9a47a5fa48f6.mp4',
    thumbnail:
        'https://res.cloudinary.com/dwa5hefth/video/upload/w_100,h_100,c_lfill/w_100/v1602438581/BumblebeeflowerFarm-Static/joined_video_f2c446e81a9f407ab15a9a47a5fa48f6.mp4',
};
export const christmasPromoVid = {
    src:
        'https://res.cloudinary.com/dwa5hefth/video/upload/v1602438590/BumblebeeflowerFarm-Static/VIDEO-2020-09-30-17-41-59.mp4',
    filename: 'BumblebeeflowerFarm-Static/VIDEO-2020-09-30-17-41-59.mp4',
    thumbnail:
        'https://res.cloudinary.com/dwa5hefth/video/upload/w_100,h_100,c_lfill/w_100/v1602438590/BumblebeeflowerFarm-Static/VIDEO-2020-09-30-17-41-59.mp4',
};
export const wreath_kitx2 = {
    src:
        'https://res.cloudinary.com/dwa5hefth/image/upload/v1604248259/BumblebeeflowerFarm-Static/Christmas_Flatlay-00416.jpg',
    filename: 'BumblebeeflowerFarm-Static/Christmas_Flatlay-00416.jpg',
    thumbnail:
        'https://res.cloudinary.com/dwa5hefth/image/upload/w_100,h_100,c_lfill/w_100/v1602438576/BumblebeeflowerFarm-Static/2x_Wreath_kit-4953.jpg',
};
export const christmasBouquet = {
    src:
        'https://res.cloudinary.com/dwa5hefth/image/upload/v1602438575/BumblebeeflowerFarm-Static/Christmas_20-4830.jpg',
    filename: 'BumblebeeflowerFarm-Static/Christmas_20-4830.jpg',
    thumbnail:
        'https://res.cloudinary.com/dwa5hefth/image/upload/w_100,h_100,c_lfill/w_100/v1602438575/BumblebeeflowerFarm-Static/Christmas_20-4830.jpg',
};
export const christmasCandle = {
    src:
        'https://res.cloudinary.com/dwa5hefth/image/upload/v1602438575/BumblebeeflowerFarm-Static/Christmas_29Aug2020-4774.jpg',
    filename: 'BumblebeeflowerFarm-Static/Christmas_29Aug2020-4774.jpg',
    thumbnail:
        'https://res.cloudinary.com/dwa5hefth/image/upload/w_100,h_100,c_lfill/w_100/v1602438575/BumblebeeflowerFarm-Static/Christmas_29Aug2020-4774.jpg',
};
export const wreathKitStarter = {
    src:
        'https://res.cloudinary.com/dwa5hefth/image/upload/v1602438575/BumblebeeflowerFarm-Static/2x_Wreath_kit-4945.jpg',
    filename: 'BumblebeeflowerFarm-Static/2x_Wreath_kit-4945.jpg',
    thumbnail:
        'https://res.cloudinary.com/dwa5hefth/image/upload/w_100,h_100,c_lfill/w_100/v1602438575/BumblebeeflowerFarm-Static/2x_Wreath_kit-4945.jpg',
};
export const wreathKitPinecones = {
    src:
        'https://res.cloudinary.com/dwa5hefth/image/upload/v1602438575/BumblebeeflowerFarm-Static/2x_Wreath_kit-4919.jpg',
    filename: 'BumblebeeflowerFarm-Static/2x_Wreath_kit-4919.jpg',
    thumbnail:
        'https://res.cloudinary.com/dwa5hefth/image/upload/w_100,h_100,c_lfill/w_100/v1602438575/BumblebeeflowerFarm-Static/2x_Wreath_kit-4919.jpg',
};

export const christmasCollage = [wreath_kitx2, christmasBouquet, christmasCandle, wreathKitStarter, wreathKitPinecones];

/**
 * Edible
 */

export const aTasteSpoonImg = {
    src:
        'https://res.cloudinary.com/dwa5hefth/image/upload/v1593217685/BumblebeeflowerFarm-Static/A-Taste-Spoon-min.jpg',
    filename: 'BumblebeeflowerFarm-Static/A-Taste-Spoon-min.jpg',
    thumbnail:
        'https://res.cloudinary.com/dwa5hefth/image/upload/w_100,h_100,c_lfill/w_100/v1593217685/BumblebeeflowerFarm-Static/A-Taste-Spoon-min.jpg',
};
export const grapeFruitMintImg = {
    src:
        'https://res.cloudinary.com/dwa5hefth/image/upload/v1593217687/BumblebeeflowerFarm-Static/Grapefruit-mint-min.jpg',
    filename: 'BumblebeeflowerFarm-Static/Grapefruit-mint-min.jpg',
    thumbnail:
        'https://res.cloudinary.com/dwa5hefth/image/upload/w_100,h_100,c_lfill/w_100/v1593217687/BumblebeeflowerFarm-Static/Grapefruit-mint-min.jpg',
};
export const nasturtiumImg = {
    src: 'https://res.cloudinary.com/dwa5hefth/image/upload/v1593217688/BumblebeeflowerFarm-Static/Nasturtium-min.jpg',
    filename: 'BumblebeeflowerFarm-Static/Nasturtium-min.jpg',
    thumbnail:
        'https://res.cloudinary.com/dwa5hefth/image/upload/w_100,h_100,c_lfill/w_100/v1593217688/BumblebeeflowerFarm-Static/Nasturtium-min.jpg',
};
export const purslaneImg = {
    src: 'https://res.cloudinary.com/dwa5hefth/image/upload/v1593217688/BumblebeeflowerFarm-Static/Purslane-min.jpg',
    filename: 'BumblebeeflowerFarm-Static/Purslane-min.jpg',
    thumbnail:
        'https://res.cloudinary.com/dwa5hefth/image/upload/w_100,h_100,c_lfill/w_100/v1593217688/BumblebeeflowerFarm-Static/Purslane-min.jpg',
};
export const summerAbundanceImg = {
    src:
        'https://res.cloudinary.com/dwa5hefth/image/upload/v1593217689/BumblebeeflowerFarm-Static/Summer_abundance-min.jpg',
    filename: 'BumblebeeflowerFarm-Static/Summer_abundance-min.jpg',
    thumbnail:
        'https://res.cloudinary.com/dwa5hefth/image/upload/w_100,h_100,c_lfill/w_100/v1593217689/BumblebeeflowerFarm-Static/Summer_abundance-min.jpg',
};
export const tortoiseShellButterfly = {
    src:
        'https://res.cloudinary.com/dwa5hefth/image/upload/v1593217690/BumblebeeflowerFarm-Static/Tortoise-shell-butterfly-on-scabious.jpg',
    filename: 'BumblebeeflowerFarm-Static/Tortoise-shell-butterfly-on-scabious.jpg',
    thumbnail:
        'https://res.cloudinary.com/dwa5hefth/image/upload/w_100,h_100,c_lfill/w_100/v1593217690/BumblebeeflowerFarm-Static/Tortoise-shell-butterfly-on-scabious.jpg',
};
export const eddiesHandImg = {
    src: 'https://res.cloudinary.com/dwa5hefth/image/upload/v1593217686/BumblebeeflowerFarm-Static/Eddies-Hand-min.jpg',
    filename: 'BumblebeeflowerFarm-Static/Eddies-Hand-min.jpg',
    thumbnail:
        'https://res.cloudinary.com/dwa5hefth/image/upload/w_100,h_100,c_lfill/w_100/v1593217686/BumblebeeflowerFarm-Static/Eddies-Hand-min.jpg',
};

export const edibleCollage = [aTasteSpoonImg, purslaneImg, eddiesHandImg, grapeFruitMintImg, nasturtiumImg];

export const logoImg = '/bbLogo.png';
export const aippLogo = {
    src: 'https://res.cloudinary.com/dwa5hefth/image/upload/v1593217685/BumblebeeflowerFarm-Static/aipp_logo-min.png',
    filename: 'BumblebeeflowerFarm-Static/aipp_logo-min.png',
    thumbnail:
        'https://res.cloudinary.com/dwa5hefth/image/upload/w_100,h_100,c_lfill/w_100/v1593217685/BumblebeeflowerFarm-Static/aipp_logo-min.png',
};

export const staticImages = {
    defaultImage,
    sunsetImage,
    weddingBanner,
    cascadeImg,
    weddingVaseImg,
    herbGardenImg,
    educationImg,
    profilePicImg,
    autumnFloralsImg,
    bouquetLeftImg,
    eddieImg,
    pinkCocktailImg,
    alyBumble,
    lavenderGreen,
    autumnMarketBqt,
    castleWedding,
    coastWedding,
    brideMin,
    corsageMin,
    fullDesignVase,
    groomButtonHole,
    headDressMagda,
    lateSummerCascade,
    lateSummerCascade2,
    annaRickyImg,
    weddingCollage,
    joinedVideo,
    christmasPromoVid,
    wreath_kitx2,
    christmasBouquet,
    christmasCandle,
    wreathKitStarter,
    wreathKitPinecones,
    christmasCollage,
    aTasteSpoonImg,
    grapeFruitMintImg,
    nasturtiumImg,
    purslaneImg,
    summerAbundanceImg,
    tortoiseShellButterfly,
    eddiesHandImg,
    edibleCollage,
    logoImg,
    aippLogo,
    cafeAuLait,
    alyBouquet,
};
