import { SelectedOptionInput } from 'src/types/sf-globalTypes';
import { getUrlVars } from 'utils/clientOnly/getUrlVars';
import { removeObjectProperty } from 'utils/objectFunctions';
import { capitalize } from 'utils/stringFunctions';
import { isBrowser } from 'utils/booleans';
import { Product } from '../types';

export function optionsToQueryString(opts?: SelectedOptionInput[]): string {
    if (!opts) return '';
    return opts
        .map((obj) =>
            Object.values(obj)
                .map((el, idx) =>
                    idx === 0
                        ? ''
                        : `${idx % 2 == 0 ? 'bbv_' : ''}` +
                          el
                              ?.split(' ')
                              .map((el) => el.toLowerCase())
                              .join('-'),
                )
                .join('=')
                .replace(/=bbv_selectedoption|=shopifysourceselectedoption=/, ''),
        )
        .join('&');
}

export function queryStringToOptions(options: SelectedOptionInput[], product: Product): SelectedOptionInput[] {
    if (!isBrowser) return options;
    const qs = getUrlVars(location.href.replace(/-&-/, '-%26-').replace(/€|%E2%82%AC/, ''));

    const newOpts = options.map((el) => removeObjectProperty(el, '__typename'));
    const availOpts = product.store.options;

    if (qs) {
        try {
            const updates = Object.entries(qs)
                .filter((el) => /^bbv_/.test(el[0]))
                .map((el) => {
                    const name = capitalize(el[0].replace(/^bbv_/, ''));

                    const value = availOpts
                        .find((availOpt) => availOpt.name === name)
                        ?.values.find((val) => {
                            return (
                                val.toLowerCase() === el[1].toLowerCase() ||
                                parseInt(val.replace(/€|\$/, '')) === parseInt(el[1])
                            );
                        });

                    return { name, value: value || capitalize(el[1]) };
                });

            newOpts.forEach((el, idx) => {
                const matchingOption = updates.find((up) => up.name === el.name);
                if (matchingOption) {
                    newOpts.splice(idx, 1, removeObjectProperty(matchingOption, '__typename'));
                }
            });
        } catch (err) {
            // console.error('Error parsing query string: ', err);
            return options;
        }
    }

    return newOpts;
}
