import mixins from 'components/styles/mixins';
import { bbTheme } from 'components/styles/theme';
import { Field, FormikErrors, useField, useFormikContext } from 'formik';
import dynamic from 'next/dynamic';
import React from 'react';
import Select, { components } from 'react-select';
// import ReactTooltip from 'react-tooltip';
import styled, { keyframes } from 'styled-components';
import { convertRems } from 'utils/stringFunctions';
import { slugify } from 'utils/stringUtils';
import { matchLabelToOption } from '../../utils/matchStringInSelectOption';
import Icon from '../styles/general-elements/Icon';
import CheckboxInput from './AccessibleCheckbox';
import reactSelectReset from './reactSelectStyle';
const loading = keyframes`
  from {
    background-position: 0 0;
    /* rotate: 0; */
  }

  to {
    background-position: 100% 100%;
    /* rotate: 360deg; */
  }
`;

export const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <Icon name={'chevron'} />
        </components.DropdownIndicator>
    );
};

const ReactTooltip = dynamic(() => import('react-tooltip'), {
    ssr: false,
});

export const MyFieldSet = styled.fieldset.attrs((props) => ({ className: `${props.className || ''} flex-gap` }))`
    border: 0;
    padding: 0;
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    @media only screen and (min-width: 600px) {
        width: 100%;
    }
    &[disabled] {
        opacity: 0.5;
    }
    &::before {
        height: 10px;
        content: '';
        display: block;
    }
    &[aria-busy='true']::before {
        background-size: 50% auto;
        animation: ${loading} 0.5s linear infinite;
    }
`;

export const MyLabel = styled.label`
    display: block;
    margin: 0.5rem 0.5rem 0.5rem 0;
    text-align: left;
    transition: all 300ms ease-in-out;
    cursor: pointer;

    &.radio-label {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    &.untouched {
        transition: all 400ms ease-in-out;
        opacity: 0;
        visibility: hidden;
        transform: translateY(-4rem);
    }

    .checkbox-input {
        margin: 0 1rem 0 0;
        position: relative;
        top: -2px;
    }

    .radio-input {
        margin: 0 1rem 0 0;
        position: relative;
    }

    .field-error & {
        color: ${({ theme }) => theme.colors.error};
    }
`;

export const MyInput = styled.input`
    width: 100%;
    padding: ${({ theme }) => theme.spacing.xs};
    /* margin: 0.5rem 0.5rem 0.5rem 0; */
    border: 1px solid ${({ theme }) => theme.colors.light_grey_soft_lines};
    color: ${({ theme }) => theme.colors.black};
    position: relative;
    display: inline-flex;
    flex-flow: column nowrap;

    @media only screen and (max-width: 600px) {
        max-width: 98%;
    }
    transition: all 400ms ease-in-out;
    font-family: ${({ theme }) => theme.typography.font_family.body};

    &:placeholder-shown + label,
    .field-error & + label {
        transition: all 400ms ease-in-out;
        opacity: 0;
        visibility: hidden;
        transform: translateY(-4rem);
    }

    .field-error & + label {
        transition: all 400ms ease-in-out;
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
        color: ${({ theme }) => theme.colors.error};
    }

    &:focus,
    &.field-complete {
        outline: 0;
        border-color: ${({ theme }) => theme.colors.black};
    }
    .error &,
    .field-error &,
    &:focus:invalid,
    &[aria-invalid='true'] {
        border-color: ${({ theme }) => theme.colors.error};
    }

    & .required {
        color: ${({ theme }) => theme.colors._red};
    }
`;

export const RadioInput = styled.div`
    display: inline-flex;

    input[type='radio'] {
        /* display: none; */
        ${mixins.hideVisually}
    }

    & .radio-button {
        height: 2.2rem;
        width: 2.2rem;
        border-radius: 50%;
        display: inline-block;
        position: relative;
        left: 0;
        top: 0rem;
        color: ${({ theme }) => theme.colors.light_grey_soft_lines};
        border: ${({ theme }) => theme.borders.base};

        &::after {
            content: '';
            display: block;
            height: 1.2rem;
            width: 1.2rem;
            border-radius: 50%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: ${({ theme }) => theme.colors.primary_black};
            opacity: 0;
            transition: opacity 0.2s;
        }

        &.large {
            height: 6rem;
            width: 6rem;
            color: ${({ theme }) => theme.colors.black};
            border: ${({ theme }) => theme.borders.base};
            text-align: center;

            .label-text {
                position: relative;
                top: 20%;
            }
        }
    }

    input:checked ~ .radio-button::after {
        opacity: 1;
    }
    input:checked ~ .radio-button.large {
        transition: ${({ theme }) => theme.transitions.base};
        background-color: ${({ theme }) => theme.colors.backgrounds_muted_honey};

        &:after {
            transition: ${({ theme }) => theme.transitions.base};
            content: none;
        }
    }

    input:focus ~ .radio-button {
        ${mixins.focusShadow}
    }
`;

export const MyTextArea = styled(MyInput).attrs(() => ({ as: 'textarea' }))`
    padding: 0.5rem;
    font-size: 16px;
    font-family: ${({ theme }) => theme.typography.font_family.body};
    border: 1px solid black;
    transition: ${({ theme }) => theme.transitions.base};
    &:focus {
        outline: 0;
        border-color: ${({ theme }) => theme.colors.white};
    }
`;

export const MySelectWrapper = styled.div`
    i {
        margin: 0 1.1rem;
    }
    transition: ${({ theme }) => theme.transitions.base};
    border-radius: 0;
    cursor: pointer;

    /** React Select Styles */

    & .dropdown {
        &-container {
            font-size: ${({ theme }) => theme.typography.size.paragraph} !important;
            border-radius: 0;
            cursor: pointer;
            border-color: ${({ theme }) => theme.colors.black} !important;
            outline: ${({ theme }) => theme.colors.black} !important;
        }
        &__control {
            border-radius: 0;
            cursor: pointer;
            /* border-color: ${({ theme }) => theme.colors.black} !important; */
            /* outline: ${({ theme }) => theme.colors.black} !important; */
            &--is-focused {
                outline: ${({ theme }) => theme.colors.black} !important;
                border-color: ${({ theme }) => theme.colors.black} !important;
                &:hover {
                    border-color: ${({ theme }) => theme.colors.black};
                }
            }

            &--menu-is-open {
                .dropdown__indicators {
                    --icon-rotate: -180deg;
                }
            }
        }
        &__value-container {
            border-radius: 0;
            cursor: pointer;
            padding: ${({ theme }) => convertRems(theme.spacing.xs) / 2}rems;
        }
        &__indicators {
            border-radius: 0;
        }

        &__option {
            border-radius: 0;
            border: none;
            background-color: ${({ theme }) => theme.colors.white};
            color: ${({ theme }) => theme.colors.black};
            cursor: pointer;
            &:hover {
                background-color: ${({ theme }) => theme.colors.black};
                color: ${({ theme }) => theme.colors.white};
            }

            &--is-selected {
                background-color: ${({ theme }) => theme.colors.black};
                color: ${({ theme }) => theme.colors.white};
            }
        }
        &__menu {
            border-radius: 0;
            box-shadow: none;
            padding: 0;
            transition: ${({ theme }) => theme.transitions.base};
        }
        &__menu-list {
            border-radius: 0;
            cursor: pointer;
            padding: 0;
        }
    }
`;

interface MyErrorProps {
    isTouched?: boolean | undefined;
    message: string | string[] | FormikErrors<any> | FormikErrors<any>[] | undefined;
    name: string;
}

export const MyErrorStyles = styled.span`
    margin-left: 0.25em;
    color: ${({ theme }) => theme.colors._red};
    position: absolute;
`;

export const MyError: React.FC<MyErrorProps> = ({ message, isTouched, name }) => {
    if (isTouched && message && message?.length)
        // @ts-ignore
        return <MyErrorStyles className={`error ${name}-error`}>{message}</MyErrorStyles>;

    return null;
};

interface FormFieldProps {
    name: string;
    type?: 'text' | 'select' | 'textarea' | 'checkbox' | 'radio';
    multiple?: boolean;
    placeholder?: string;
    func?: (e: { target: { value: string } }) => void;
    selectOptions?: { label: string; value: string | number }[];
    checkboxOptions?: { label: string; value: string | number | boolean; name: string }[];
    radioOptions?: { label: string; value: string | number | boolean; name: string }[];
    hideFieldLabel?: boolean;
    className?: string;
    singleButton?: boolean;
    tooltip?: string;
    inputMode?: 'text' | 'email' | 'search' | 'tel' | 'url' | 'none' | 'numeric' | 'decimal';
}

export const FormField: React.FC<FormFieldProps> = ({
    name,
    type,
    placeholder,
    func,
    selectOptions,
    checkboxOptions,
    radioOptions,
    multiple,
    hideFieldLabel,
    className,
    singleButton,
    tooltip,
    inputMode = 'text',
}) => {
    const [field, meta, helpers] = useField({ name, type, multiple });

    const { setFieldValue, setFieldTouched } = useFormikContext();
    // const textarea = useRef(null)
    /**
     * Will manually set the value belong to the name props in the Formik form using setField
     */
    function handleOptionChange(selection) {
        setFieldValue(name, selection.value);
    }

    /**
     * Manually updated the touched property for the field in Formik
     */
    function updateBlur() {
        setFieldTouched(name, true);
    }
    // Icon.defaultProps = { name: 'chevron' };
    // const DropdownIndicator = Icon;

    return (
        <li
            data-tip={tooltip}
            data-for={slugify(tooltip || '')}
            className={`form-field ${meta.error && meta.touched ? 'field-error' : ''} ${className || ''}`}
        >
            {tooltip && (
                <ReactTooltip
                    id={slugify(tooltip || '')}
                    backgroundColor={bbTheme.colors.white}
                    textColor={bbTheme.colors.black}
                    border
                    borderColor={bbTheme.colors.black}
                />
            )}
            {type === 'text' && (
                <MyInput
                    className={!meta.error && meta.touched && field.value ? 'field-complete' : ''}
                    type={type}
                    placeholder={placeholder}
                    {...field}
                    inputMode={inputMode}
                />
            )}

            {type === 'select' && selectOptions && (
                <MySelectWrapper>
                    <Select
                        instanceId={'country-select'}
                        options={selectOptions}
                        {...field}
                        onBlur={updateBlur}
                        onChange={handleOptionChange}
                        value={matchLabelToOption(field.value, selectOptions)}
                        styles={reactSelectReset}
                        defaultValue={selectOptions[0]}
                        className="dropdown-container"
                        classNamePrefix="dropdown"
                        components={{ DropdownIndicator }}
                    />
                </MySelectWrapper>
            )}

            {type === 'checkbox' && (
                <>
                    <legend id={`checkbox-group-${field.name}`}>{placeholder}</legend>
                    <fieldset role="group" aria-labelledby={`checkbox-group-${field.name}`}>
                        {checkboxOptions?.map((option) => (
                            <MyLabel key={option.value.toString()} htmlFor={option.name} className="checkbox-label">
                                <CheckboxInput
                                    id={option.name}
                                    className="checkbox-input"
                                    checked={field.value.find((el) => el === option.value)}
                                    value={option.value}
                                    name={field.name}
                                />
                                <span className="label-text">{option.label} </span>{' '}
                            </MyLabel>
                        ))}
                    </fieldset>
                </>
            )}
            {type === 'radio' && (
                <>
                    <legend id={`radio-group-${field.name}`}>{placeholder}</legend>
                    <fieldset role="group" aria-labelledby={`radio-group-${field.name}`}>
                        {radioOptions?.map((option) => (
                            <MyLabel key={option.value.toString()} htmlFor={option.name} className="radio-label">
                                <RadioInput>
                                    <Field
                                        id={option.name}
                                        type="radio"
                                        value={option.value.toString()}
                                        name={field.name}
                                    />
                                    <div
                                        className={`radio-button ${singleButton ? 'large' : ''}`}
                                        role="button"
                                        title={option.value.toString()}
                                    >
                                        {singleButton && <span className="label-text">{option.label} </span>}
                                    </div>
                                </RadioInput>
                                {!singleButton && <span className="label-text">{option.label} </span>}
                            </MyLabel>
                        ))}
                    </fieldset>
                </>
            )}

            {/* {type === 'textarea' && <MyTextArea placeholder={placeholder} />} */}

            {type === 'textarea' && (
                <MyTextArea
                    as="textarea"
                    rows={typeof field.value === 'string' ? field.value.length / 75 + 3 : 5}
                    className={!meta.error && meta.touched && field.value ? 'field-complete' : ''}
                    placeholder={placeholder}
                    {...field}
                />
            )}

            {hideFieldLabel ? null : (
                <MyLabel htmlFor={field.name} className={''}>
                    <span className="label-text">{placeholder} </span>{' '}
                    {!meta.error && <span className="required">*</span>}
                    <MyError message={meta.error} isTouched={!!meta.touched} name={field.name} />
                </MyLabel>
            )}
        </li>
    );
};
