import styled from 'styled-components';
import mixins, { device } from '../../styles/mixins';

const FooterStyle = styled.footer`
    background: ${({ theme }) => theme.colors.gradients.footer};
    position: relative;
    z-index: 5;
    padding: ${({ theme }) => theme.spacing.l} 0 0 0;
    color: ${({ theme }) => theme.colors.grey_text};
    --svg-font-size: 2rem;
    &.grid-12 {
        grid-row-gap: ${({ theme }) => theme.spacing.m};
        @media ${device().medium_small} {
            grid-row-gap: ${({ theme }) => theme.spacing.l};
        }
        @media ${device().large} {
            grid-row-gap: ${({ theme }) => theme.spacing.xxl};
        }
    }

    .footer-topbar {
        grid-column: 2 / -2;
        grid-row: 1 / 2;
        padding-top: ${({ theme }) => theme.spacing.m};
        justify-content: center;
        align-items: center;

        > * {
            flex: 0 0 50%;

            max-width: 400px;
        }

        & input {
            border-color: ${({ theme }) => theme.colors.black};
        }

        @media ${device(true).small} {
            flex-flow: column;
            align-items: center;
        }
    }

    .profile {
        grid-column: 2 / 4;
        grid-row: 2 / 3;
        display: flex;
        flex-flow: column;
        position: relative;
        --svg-font-size: 4rem;

        .mask {
            position: relative;
            width: 10rem;
            height: 10rem;
            overflow: hidden;
            border-radius: 50%;
        }
        .logo {
            margin: ${({ theme }) => theme.spacing.xs} 0 0 18px;
        }
        svg {
            max-height: 4rem;
        }
        & img {
        }
    }
    .footer-main {
        grid-column: 4 / 12;
        grid-row: 2 / 3;
        flex-flow: wrap;

        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        @media ${device().large} {
            grid-column: 4 / -1;
        }
        @media ${device(true).medium_small} {
            grid-column: 2 / -2;
            grid-row: 3 / 4;
            flex-flow: column wrap;
        }
        .footer-menus {
            display: flex;
            justify-content: space-evenly;
            align-items: flex-start;

            @media ${device(true).large} {
                width: 100%;
            }

            @media ${device(true).medium_large} {
                flex-flow: wrap;
                width: 100%;
                justify-content: space-between;
            }

            .footer-menu {
                width: var(--one-12th);
                min-width: 15rem;

                @media ${device(true).small} {
                    flex: 1 1 50%;
                    margin-bottom: ${({ theme }) => theme.spacing.xxxl};
                }
            }
        }
    }

    .address {
        max-width: 20rem;
        grid-column: 12 / -1;
        grid-row: 2 / 3;

        @media ${device(true).large} {
            grid-column: 2 / 8;
            grid-row: 3 / 4;
        }

        @media ${device(true).medium_small} {
            grid-row: 4 / 5;
        }
    }

    .footer-bottombar {
        grid-column: 2 / -2;
        ${mixins.flex}
        justify-content: space-between;
        position: relative;
        @media ${device(true).medium_small} {
            align-items: flex-start;
            flex-flow: column;
            position: relative;
            .social-widgets {
                margin-bottom: ${({ theme }) => theme.spacing.s};
            }
        }
        .social-widgets {
            max-width: 300px;
            justify-content: flex-start;
            transform: translateX(-5px);
            a:nth-child(1) {
                padding-left: 0;
            }
        }

        p {
            margin: 0;
        }

        .legal {
        }
    }
`;

export default FooterStyle;
