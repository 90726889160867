import { MyLabel } from 'components/form/FormElements';
import React from 'react';
import styled from 'styled-components';
import Button from '../Layout/Buttons';

// interface Props {}

const QuantitySelectorStyles = styled.div`
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-size: 125%;

    --btn-min-width: 4.5rem;
    --btn-height: 4.5rem;
    --btn-padding: 1rem
    --color-btn-border: ${({ theme }) => theme.colors.light_grey_soft_lines};

    button:hover {
        --color-btn-secondary: ${({ theme }) => theme.colors.backgrounds_muted_honey};
        --color-btn-secondary-text: ${({ theme }) => theme.colors.black};
    }
    > span {
        margin: 0 1rem;
        max-width: 2rem;
        text-align: center;
    }
`;

interface Props {
    quantity: number;
    className?: string;
    decrement: () => void;
    increment: () => void;
}

const QuantitySelector: React.FC<Props> = ({ quantity, decrement, increment, className }) => {
    return (
        <>
            <MyLabel>Quantity</MyLabel>
            <QuantitySelectorStyles className={className}>
                <Button
                    btnStyle="secondary"
                    func={decrement}
                    title="Decrease Quantity"
                    text=""
                    circle
                    icon={{ name: 'minus' }}
                />
                <span>{quantity}</span>
                <Button
                    btnStyle="secondary"
                    func={increment}
                    title="Increase Quantity"
                    text=""
                    circle
                    icon={{ name: 'plus' }}
                />
            </QuantitySelectorStyles>
        </>
    );
};

export default QuantitySelector;
