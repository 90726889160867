import { MyLink } from 'components/Layout/Link';
import Link from 'next/link';
import React, { useContext } from 'react';
import CookieConsent from 'react-cookie-consent';
import { UserContext } from '../../state/context/global';
import { bbTheme } from '../styles/theme';
const ReactCookieConsent: React.FC = ({}) => {
    const user = useContext(UserContext);
    if (!user) return <div />;
    const buttonStyle = {
        background: bbTheme.colors.black,
        color: bbTheme.colors.white,
        border: '0',
        borderRadius: '0px',
        boxShadow: 'none',
        cursor: 'pointer',
        flex: '0 0 auto',
        padding: '5px 10px',
        margin: '15px',
    };
    const declineButtonStyle = {
        background: bbTheme.colors.white,
        color: bbTheme.colors.black,
        border: '0',
        borderRadius: '0px',
        boxShadow: 'none',
        cursor: 'pointer',
        flex: '0 0 auto',
        padding: '5px 10px',
        margin: '15px',
    };
    const bgStyle = {
        background: bbTheme.colors.white,
        borderTop: bbTheme.borders.base,
        color: bbTheme.colors.black,
    };
    return (
        <CookieConsent
            location="bottom"
            buttonText="I accept"
            onAccept={() => user.setCookieStatus('accept')}
            onDecline={() => user.setCookieStatus('decline')}
            declineButtonText="I decline"
            declineCookieValue="decline"
            enableDeclineButton
            declineButtonStyle={declineButtonStyle}
            cookieName="bbff"
            style={bgStyle}
            buttonStyle={buttonStyle}
            expires={150}
            sameSite="lax"
        >
            <p>
                {' '}
                We use cookies to better understand and enhance the user experience. We hope you understand. You can
                read more in our <MyLink text="Cookie Policy" btnStyle="clear" hrefProp="/cookie-policy" />{' '}
                <span>and our</span> <MyLink text="Privacy Policy" btnStyle="clear" hrefProp="/privacy-policy" />
            </p>
        </CookieConsent>
    );
};

export default ReactCookieConsent;
