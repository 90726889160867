import Divider from 'components/styles/general-elements/Divider';
import Title from 'components/styles/Title';
import React from 'react';
import styled from 'styled-components';

interface ContentTemplateProps {
    color?: string;
}
export const ContentArticle = styled.article<ContentTemplateProps>`
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    margin-bottom: ${({ theme }) => theme.spacing.xs};
    &.content-list {
        .Accordian-container {
            width: 100%;
            max-width: 750px;
        }
    }
`;

export const PageTitle = styled.h1<ContentTemplateProps>`
    color: ${({ theme, color }) => color || theme.colors.black};
    max-width: 600px;
`;

export const CAHeader = styled.h3<ContentTemplateProps>`
    /* margin: auto auto 1rem 0; */
    color: ${({ theme, color }) => color || theme.colors.black};

    max-width: 600px;
`;

export const CADivider = styled.hr<ContentTemplateProps>`
    width: 10%;
    height: 0.25rem;
    background-color: ${(props) => props.color || props.theme.colors.black};
    margin: auto auto 1rem 0;
`;

export const CALead = styled.p.attrs((props) => ({
    className: `${props.className || ''} featured`,
}))<ContentTemplateProps>``;
export const CAContent = styled.p<ContentTemplateProps>`
    margin-bottom: ${({ theme }) => theme.spacing.xs};
`;

export interface HeaderTxt {
    text: string;
}
export interface LeadTxt {
    text: string;
}
export interface ContentTxt {
    text: string;
}
export interface MenuTxt {
    text: string;
}
export interface QuoteTxt {
    text: string;
}

export interface ContentObject {
    header: HeaderTxt[];
    lead: LeadTxt[];
    content: ContentTxt[];
    menu: MenuTxt[];
    quotes?: QuoteTxt[];
    color?: string;
}
export interface GenericContentObject {
    header: HeaderTxt[];
    lead: LeadTxt[];
    content: ContentTxt[];
    menu: MenuTxt[];
    quotes?: QuoteTxt[];
    color?: string;
}

export interface ContentProps {
    color?: string;
    className?: string;
    layoutStyle?: 'opener' | 'regular';
    contentObj: ContentObject;
    headingLevel?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
}

const ContentTemplate: React.FC<ContentProps> = ({ contentObj, color, className, layoutStyle, headingLevel }) => {
    const { header, lead, content, menu } = contentObj;

    return (
        <ContentArticle className={className}>
            {header.length > 0 &&
                header.map((headText: HeaderTxt, idx) => {
                    if (layoutStyle === 'opener')
                        return (
                            <Title
                                as={headingLevel || 'h1'}
                                className={className == 'light' ? 'light' : ''}
                                color={color}
                                key={headText.text.slice(-12)}
                            >
                                {headText.text}
                            </Title>
                        );
                    return (
                        <Title
                            as={headingLevel || 'h2'}
                            className={className == 'light' ? 'light' : ''}
                            color={color}
                            key={headText.text.slice(-12)}
                        >
                            {headText.text}
                        </Title>
                    );
                })}

            {header[0].text.length > 0 && <Divider size="xs" />}

            {lead.length > 0 &&
                lead.map((leadText: LeadTxt, idx) => {
                    return (
                        <CALead
                            // className={`featured ${className == 'light' ? 'light' : ''}`}
                            color={color}
                            key={leadText.text.slice(-12)}
                        >
                            {leadText.text}
                        </CALead>
                    );
                })}

            {content.length > 0 &&
                content.map((contentTxt: ContentTxt, idx) => {
                    return (
                        <CAContent
                            color={color}
                            key={contentTxt.text.slice(-12)}
                            className={className == 'light' ? 'light' : ''}
                        >
                            {contentTxt.text}
                        </CAContent>
                    );
                })}
        </ContentArticle>
    );
};

export default ContentTemplate;
