import { InitialCheckoutState } from '../components/shop/interfaces';
import { CheckoutStep } from '../components/shop/types';
import { replaceItems_checkoutLineItemsReplace_checkout_lineItems } from '../src/types/shopify/storefront/replaceItems';
import { shippingRates_node_Checkout_availableShippingRates_shippingRates } from '../src/types/shopify/storefront/shippingRates';
import { GAEECCheckoutFunnelType } from '../utils/analytics';

export const dfContentObject = {
    header: [{ text: '' }],
    lead: [{ text: '' }],
    content: [{ text: '' }],
    menu: [{ text: '' }],
};

export const dfShippingRate: shippingRates_node_Checkout_availableShippingRates_shippingRates = {
    __typename: 'ShippingRate',
    handle: 'shopify-Standard-10.00',
    priceV2: {
        __typename: 'MoneyV2',
        amount: '10.0',
    },
    title: 'Standard',
};
export const dfLineItems: replaceItems_checkoutLineItemsReplace_checkout_lineItems = {
    __typename: 'CheckoutLineItemConnection',
    edges: [
        {
            __typename: 'CheckoutLineItemEdge',
            node: { __typename: 'CheckoutLineItem', id: '', quantity: 0, title: '' },
        },
    ],
};
export const dfCheckoutSteps: CheckoutStep[] = [
    { active: false, complete: true, slug: 'cart', title: 'Manage Cart', type: 'read', order: 0, validate: false },
    {
        active: true,
        complete: false,
        slug: 'shipping-info',
        title: 'Shipping Information',
        type: 'read',
        order: 1,
        validate: true,
    },
    {
        active: false,
        complete: false,
        slug: 'email',
        title: 'Customer Email',
        type: 'write',
        order: 2,
        validate: true,
    },
    {
        active: false,
        complete: false,
        slug: 'shipping-details',
        title: 'Shipping Details',
        type: 'write',
        order: 3,
        validate: true,
    },
    {
        active: false,
        complete: false,
        slug: 'note',
        title: 'Add a Note',
        type: 'write',
        order: 4,
        validate: false,
    },
    {
        active: false,
        complete: false,
        slug: 'delivery-date-info',
        title: 'Dispatch Information',
        type: 'read',
        order: 5,
        validate: false,
    },
    {
        active: false,
        complete: false,
        slug: 'delivery-date',
        title: 'Dispatch Date',
        type: 'write',
        order: 6,
        validate: true,
    },
    {
        active: false,
        complete: false,
        slug: 'checkout',
        title: 'Proceed to Checkout',
        type: 'write',
        order: 7,
        validate: false,
    },
];

type CheckoutStepShape = {
    [key in GAEECCheckoutFunnelType]: CheckoutStep;
};

// TODO Typescript doesn't like this?
// export const dfCheckoutStepObject: CheckoutStepShape = dfCheckoutSteps.reduce((obj, item) => {
//     obj[item.slug] = item;
//     return obj;
// }, {});

export const dfUserDetails = { firstName: '', lastName: '', email: '' };

export const dfUserNote = { to: '', from: '', note: '' };

export const dfContactForm = { name: '', subject: '', email: '', message: '' };

export const dfVariantOption = { label: '', value: '', variantId: '', amount: '' };
export const dfGiftCardStatus = {
    code: 'default',
    last4: '0000',
    id: '',
    applied: false,
    removed: false,
    remaining: '0.00',
};

export const dfCheckoutState: InitialCheckoutState = {
    // Checkout State
    isLoading: false,
    isFixedCalendar: false,
    isCheckoutStarted: false,
    isCalendarVis: false,
    isCheckoutReady: false,
    isCheckoutReloading: false,
    isCheckoutReloaded: false,
    checkoutStep: 1,
    userSteps: dfCheckoutSteps,
    shippingRule: 'regular',
    resets: [],
    checkoutData: undefined,

    // Checkout Data
    // checkoutId: '',
    // webUrl: '',
    // note: dfUserNote,
    // shippingAddr: undefined,
    // email: '',
    // shipDate: '',
    // shipRateHandle: '',
    // numItems: 1,
    // lineItems: dfLineItems,
};
