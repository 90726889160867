import Nav from 'components/navigation/Navigation';
import { BbLogoSmall } from 'components/styles/icons';
import { contactAddress, siteTitle, siteFooterNav } from 'core/constants';
import { staticImages } from 'images';
import Link from 'next/link';
import React from 'react';

import { capitalize } from 'utils/stringFunctions';
import NewsLetterForm from '../../form/NewsLetterForm';
import { buildSanityLink } from 'utils/sanity/sanity';
import MyImage from '../MyImage';
import SocialWidgets from '../social/SocialWidgets';
import FooterStyle from './Style';

interface FooterProps {
    hideSignup?: boolean;
}

const Footer: React.FC<FooterProps> = ({ hideSignup }) => {
    const year = new Date();
    return (
        <FooterStyle className="grid-12">
            <div className="footer-topbar flex-gap">
                {!hideSignup && (
                    <>
                        {' '}
                        <p className="footer-signup-text no-style caption-text--micro">
                            Receive updates and offers you’ll actually be interested in. Unsubscribe any time.{' '}
                        </p>
                        <div className="form-container">
                            <NewsLetterForm hide={{ fname: true, lname: true, email: false, button: true }} />
                        </div>
                    </>
                )}
            </div>

            {/* <!-- Footer main --> */}
            <div className="profile">
                <div className="mask">
                    <MyImage
                        // filename={staticImages.cafeAuLait.filename}
                        // transformations="w_400,h_400,c_crop,x_50,y_100,r_max/w_200"
                        className="profile mb-s"
                        // options={{ w: 75, h: 75 }}
                        nextImageProps={{
                            alt: 'Mags with cafe au lait',
                            src: staticImages.cafeAuLait.filename,
                            width: 554,
                            height: 831,
                            layout: 'intrinsic',
                        }}
                    />
                </div>
                <BbLogoSmall className="logo" />
            </div>
            <div className="footer-main">
                <div className="footer-menus">
                    {siteFooterNav.map((menu, i) => (
                        <div key={menu._key} className={`footer-menu footer-${menu}`}>
                            <h6>{capitalize(menu.title)}</h6>
                            <ul>
                                {menu.footerNavigationItem.map((navItem, idx) => (
                                    <li key={navItem._id}>
                                        <Link href={`/${buildSanityLink(navItem._type, navItem.slug)}`} passHref>
                                            <a title={navItem.page || navItem.product} href="0#">
                                                {navItem.title}
                                            </a>
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
            </div>
            <div className="address">
                <h6>The Farm</h6>
                <address>{contactAddress}</address>
            </div>
            <div className="footer-bottombar">
                <SocialWidgets type="horizontal" />
                <p className="legal">
                    &copy; {year.getFullYear()} Copyright {siteTitle}
                </p>
            </div>
        </FooterStyle>
    );
};

export default Footer;
