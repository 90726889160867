import { Product } from 'components/shop/types';
import {
    Q_ALL_COLLECTIONS,
    Q_ALL_FRONT_PAGE_PRODUCTS,
    Q_ALL_PAGES,
    Q_ALL_POSTS_FOR_HOME,
    Q_ALL_POSTS_WITH_SLUG,
    Q_ALL_PRODUCTS,
    Q_COLLECTION_BY_HANDLE,
    Q_COLLECTION_HANDLES,
    Q_CURRENT_ALERT,
    // Q_PAGE_BY_ROUTE,
    Q_PAGE_BY_SLUG,
    Q_POST_AND_MORE_POSTS_1,
    Q_POST_AND_MORE_POSTS_2,
    Q_PREVIEW_PAGE,
    // Q_PREVIEW_PAGE_BY_ROUTE,
    Q_PREVIEW_POST_BY_SLUG,
    Q_PREVIEW_PRODUCT,
    Q_PRODUCTS_BY_TYPE,
    Q_PRODUCT_BY_HANDLE,
    Q_PRODUCT_HANDLES,
    Q_PRODUCT_HANDLES_BY_TYPE_INCL,
    Q_RELATED_ARTICLES,
    Q_SHOP_CONFIG,
    Q_SHOP_CONFIG_FIXED_CALENDAR,
    Q_SHOP_CONFIG_POPUP,
    Q_SHOP_CONFIG_SHIPPING_INFORMATION,
    Q_SHOP_CONFIG_SHOPPING_EXCLUSION,
} from 'src/queries/sanity';
import { Page } from 'src/types/sanity/new-schema';
import { Alertbar, Article, ShopConfig } from 'src/types/sanity/schema';
import { previewClient, sanityClient } from './sanity';
const getUniqueArticles = (posts) => {
    const slugs = new Set();
    return posts.filter((post) => {
        if (slugs.has(post.slug)) {
            return false;
        } else {
            slugs.add(post.slug);
            return true;
        }
    });
};

const getClient = (preview = false) => (preview ? previewClient : sanityClient);

export async function getCurrentAlert(): Promise<Alertbar> {
    const data = await getClient().fetch(Q_CURRENT_ALERT);

    return data;
}

export async function getAllPages(): Promise<Page[]> {
    const pages = await getClient().fetch(Q_ALL_PAGES);
    return pages;
}

export async function getPageBySlug(slug: string, preview = false): Promise<Page> {
    const page = await getClient().fetch(Q_PAGE_BY_SLUG, { slug });

    return page;
}
// export async function getPageByRoute(slug: string): Promise<{ page: Page }> {
//     const page = await getClient().fetch(Q_PAGE_BY_ROUTE, { slug });
//     return page;
// }

export async function getPreviewArticleBySlug(slug): Promise<Article> {
    const data = await getClient(true).fetch(Q_PREVIEW_POST_BY_SLUG, { slug });
    return data[0];
}
// export async function getPreviewRouteBySlug(slug): Promise<Route> {
//     const data = await getClient(true).fetch(Q_PREVIEW_PAGE_BY_ROUTE, { slug });
//     return data;
// }
export async function getPreviewPageBySlug(slug): Promise<Page> {
    const data = await getClient(true).fetch(Q_PREVIEW_PAGE, { slug });
    return data;
}

export async function getAllArticlesWithSlug() {
    const data = await sanityClient.fetch(Q_ALL_POSTS_WITH_SLUG);
    return data;
}

export async function getAllArticlesForHome(preview = false, limit?: number) {
    const results = await getClient(preview).fetch(Q_ALL_POSTS_FOR_HOME);
    return getUniqueArticles(results).slice(0, limit);
}

export async function getArticlesByCategory(categories: string[], slug: string) {
    const relatedArticles = await getClient()
        .fetch(Q_RELATED_ARTICLES, { categories, slug })
        .then((data) => getUniqueArticles(data.reduce((acc, curr) => acc.concat(curr.relatedArticles), [])))
        .catch((error) => console.error({ error }));
    return relatedArticles;
}

export async function getArticleAndMoreArticles(
    slug: string,
    preview: boolean,
): Promise<{ post: Article; moreArticles: Article[]; relatedArticles: Article[] }> {
    const curClient = getClient(preview);

    const [post, moreArticles] = await Promise.all([
        curClient.fetch(Q_POST_AND_MORE_POSTS_1, { slug }).then((res) => res?.[0]),
        curClient.fetch(Q_POST_AND_MORE_POSTS_2, { slug }),
    ]);

    const categories = post?.categories?.map((cat) => cat.title);
    const relatedArticles = post?.categories?.length ? await getArticlesByCategory(categories, slug) : null;

    return { post, moreArticles: getUniqueArticles(moreArticles), relatedArticles };
}

export async function getAllFrontPageProducts(preview) {
    const results = await getClient(preview).fetch(Q_ALL_FRONT_PAGE_PRODUCTS);
    return results;
}

export async function getPreviewProduct(handle) {
    const results = await getClient(true).fetch(Q_PREVIEW_PRODUCT, { handle });
    return results;
}

export async function getAllProducts(): Promise<Product[]> {
    const results = await getClient().fetch(Q_ALL_PRODUCTS);
    return results;
}

export async function getProductsByType(productType: Product['store']['productType']): Promise<Product[]> {
    const results = await getClient().fetch(Q_PRODUCTS_BY_TYPE, { productType });
    return results;
}

export async function getProductByHandle(handle: string): Promise<Product> {
    const results = await getClient().fetch(Q_PRODUCT_BY_HANDLE, { handle });
    return results;
}

export async function getProductHandles(type?: Product['store']['productType']): Promise<Array<{ handle: string }>> {
    if (type) {
        const results = await getClient().fetch(Q_PRODUCT_HANDLES_BY_TYPE_INCL, { productType: type });
        return results;
    }
    const results = await getClient().fetch(Q_PRODUCT_HANDLES);
    return results;
}

export async function getAllCollections(): Promise<Product[]> {
    const results = await getClient().fetch(Q_ALL_COLLECTIONS);
    return results;
}

export async function getCollectionByHandle(handle: string): Promise<Product> {
    const results = await getClient().fetch(Q_COLLECTION_BY_HANDLE, { handle });
    return results;
}

export async function getCollectionHandles(): Promise<Array<{ handle: string }>> {
    const results = await getClient().fetch(Q_COLLECTION_HANDLES);
    return results;
}

/** Shop Config */

export async function getShopConfig(): Promise<ShopConfig> {
    const results = await getClient().fetch(Q_SHOP_CONFIG);
    return results;
}
export async function getShopConfigFixedCalendar(): Promise<ShopConfig['fixedCalendarDates']> {
    const { fixedCalendarDates } = await getClient().fetch(Q_SHOP_CONFIG_FIXED_CALENDAR);
    return fixedCalendarDates;
}
export async function getShopConfigPopup(): Promise<ShopConfig['popupProduct']> {
    const { popupProduct } = await getClient().fetch(Q_SHOP_CONFIG_POPUP);
    return popupProduct;
}
export async function getShopConfigShippingExclusion(): Promise<ShopConfig['shippingExclusion']> {
    const { shippingExclusion } = await getClient().fetch(Q_SHOP_CONFIG_SHOPPING_EXCLUSION);
    return shippingExclusion;
}
export async function getShopConfigShippingInfo(): Promise<ShopConfig['shippingInformation']> {
    const { shippingInformation } = await getClient().fetch(Q_SHOP_CONFIG_SHIPPING_INFORMATION);
    return shippingInformation;
}
