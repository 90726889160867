import * as React from 'react';
import PropTypes from 'prop-types';

function SvgYoutube(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 21 15"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.603 3.03a2.353 2.353 0 00-1.68-1.642C16.442 1 10.5 1 10.5 1s-5.941 0-7.423.388a2.353 2.353 0 00-1.68 1.642C1 4.478 1 7.5 1 7.5s0 3.022.397 4.47c.218.8.862 1.428 1.68 1.642C4.559 14 10.5 14 10.5 14s5.942 0 7.423-.388a2.354 2.354 0 001.68-1.642C20 10.522 20 7.5 20 7.5s0-3.022-.397-4.47z"
                fill="#141414"
                stroke="#000"
                pathLength="1"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.719 10.609V4.957l4.75 2.826-4.75 2.826z"
                fill="#fff"
                pathLength="1"
            />
        </svg>
    );
}

SvgYoutube.propTypes = {
    title: PropTypes.string,
};
export default SvgYoutube;
