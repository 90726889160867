import Divider from 'components/styles/general-elements/Divider';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { CartContext } from '../../../state/context/global';
import { isBrowser } from '../../../utils/booleans';
import { MyLink } from '../../Layout/Link';
import { CartItemProductNode } from '../interfaces';
import { CartProductExpandedStyles } from './CartProductStyles';
import SingleCartProduct from './Single';

interface Props {
    items: CartItemProductNode[];
    isCheckoutPage?: boolean | undefined;
}

export const EmptyProductsListStyles = styled.div`
    width: 100%;
    padding: 5rem 3rem;
    margin: 0 auto;
    text-align: center;

    p {
        margin: 3rem auto;
    }
`;

const CartProductList: React.FC<Props> = ({ items, isCheckoutPage }) => {
    const cart = useContext(CartContext);
    if (!cart) return null;
    const [isExpanded, setIsExpanded] = useState(cart.items.length > 1 ? false : true);
    function handleCart(): void {
        if (cart?.toggleCart && cart?.isOpen) {
            cart.toggleCart();
        }
    }

    function expandCart() {
        if (!isCheckoutPage) return;
        setIsExpanded(!isExpanded);

        if (isBrowser && isExpanded) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }

    if (items.length < 1) {
        return (
            <EmptyProductsListStyles>
                <p>There are no items in your cart. </p>
                <MyLink func={() => handleCart()} text="View our Store" hrefProp="/shop" />
            </EmptyProductsListStyles>
        );
    }

    if (isCheckoutPage)
        return (
            <CartProductExpandedStyles isCheckoutPage={isCheckoutPage} className="">
                {items.map((item, idx, arr) => {
                    return (
                        <li key={item.store.id + idx}>
                            <SingleCartProduct isExpanded={isExpanded} idx={idx} product={item} />
                            {idx < arr.length - 1 && <Divider size="s" />}
                        </li>
                    );
                })}
            </CartProductExpandedStyles>
        );

    return (
        <ul className="scrollable">
            {items.map((item, idx, arr) => {
                return (
                    <li key={item.store.id + idx}>
                        <SingleCartProduct isExpanded={isExpanded} idx={idx} product={item} />
                        {idx < arr.length - 1 && <Divider size="s" />}
                    </li>
                );
            })}
        </ul>
    );
};

export default CartProductList;
