import { motion } from 'framer-motion';
import { useRouter } from 'next/router';
import React from 'react';
import { MetaTags, ProductMetaTags } from 'src/interfaces/tag';
import styled from 'styled-components';
import ReactCookieConsent from '../analytics/ReactCookieConsent';
import AlertBar from './AlertBar';
import FooterIndex from './Footer';
import Header from './Header';
import Lightbox from './Modal/LightBoxModal';

const SiteLayoutStyles = styled.div`
    /* margin-top: 5rem; */
`;

export interface MyPageProps {
    children: React.ReactNode;
    metaTags?: MetaTags | ProductMetaTags;
    structuredData?: any;
}

export const Inner = styled(motion.main)``;

const SiteLayout: React.FC<MyPageProps> = ({ children }) => {
    const router = useRouter();

    return (
        <SiteLayoutStyles id="app">
            <AlertBar />
            <Header />

            <Inner
                key={router.route}
                initial="pageInitial"
                animate="pageAnimate"
                variants={{
                    pageInitial: {
                        opacity: 0,
                    },
                    pageAnimate: {
                        opacity: 1,
                    },
                }}
            >
                {children}
            </Inner>

            <ReactCookieConsent />
            <FooterIndex hideSignup={/cart/i.test(router.asPath)} />
            <Lightbox />
        </SiteLayoutStyles>
    );
};
export const getLayout = (page) => <SiteLayout>{page}</SiteLayout>;

export default SiteLayout;
