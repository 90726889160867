import { ToplineNavStyles } from 'components/navigation/NavStyles';
import Nav from 'components/navigation/Navigation';
import SvgBbLogoFull from 'components/styles/icons/BbLogoFull';
import { device } from 'components/styles/mixins';
import { bbTheme } from 'components/styles/theme';
import useKeyPress from 'hooks/useKeyPress';
import Link from 'next/link';
import Router, { useRouter } from 'next/router';
import NProgress from 'nprogress';
import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { isBrowser } from 'utils/booleans';
import { CartContext, MyAppContext } from '../../state/context/global';
import Button from './Buttons';

Router.events.on('routeChangeStart', NProgress.start);
Router.events.on('routeChangeComplete', NProgress.done);
Router.events.on('routeChangeError', NProgress.done);

interface NavLinkProps {
    children?: React.ReactNode;
    href: string;
    title: string;
    className?: string;
}

const LogoStyles = styled.div`
    .logo-small {
        width: 6rem;
    }

    .logo-full {
        width: 20rem;
        @media ${device().medium} {
            max-width: 110%;
        }
        @media ${device().small} {
            width: 25rem;
        }
    }
`;

interface FixedPlaceholderProps {
    height: string;
}

interface HeaderStyleProps {
    isCart: boolean;
    isTransparent: boolean;
}

export const FixedPlaceholderStyles = styled.div<FixedPlaceholderProps>`
    position: relative;
    top: 0;
    left: 0;
    height: ${({ height }) => height || 0};
`;

const StyledHeader = styled.header<HeaderStyleProps>`
    .header-bar {
        position: fixed;
        top: 0;
        width: 100vw;
        z-index: 101;
        background-color: ${({ theme, isTransparent }) => (isTransparent ? 'transparent' : theme.colors.white)};
        transition: ${({ theme }) => theme.transitions.base};
        padding: ${({ theme }) => theme.spacing.xs} 0;
        place-items: center;
        gap: ${({ theme }) => theme.spacing.xs};
        border-bottom: 1px solid
            ${({ theme, isTransparent }) => (isTransparent ? 'transparent' : theme.colors.light_grey_soft_lines)};
        --btn-min-width: 4rem;
        --btn-height: 4rem;
        --btn-border: none;
        --btn-padding: 0;

        /* hr {
            position: relative;
            top: 5px;
            transform: rotate(180deg);
            height: 3rem;
            width: auto;
            margin: 0;
        } */

        .buttons-left {
            grid-column: 2 / 3;
            /* min-width: 20rem; */
            justify-self: start;
            /* --icon-scale: 0.7; */

            margin: 0 -2rem;

            button svg {
                transition: none;
                margin: 0 -2rem;
                --svg-font-size: 5rem;
            }
            & .opened {
                pointer-events: none;
            }
            .menu-button {
                background-color: transparent;
            }
            .line {
                fill: none;
                stroke: black;
                stroke-width: 6;
                transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
                    stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
            }
            .line1 {
                stroke-dasharray: 60 207;
                stroke-width: 6;
            }
            .line2 {
                stroke-dasharray: 60 60;
                stroke-width: 6;
            }
            .line3 {
                stroke-dasharray: 60 207;
                stroke-width: 6;
            }
            .opened .line1 {
                stroke-dasharray: 90 207;
                stroke-dashoffset: -134;
                stroke-width: 6;
            }
            .opened .line2 {
                stroke-dasharray: 1 60;
                stroke-dashoffset: -30;
                stroke-width: 6;
            }
            .opened .line3 {
                stroke-dasharray: 90 207;
                stroke-dashoffset: -134;
                stroke-width: 6;
            }
        }

        .menu-left {
            grid-column: 3 / 7;
            --btn-min-width: fit-content;
            width: 100%;
            transform: translateX(-${({ theme }) => theme.spacing.s});

            /* @media ${device().medium_small} {
                grid-column: 2 / 5;
            } */
        }

        .Logo-container {
            grid-column: 7 / 9;
            /* place-self: self-start; */
            /* transform: translateX(-${({ theme }) => theme.spacing.xs}); */

            /* @media ${device().small} {
                grid-column: 6 / 10;
                justify-self: auto;
            } */
        }
        .menu-right {
            grid-column: 9 / 13;
            --btn-min-width: fit-content;
            width: 100%;
            transform: translateX(${({ theme }) => theme.spacing.s});
            /* width: 120%;
            transform: translateX(2rem); */
        }

        .buttons-right {
            justify-self: end;
            grid-column: 13 / 14;
            /* min-width: 20rem; */
            display: flex;
            justify-content: space-between;
        }
    }
`;
interface LogoProps {
    isHeaderVisible: boolean;
}

export const Logo: React.FC<LogoProps> = ({ isHeaderVisible }) => {
    return (
        <LogoStyles className="Logo-container">
            <Link href="/" passHref>
                {isHeaderVisible ? (
                    <a href="#0" title="Home">
                        <SvgBbLogoFull
                            strokeWidth="1"
                            stroke={bbTheme.colors.black}
                            className="logo-full hide-for- bblogo-singlestroke"
                            style={{ fontSize: '3.5rem' }}
                        />
                    </a>
                ) : (
                    <div />
                )}
            </Link>
        </LogoStyles>
    );
};

const Header: React.FC = () => {
    const router = useRouter();
    const app = useContext(MyAppContext);
    const cart = useContext(CartContext);
    const isEscPressed = useKeyPress('Escape');

    function scrollToTop() {
        if (isBrowser) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }

    useEffect(() => {
        if (isEscPressed && app.isNavOpen) {
            app.toggleNavDrawer('close');
        }
        if (isEscPressed && cart) {
            app.toggleNavDrawer('close');
        }
    }, [isEscPressed]);

    return (
        <>
            <StyledHeader isTransparent={false} isCart={/\/cart/.test(router.pathname)}>
                <div className="header-bar grid-12">
                    <div className="buttons-left">
                        <Button
                            className={`menu-button ${app.isNavOpen ? 'opened' : ''}`}
                            title={app.isNavOpen ? 'Close Full Menu' : 'Open Full Menu'}
                            func={() => {
                                !app.isNavOpen ? app.toggleNavDrawer('open') : null;
                            }}
                            aria-label="Main Menu"
                            icon={{ name: 'animated-close' }}
                        />
                    </div>
                    <div className="menu-left hide-for-medium">
                        <ToplineNavStyles id="topline-left" title="Topline Menu Left">
                            <Nav location="topline-left" />
                        </ToplineNavStyles>
                    </div>
                    <Logo isHeaderVisible={true} />
                    <div className="menu-right hide-for-medium">
                        <ToplineNavStyles id="topline-right" title="Topline Menu Right">
                            <Nav location="topline-right" />
                        </ToplineNavStyles>
                    </div>
                    <div className="buttons-right">
                        <Button
                            func={router.pathname !== '/cart' ? () => cart.toggleCart(undefined) : () => scrollToTop()}
                            text={(cart.items?.length || 0).toString()}
                            circle
                            btnStyle="accent-soft"
                            title={cart.isOpen ? 'Close Cart Drawer' : 'Open Cart Drawer'}
                        />
                    </div>
                </div>
            </StyledHeader>
        </>
    );
};

export default Header;
