import { css, DefaultTheme } from 'styled-components';
import { bbTheme } from 'components/styles/theme';

export const device = (isMax = false) =>
    Object.keys(bbTheme.breakpoints).reduce((acc, cur) => {
        acc[cur] = `(${isMax ? 'max' : 'min'}-width: ${bbTheme.breakpoints[cur]}px)`;
        return acc;
    }, {}) as DefaultTheme['breakpoints'];

const flex = css`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const focusShadow = css`
    box-shadow: 0 0 7px 5px ${({ theme }) => theme.colors.primary_black};
`;

const hideVisually = css`
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
`;

export const banner = css`
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
`;

export const link = css`
    border-bottom: 2px solid transparent;
    cursor: pointer;
    max-width: max-content;
    max-width: -moz-max-content;
    transition: all 200ms ease-in-out;
    &:hover {
        opacity: 0.8;
        border-bottom-color: ${({ theme }) => theme.colors._grey[800]};
    }
`;

export const hoverLightBg = css`
    &:hover {
        color: ${({ theme }) => theme.colors.black};
        border-color: ${({ theme }) => theme.colors.black};
        background-color: ${({ theme }) => theme.colors.white + 'CC'};
    }
`;

export const hoverDarkBg = css`
    &:hover {
        color: ${({ theme }) => theme.colors.white};
        border-color: ${({ theme }) => theme.colors.white};
        background-color: ${({ theme }) => theme.colors._grey[800] + 'CC'};
    }
`;

export const removeScrollbars = css`
    scrollbar-width: none;
    &::-webkit-scrollbar {
        width: 0px; /* Remove scrollbar space */
        background: transparent; /* Optional: just make scrollbar invisible */
    }
`;

export const sectionPadding = css`
    padding: 7rem 7rem 10rem 7rem;
    @media only screen and (max-width: 1024px) {
        padding: 5rem 3rem 5rem 3rem;
    }
    @media only screen and (max-width: 768px) {
        padding: 5rem 1.5rem 5rem 1.5rem;
    }
    @media only screen and (max-width: 500px) {
        padding: 3rem 1rem 3rem 1rem;
    }
    /* @media only screen and (max-width: 400px) {
        padding: 0;
    } */
`;
export const sectionPaddingNoTop = css`
    padding: 0 7rem 10rem 7rem;
    @media only screen and (max-width: 1024px) {
        padding: 0 3rem 5rem 3rem;
    }
    @media only screen and (max-width: 768px) {
        padding: 0 1.5rem 5rem 1.5rem;
    }
    @media only screen and (max-width: 500px) {
        padding: 0 0.5rem 3rem 0.5rem;
    }
    /* @media only screen and (max-width: 400px) {
        padding: 0;
    } */
`;
export const sectionPaddingNoBottom = css`
    padding: 7rem 7rem 0 7rem;
    @media only screen and (max-width: 1024px) {
        padding: 5rem 3rem 0 3rem;
    }
    @media only screen and (max-width: 768px) {
        padding: 3rem 1.5rem 0 1.5rem;
    }
    @media only screen and (max-width: 500px) {
        padding: 1rem 0.5rem 0 0.5rem;
    }
    /* @media only screen and (max-width: 400px) {
        padding: 0;
    } */
`;
export const sectionPaddingNoSides = css`
    padding: 7rem 0;
    @media only screen and (max-width: 1024px) {
        padding: 5rem 0;
    }
    @media only screen and (max-width: 768px) {
        padding: 3rem 0;
    }

    /* @media only screen and (max-width: 400px) {
        padding: 0;
    } */
`;
export const sectionPaddingOnlySides = css`
    padding: 0 7rem;
    @media only screen and (max-width: 1024px) {
        padding-right: 3rem;
        padding-left: 3rem;
    }
    @media only screen and (max-width: 768px) {
        padding-right: 1.5rem;
        padding-left: 1.5rem;
    }

    /* @media only screen and (max-width: 400px) {
        padding: 0;
    } */
`;

export const sectionMargin = css`
    margin: 7rem 5rem;
    @media only screen and (max-width: 1024px) {
        flex-flow: column;
        margin: 3rem 3rem;
    }
    @media only screen and (max-width: 768px) {
        margin: 1.5rem 1.5rem;
    }
    @media only screen and (max-width: 500px) {
        margin: 0;
    }
`;
export const sectionMarginEnds = css`
    margin: 5rem 0;
    @media only screen and (max-width: 1024px) {
        flex-flow: column;
        margin: 3rem 0;
    }
    @media only screen and (max-width: 768px) {
        margin: 2rem 0;
    }
    @media only screen and (max-width: 500px) {
        margin: 1rem;
    }
`;

const mixins = {
    device,
    flex,
    banner,
    focusShadow,
    hideVisually,
    link,
    hoverLightBg,
    hoverDarkBg,
    removeScrollbars,
    sectionPadding,
    sectionPaddingNoTop,
    sectionPaddingNoBottom,
    sectionPaddingNoSides,
    sectionPaddingOnlySides,
    sectionMargin,
    sectionMarginEnds,
};

export default mixins;
