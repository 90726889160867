import styled, { createGlobalStyle } from 'styled-components';
import { device } from './mixins';

// const BaseLayout = createGlobalStyle`
const BaseLayout = createGlobalStyle`
    main, .grid-12 {
        display: grid;
        /* & > section {
        margin-top: ${({ theme }) => theme.spacing.gutter};
    } */
        grid-template-columns:
            ${({ theme }) => theme.spacing.gutter}
            repeat(12, 1fr)
            ${({ theme }) => theme.spacing.gutter};
            gap: ${({ theme }) => theme.spacing.l} 0;


        & > * {
            /* border: 1px solid red; */
            grid-column: 2 / -2;
        }

        & > .full-bleed {
            width: 100%;
            grid-column: 1 / -1;
        }
        & > .full-bleed-r {
            width: 100%;
            grid-column: 2 / -1;
        }
        & > .full-bleed-l {
            width: 100%;
            grid-column: 1 / -2;
        }
    }

    .breakout-grid {
    /* Adaptation of https://www.joshwcomeau.com/css/full-bleed/ */

    display: grid;
    grid-template-columns:
        1fr
        min(${({ theme }) => theme.typography.maxPWidth}, 100%)
        1fr;

        & > .full-bleed {
            width: 100%;
            grid-column: 1 / -1;
        }
    }
    .breakout-grid > * {
        grid-column: 2;
    }



`;

export default BaseLayout;
