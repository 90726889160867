import styled, { createGlobalStyle } from 'styled-components';
import { device, removeScrollbars } from './mixins';

/**
 * 0. Root
 * 1. Base
 * 2. Elements
 * 3. Typography
 * 4. Common Classnames
 * 5. Utility
 * 6. Simple Animations
 *
 */

const GlobalStyle = createGlobalStyle`
    :root {
        /* Elements */
        --badge-width: 6rem;
        --icon-scale: 1;
        --icon-rotate: 0;
        /* --icon-translate: none; */
        --icon-width: 2rem;
        --btn-height: auto;
        --btn-min-width: fit-content;
        --btn-padding: 1.2rem 2.4rem;
        --color-btn-text: ${({ theme }) => theme.colors.black};
        --color-btn: ${({ theme }) => theme.colors.white};
        --one-12th: calc(92.2vw / 12);
        --social-widgets-margin: 0;
        --gap: ${({ theme }) => theme.spacing.xs};
        --nav-height: 6.5rem;
    }

    /*
* 1. Base
*/

    html {
        box-sizing: border-box;
        font-size: 62.5%; // 10px/16px = 62.5% -> 1rem = 10px

        @media ${device(true).large} {
            font-size: 56.25%; //1 rem = 9px, 9/16 = 50%
        }
        /* @media ${device(true).medium} {
            font-size: 50%; //1 rem = 8px, 8/16 = 50%

        } 
         @media ${device().xl} {
            font-size: 75%; //1rem = 12, 12/16
        } */

        ${removeScrollbars}
        height: 100%;

        @media (min-width: 40em) {
            :root {
                --ratio: --ratio-alt;
            }
        }
    }

    *,
    *::before,
    *::after {
        margin: 0;
        padding: 0;
        box-sizing: inherit;
    }

    body {
        line-height: 1.6;
        height: 100%;
        /* scroll-behavior: smooth; */
        font-family: ${({ theme }) => theme.font.body};
        /* margin-right: 0 !important; * FS Lightbox adds 15px of  this for some reason */
        /* margin: 5rem 0 0 0; */
        font-size: 1.6rem;
        background-color: ${({ theme }) => theme.colors.white};
        padding-top: 6rem;
        @media ${device().small} {
            --badge-width: 8rem;
        }
    }

    /* :target:before {
        content: "";
        display: block;
        height: 20vh;
        margin: -20vh 0 0;
        visibility: hidden;
    } */

    main > * {
        scroll-margin-top: 10rem;
    }

    .target-ref{
        scroll-margin-top: 10rem;
    }

    #__next {
        height: 100%;
    }

    #app {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    main {
        flex: 1 0 auto;
        /* overflow-x: hidden; */
    }

    footer {
        flex-shrink: 0;
    }

    ::placeholder,
    ::-moz-placeholder,
    :-ms-input-placeholder,
    ::-webkit-input-placeholder {
        color: ${({ theme }) => theme.colors.black};
        opacity: 0.5;
    }

    /*
* 2. Elements
*/
    a {
        text-decoration: none;
        color: ${({ theme }) => theme.colors.black};
        border-bottom: ${({ theme }) => theme.borders.transparent};
        transition: ${({ theme }) => theme.transitions.base};
        cursor: pointer;
        &:hover:not(.widget) {
            transition: ${({ theme }) => theme.transitions.base};
            opacity: 0.8;
        }
        &.text-link:hover:not(.widget) {
            border-bottom-color: currentColor;
        }
    }

    input[type='color'],
    input[type='date'],
    input[type='datetime'],
    input[type='datetime-local'],
    input[type='email'],
    input[type='month'],
    input[type='number'],
    input[type='password'],
    input[type='search'],
    input[type='tel'],
    input[type='text'],
    input[type='time'],
    input[type='url'],
    input[type='week'],
    input[type='phone'],
    select:focus,
    form label,
    textarea {
        font-size: 16px;
    }

    address {
        font-style: normal;
    }

    hr {
    }

    svg {
        font-size: var(--svg-font-size, 3rem);
    }

    /* Sanity Rich Text (PortableText) */

    .PortableText-inline-image {
        display: flex;
        .MyImage-container {
            width: auto;
            height: auto;
        }
        &.center {
            justify-content: center;
        }
    }

    .LongForm-container .PortableText-container {
        grid-column: 1 / -1;

        @media ${device().medium_small} {
            grid-column: 2 / -2;
        }
    }

   
    .Accordian-container .Youtube-container iframe {
            width: 100%;
    }

    .PortableText-container {


        .Youtube-container {
            max-width: 100%;
            @media ${device().small} {
            max-width: 95%;
            }

            iframe {
                max-width: 100%
            }
        }   
    }

    .Gallery {
        max-width: 95vw;
        margin: 0 auto;
    }
    /* 
    .ContentCenterpiece-container ~ .ContentCenterpiece-container {
        padding-top: 0;
        padding-bottom: 0;
    } */

    /* 
    * 3. Typography 
    */

    h1 {
        font-family: ${({ theme }) => theme.typography.font_family.heading};
        font-size: ${({ theme }) => theme.typography.size.h2};
        font-weight: ${({ theme }) => theme.typography.weight.medium};
        font-style: normal;
        line-height: 130%;
        color: ${({ theme }) => theme.colors.black};
        margin-bottom: ${({ theme }) => theme.spacing.xs};

        &.heading-1 {
            font-size: ${({ theme }) => theme.typography.size.h2};
        }

        /* @media only screen and (max-width: ${({ theme }) => theme.breakpoints.medium}px) {
        //styleName: Mobile - H1;
        font-size: ${({ theme }) => theme.typography.size.mobile_h1};
        font-weight: ${({ theme }) => theme.typography.weight.normal};

    } */
    }

    h2,
    .heading-2 {
        font-family: ${({ theme }) => theme.typography.font_family.heading};
        font-size: ${({ theme }) => theme.typography.size.h2};
        font-weight: ${({ theme }) => theme.typography.weight.normal};
        color: ${({ theme }) => theme.colors.black};
        margin-bottom: ${({ theme }) => theme.spacing.xs};
        line-height: 130%;

        &.light {
            color: ${({ theme }) => theme.colors.grey_text};
        }

        /* @media only screen and (max-width: ${({ theme }) => theme.breakpoints.large}px) {
        //styleName: Mobile - H2;
        font-size: ${({ theme }) => theme.typography.size.mobile_h3};
        font-weight: ${({ theme }) => theme.typography.weight.normal};

    } */
    }

    h3,
    .heading-3 {
        font-family: ${({ theme }) => theme.typography.font_family.heading};
        font-size: ${({ theme }) => theme.typography.size.h3};
        font-weight: ${({ theme }) => theme.typography.weight.normal};
        line-height: 130%;
        color: ${({ theme }) => theme.colors.black};
        margin-bottom: ${({ theme }) => theme.spacing.xs};

        &.light {
            color: ${({ theme }) => theme.colors.grey_text};
        }

        /* @media only screen and (max-width: ${({ theme }) => theme.breakpoints.large}px) {
        //styleName: Mobile - H3;
        font-size: ${({ theme }) => theme.typography.size.mobile_h3};
        font-weight: ${({ theme }) => theme.typography.weight.normal};

    } */
    }

    h4,
    .heading-4 {
        font-family: ${({ theme }) => theme.typography.font_family.heading};
        font-size: ${({ theme }) => theme.typography.size.h4};
        font-weight: ${({ theme }) => theme.typography.weight.normal};
        line-height: 130%;
        color: ${({ theme }) => theme.colors.black};
        margin-bottom: ${({ theme }) => theme.spacing.xs};

        &.light {
            color: ${({ theme }) => theme.colors.grey_text};
        }

        /* @media only screen and (max-width: ${({ theme }) => theme.breakpoints.large}px) {
        //styleName: Mobile - H4;
        font-size: ${({ theme }) => theme.typography.size.mobile_h4};
        font-weight: ${({ theme }) => theme.typography.weight.normal};

    } */
    }
    h5,
    .heading-5 {
        font-family: ${({ theme }) => theme.typography.font_family.heading};
        font-size: ${({ theme }) => theme.typography.size.h5};
        font-weight: ${({ theme }) => theme.typography.weight.normal};
        line-height: 130%;
        color: ${({ theme }) => theme.colors.black};

        &.light {
            color: ${({ theme }) => theme.colors.grey_text};
        }
    }
    h6,
    .heading-6 {
        font-family: ${({ theme }) => theme.typography.font_family.body};
        font-size: ${({ theme }) => theme.typography.size.h6};
        font-weight: ${({ theme }) => theme.typography.weight.extra_bold};
        color: ${({ theme }) => theme.colors.black};

        letter-spacing: 0.1em;
        text-transform: uppercase;
        &.light {
            color: ${({ theme }) => theme.colors.grey_text};
        }

        &.alt {
            font-family: ${({ theme }) => theme.typography.font_family.heading};
            font-size: ${({ theme }) => theme.typography.size.h6};
            font-weight: ${({ theme }) => theme.typography.weight.normal};
            color: ${({ theme }) => theme.colors.black};

            letter-spacing: 0.02em;
            text-transform: uppercase;
        }
    }

    .header-nav-title {
        //styleName: Header Navigation Titles;
        font-family: ${({ theme }) => theme.typography.font_family.body};
        font-size: ${({ theme }) => theme.typography.size.header_navigation_titles};
        font-weight: ${({ theme }) => theme.typography.weight.extra_bold};

        letter-spacing: 0.25em;
    }

    .nav-text {
        //styleName: Navigation;
        font-family: ${({ theme }) => theme.typography.font_family.body};
        font-size: ${({ theme }) => theme.typography.size.navigation};
        font-weight: ${({ theme }) => theme.typography.weight.normal};

        letter-spacing: 0.02em;
    }

    p,
    .paragraph {
        //styleName: Paragraph;
        font-family: ${({ theme }) => theme.typography.font_family.body};
        font-size: ${({ theme }) => theme.typography.size.paragraph};
        font-weight: ${({ theme }) => theme.typography.weight.normal};
        margin-bottom: ${({ theme }) => theme.spacing.xs};
        max-width: ${({ theme }) => theme.typography.maxPWidth};
        color: ${({ theme }) => theme.colors.black};
        &.featured {
            //styleName: Featured Paragraph;
            font-family: ${({ theme }) => theme.typography.font_family.heading};
            font-size: ${({ theme }) => theme.typography.size.featured_paragraph};

            @media only screen and (max-width: ${({ theme }) => theme.breakpoints.xs}px) {
                //styleName: Mobile - Featured Paragraph;
                font-size: ${({ theme }) => theme.typography.size.mobile_featured_paragraph};
            }
        }

        &.serif {
            font-family: ${({ theme }) => theme.typography.font_family.heading};
        }

        &.italics {
            //styleName: Paragraph Italics;
            font-family: ${({ theme }) => theme.typography.font_family.heading};
            font-size: ${({ theme }) => theme.typography.size.paragraph_italics};
            font-style: italic;
            color: ${({ theme }) => theme.colors.black};
        }

        &.aside {
            text-transform: uppercase;
            letter-spacing: 2px;
            font-weight: ${({ theme }) => theme.typography.weight.light};
        }
    }

    .drop-cap {
        //styleName: Dropcaps;
        font-family: ${({ theme }) => theme.typography.font_family.heading};
        font-size: ${({ theme }) => theme.typography.size.dropcaps};
        font-weight: ${({ theme }) => theme.typography.weight.normal};
    }

    .quote {
        //styleName: Article quotes;
        font-family: ${({ theme }) => theme.typography.font_family.heading};
        font-size: ${({ theme }) => theme.typography.size.article_quotes};
        font-weight: ${({ theme }) => theme.typography.weight.normal};
        font-style: italic;
    }

    .caption-text {
        //styleName: Caption Text;
        font-family: ${({ theme }) => theme.typography.font_family.body};
        font-size: ${({ theme }) => theme.typography.size.caption_text};
        font-weight: ${({ theme }) => theme.typography.weight.normal};
        color: ${({ theme }) => theme.colors.black};

        &.bold {
            font-size: ${({ theme }) => theme.typography.size.caption_text_bold};
            font-weight: ${({ theme }) => theme.typography.weight.extra_bold};
        }

        &.micro {
            //styleName: Micro caption text;
            font-family: ${({ theme }) => theme.typography.font_family.heading};
            font-size: ${({ theme }) => theme.typography.size.micro_caption_text};
            &.light {
                color: ${({ theme }) => theme.colors.black};
                font-family: ${({ theme }) => theme.typography.font_family.body};
            }
        }

        &.light {
            font-weight: ${({ theme }) => theme.typography.weight.light};
        }
    }

    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    span,
    blockquote,
    q,
    footer,
    div {
        &.dark {
            color: ${({ theme }) => theme.colors.black};
        }
        &.light {
            color: ${({ theme }) => theme.colors.light_grey_soft_lines};
        }

        &.text-left {
            text-align: left;
        }
        &.text-center {
            text-align: center;
        }
        &.text-right {
            text-align: right;
        }
    }

    footer a,
    .footer-text {
        //styleName: Footer Links;
        font-family: ${({ theme }) => theme.typography.font_family.heading};
        font-size: ${({ theme }) => theme.typography.size.footer_links};
        font-weight: ${({ theme }) => theme.typography.weight.normal};
        color: ${({ theme }) => theme.colors.grey_text};
        line-height: 222%;
    }

    .loose-track {
        //styleName: Loose Track;
        font-family: ${({ theme }) => theme.typography.font_family.heading};
        font-family: ${({ theme }) => theme.typography.size.loose_track};
        font-weight: ${({ theme }) => theme.typography.weight.light};
        letter-spacing: 0.565em;
        text-transform: uppercase;
    }

    .currentColor {
        color: currentColor;
    }

    /* 
    * 4. Common Classnames 
    */

    .anchor {
        display: block;
        position: relative;
        visibility: hidden;
    }
    .fade {
        color: ${({ theme }) => theme.colors.light_grey_soft_lines};
    }

    .card {
        background-color: ${({ theme }) => theme.colors.white};
        box-shadow: ${({ theme }) => theme.bShadow.sm};
        /* border-radius: ${({ theme }) => theme.borderRadius.sm}; */
        padding: ${({ theme }) => theme.spacing.xs};
        border: ${({ theme }) => theme.borders.base};
    }

    .is-interactive {
        transition: opacity 300ms ease-in;
        cursor: pointer;
        &:hover {
            transition: opacity 200ms ease-out;
            opacity: 0.9;
        }
    }

    /*
    * 5. Utilities
    */

    .flex-col {
        display: flex;
        flex-direction: column;
    }

    .flex-gap {
        display: inline-flex;
        margin: calc(-1 * var(--gap)) 0 0 calc(-1 * var(--gap));
        width: calc(100% + var(--gap));

        &-wrap {
            flex-wrap: wrap;
        }

        &.space-between {
            justify-content: space-between;
        }
        &.space-evenly {
            justify-content: space-evenly;
        }
        &.space-around {
            justify-content: space-around;
        }

        &.center {
            justify-content: center;
        }
    }

    .flex-gap > * {
        margin: var(--gap) 0 0 var(--gap);
    }

    .flex-ai-start {
        align-items: flex-start;
    }
    .flex-ai-center {
        align-items: center;
    }
    .flex-ai-baseline {
        align-items: baseline;
    }
    .flex-ai-end {
        align-items: flex-end;
    }

    .flex-jc-start {
        justify-content: flex-start;
    }
    .flex-jc-center {
        justify-content: center;
    }
    .flex-jc-end {
        justify-content: flex-end;
    }

    .hide-from-user {
        visibility: hidden;
        position: absolute;
        opacity: 0;
    }

    /** Show Elements */

    .show-for-xxs  {
        @media only screen and (min-width: ${({ theme }) => theme.breakpoints.xxs}px) {
            display: none !important;
        }
    }
    .show-for-xs  {
        @media only screen and (min-width: ${({ theme }) => theme.breakpoints.xs}px) {
            display: none !important;
        }
    }
    .show-for-small {
        @media only screen and (min-width: ${({ theme }) => theme.breakpoints.small}px) {
            display: none !important;
        }
    }
    .show-for-medium_small {
        @media only screen and (min-width: ${({ theme }) => theme.breakpoints.medium_small}px) {
            display: none !important;
        }
    }
    .show-for-medium {
        @media only screen and (min-width: ${({ theme }) => theme.breakpoints.medium}px) {
            display: none !important;
        }
    }
    .show-for-medium_large {
        @media only screen and (min-width: ${({ theme }) => theme.breakpoints.medium_large}px) {
            display: none !important;
        }
    }
    .show-for-large {
        @media only screen and (min-width: ${({ theme }) => theme.breakpoints.large}px) {
            display: none !important;
        }
    }
    .show-for-xl {
        @media only screen and (min-width: ${({ theme }) => theme.breakpoints.xl}px) {
            display: none !important;
        }
    }
    .show-for-xxl {
        @media only screen and (min-width: ${({ theme }) => theme.breakpoints.xxl}px) {
            display: none !important;
        }
    }

    /** Hide Elements */

    .hide-for-xxs {
        @media only screen and (max-width: ${({ theme }) => theme.breakpoints.xxs}px) {
            display: none !important;
        }
    }
    
    .hide-for-xs {
        @media only screen and (max-width: ${({ theme }) => theme.breakpoints.xs}px) {
            display: none !important;
        }
    }
    .hide-for-small {
        @media only screen and (max-width: ${({ theme }) => theme.breakpoints.small}px) {
            display: none !important;
        }
    }
    .hide-for-medium_small {
        @media only screen and (max-width: ${({ theme }) => theme.breakpoints.medium_small}px) {
            display: none !important;
        }
    }
    .hide-for-medium {
        @media only screen and (max-width: ${({ theme }) => theme.breakpoints.medium}px) {
            display: none !important;
        }
    }
    .hide-for-medium_large {
        @media only screen and (max-width: ${({ theme }) => theme.breakpoints.medium_large}px) {
            display: none !important;
        }
    }
    .hide-for-large {
        @media only screen and (max-width: ${({ theme }) => theme.breakpoints.large}px) {
            display: none !important;
        }
    }
    .hide-for-xl {
        @media only screen and (max-width: ${({ theme }) => theme.breakpoints.xl}px) {
            display: none !important;
        }
    }
    .hide-for-xxl {
        @media only screen and (max-width: ${({ theme }) => theme.breakpoints.xxl}px) {
            display: none !important;
        }
    }

    /* Margin Top */
    .mt-xs {
        margin-top: ${({ theme }) => theme.spacing.xs};
    }
    .mt-s {
        margin-top: ${({ theme }) => theme.spacing.s};
    }
    .mt-m {
        margin-top: ${({ theme }) => theme.spacing.m};
    }
    .mt-l {
        margin-top: ${({ theme }) => theme.spacing.l};
    }
    .mt-xl {
        margin-top: ${({ theme }) => theme.spacing.xl};
    }
    .mt-xxl {
        margin-top: ${({ theme }) => theme.spacing.xxl};
    }
    .mt-xxl {
        margin-top: ${({ theme }) => theme.spacing.xxxl};
    }
    /* Margin Bottom */
    .mb-xs {
        margin-bottom: ${({ theme }) => theme.spacing.xs};
    }
    .mb-s {
        margin-bottom: ${({ theme }) => theme.spacing.s};
    }
    .mb-m {
        margin-bottom: ${({ theme }) => theme.spacing.m};
    }
    .mb-l {
        margin-bottom: ${({ theme }) => theme.spacing.l};
    }
    .mb-xl {
        margin-bottom: ${({ theme }) => theme.spacing.xl};
    }
    .mb-xxl {
        margin-bottom: ${({ theme }) => theme.spacing.xxl};
    }
    .mb-xxl {
        margin-bottom: ${({ theme }) => theme.spacing.xxxl};
    }
    /* Padding Top */
    .pt-xs {
        padding-top: ${({ theme }) => theme.spacing.xs};
    }
    .pt-s {
        padding-top: ${({ theme }) => theme.spacing.s};
    }
    .pt-m {
        padding-top: ${({ theme }) => theme.spacing.m};
    }
    .pt-l {
        padding-top: ${({ theme }) => theme.spacing.l};
    }
    .pt-xl {
        padding-top: ${({ theme }) => theme.spacing.xl};
    }
    .pt-xxl {
        padding-top: ${({ theme }) => theme.spacing.xxl};
    }
    .pt-xxl {
        padding-top: ${({ theme }) => theme.spacing.xxxl};
    }
    /* Padding Bottom */
    .pb-xs {
        padding-bottom: ${({ theme }) => theme.spacing.xs};
    }
    .pb-s {
        padding-bottom: ${({ theme }) => theme.spacing.s};
    }
    .pb-m {
        padding-bottom: ${({ theme }) => theme.spacing.m};
    }
    .pb-l {
        padding-bottom: ${({ theme }) => theme.spacing.l};
    }
    .pb-xl {
        padding-bottom: ${({ theme }) => theme.spacing.xl};
    }
    .pb-xxl {
        padding-bottom: ${({ theme }) => theme.spacing.xxl};
    }
    .pb-xxl {
        padding-bottom: ${({ theme }) => theme.spacing.xxxl};
    }

    /*
    * 6. Animations
    */

    /*KEYFRAMES */
    @-webkit-keyframes blink {
        20%,
        80% {
            opacity: 0;
        }
        10%,
        90% {
            opacity: 0.1;
        }
        0%,
        100% {
            opacity: 1;
        }
    }
    @keyframes blink {
        20%,
        80% {
            opacity: 0;
        }
        10%,
        90% {
            opacity: 0.1;
        }
        5%,
        90% {
            opacity: 0.2;
        }
        0%,
        100% {
            opacity: 1;
        }
    }
    @-webkit-keyframes fade-in {
        100% {
            opacity: 0;
        }
        10% {
            opacity: 0.1;
        }
        0% {
            opacity: 1;
        }
    }
    @keyframes fade-in {
        100% {
            opacity: 0;
        }
        10% {
            opacity: 0.05;
        }
        0% {
            opacity: 1;
        }
    }

    .fadeIn {
        transition-timing-function: linear;
        transition-duration: 500ms, 900ms;
        transition-property: transform, opacity;
        transition-delay: 0ms;

        &.hide {
            opacity: 0;
            transform: translate(0, 20px);
        }
        &.fade-in {
            opacity: 1;
            transform: translate(0, 0);
        }
    }

    .animate-blink {
        animation: blink 3s linear infinite;
    }
    .animate-fade {
        animation: fade 5000ms linear alternate;
    }

    /** Bumblebee Full Logo Animation */
    svg.bblogo-singlestroke {
        @keyframes draw {
            0% {
                stroke-dashoffset: 1;
                stroke-opacity: 1;
            }

            100% {
                stroke-dashoffset: 0;
                stroke-opacity: 0;
            }
        }

        @keyframes fadeOpacity {
            from {
                fill-opacity: 0;
            }
            to {
                fill-opacity: 1;
            }
        }

        fill-opacity: 0;
        animation: fadeOpacity 2s forwards 2000ms;
        --delay: 500ms;
        & path {
            stroke-dasharray: 1;
            stroke-dashoffset: 1;
            animation: draw 3250ms forwards 500ms cubic-bezier(0.33, 0.68, 0.84, 0.59);
        }

        &.sm {
            animation: fadeOpacity 2s forwards 1750ms;

            & path {
                animation: draw 1750ms forwards 500ms cubic-bezier(0.33, 0.68, 0.84, 0.59);
            }
        }
    }

    svg.bblogo-multiletter {
        @keyframes draw {
            from {
                stroke-dashoffset: 1;
                stroke-opacity: 1;
            }
            to {
                stroke-opacity: 0;
                stroke-dashoffset: 0;
            }
        }

        @keyframes fadeOpacity {
            from {
                fill-opacity: 0;
            }
            to {
                fill-opacity: 1;
            }
        }

        fill-opacity: 0;
        animation: fadeOpacity 2s forwards calc(var(--delay) * 11);
        --delay: 500ms;

        & path {
            stroke-dasharray: 1;
            stroke-dashoffset: 1;
            animation: draw 850ms forwards 300ms;

            &:nth-child(2) {
                animation-delay: calc(var(--delay) * 2 - 150ms);
            }
            &:nth-child(3) {
                animation-delay: calc(var(--delay) * 3 - 150ms);
            }
            &:nth-child(4) {
                animation-delay: calc(var(--delay) * 4 - 150ms);
            }
            &:nth-child(5) {
                animation-delay: calc(var(--delay) * 5 - 150ms);
            }
            &:nth-child(6) {
                animation-delay: calc(var(--delay) * 6 - 150ms);
            }
            &:nth-child(7) {
                animation-delay: calc(var(--delay) * 7 - 150ms);
            }
            &:nth-child(8) {
                animation-delay: calc(var(--delay) * 8 - 150ms);
            }
            &:nth-child(9) {
                animation-delay: calc(var(--delay) * 9 - 150ms);
            }
            &:nth-child(10) {
                animation-delay: calc(var(--delay) * 10 - 150ms);
            }
            &:nth-child(11) {
                animation-delay: calc(var(--delay) * 11 - 150ms);
            }
        }
    }

    /** PRODUCTS */

  

    /* ICONS */
`;

export default GlobalStyle;
