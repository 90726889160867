import * as React from 'react';
import PropTypes from 'prop-types';

function SvgBbLogoSmall(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 60 39"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M27.246 30.498c2.583-1.147 5.07-2.642 7.447-4.208a72.529 72.529 0 006.82-5.09 88.39 88.39 0 012.286-4.032c.8-1.331 1.614-2.675 2.44-4.033a68.69 68.69 0 014.883-7.047c.723-.94 1.55-1.93 2.48-2.976.93-1.043 1.989-1.722 3.178-2.036.465-.104.93-.104 1.395 0 .465.106.826.392 1.085.862.258.47.335.953.232 1.448a5.799 5.799 0 01-.542 1.488c-.26.497-.556.967-.892 1.41-.336.444-.633.849-.89 1.213a253.184 253.184 0 01-6.86 7.713 78.898 78.898 0 01-7.4 7.008l-1.55 3.524c-.155.366-.427 1.032-.814 1.997a34.488 34.488 0 00-1.046 3.015c-.31 1.045-.492 2.024-.543 2.936-.052.914.18 1.476.698 1.684 1.136 0 2.17-.3 3.1-.9a11.54 11.54 0 002.48-2.154c.05-.104.155-.235.31-.392.154-.156.31-.364.465-.626-.415-.94-.531-1.983-.35-3.132.181-1.148.607-2.114 1.28-2.898.62-.834 1.329-1.186 2.13-1.057.801.131 1.202.797 1.202 1.997 0 .888-.207 1.789-.62 2.702a14.533 14.533 0 01-1.473 2.545c.103.105.206.195.31.274.103.078.233.144.388.195.568.262 1.174.38 1.82.353a5.93 5.93 0 001.822-.353c.62-.156 1.33-.482 2.131-.979.8-.495 1.098-.45 1.098-.45.516 0 .423.464.5.96.078.496-.087.832-.5 1.04-1.209.608-1.008.552-2.144.917a11.38 11.38 0 01-3.41.587c-1.19.027-2.222-.325-3.1-1.057a25.665 25.665 0 01-2.712 2.937c-1.034.966-2.145 1.656-3.333 2.075-.879.26-1.731.274-2.557.039-.827-.235-1.422-.822-1.782-1.762-.415-1.2-.401-2.467.038-3.798a46.971 46.971 0 011.356-3.641c.258-.626.53-1.279.814-1.958.284-.678.581-1.357.891-2.036a166.334 166.334 0 01-3.177 2.193 303.154 303.154 0 01-3.178 2.114l-4.804 2.663c-.26.105-.504.222-.737.352-.232.13-.478.092-.736-.117a.9.9 0 01-.426-.862c.027-.364.187-.49.497-.647zM52.13 10.786a75.862 75.862 0 002.441-3.093 12.14 12.14 0 001.821-3.484 8.11 8.11 0 00-1.24.978c-.414.392-.775.719-1.085.98a49.796 49.796 0 00-2.518 2.74 48.534 48.534 0 00-2.287 2.897 44.977 44.977 0 00-2.13 3.132 757.677 757.677 0 00-1.977 3.21 92.258 92.258 0 003.526-3.601 459.803 459.803 0 003.449-3.759z"
                fill="#141414"
                pathLength="1"
            />
            <path
                d="M.507 29.364a51.55 51.55 0 007.44-4.072 72.545 72.545 0 006.82-5.09 88.298 88.298 0 012.286-4.033c.8-1.33 1.614-2.674 2.44-4.032a68.671 68.671 0 014.883-7.048c.723-.94 1.55-1.93 2.48-2.975.93-1.044 1.988-1.723 3.178-2.036.465-.104.93-.104 1.395 0 .465.105.825.391 1.085.861.258.47.335.953.232 1.449a5.799 5.799 0 01-.543 1.488c-.259.496-.555.966-.89 1.41-.337.443-.634.848-.892 1.213a253.426 253.426 0 01-6.858 7.713 78.899 78.899 0 01-7.401 7.008l-1.55 3.524c-.156.366-.427 1.031-.814 1.997a34.502 34.502 0 00-1.046 3.014c-.31 1.045-.492 2.024-.543 2.937-.052.914.18 1.475.697 1.683 1.136 0 2.17-.3 3.1-.9.93-.6 1.756-1.318 2.48-2.154.051-.104.155-.235.31-.391.155-.157.31-.365.465-.627-.414-.94-.53-1.983-.348-3.132.18-1.147.606-2.114 1.278-2.897.62-.834 1.33-1.187 2.132-1.057.8.13 1.2.796 1.2 1.997 0 .888-.206 1.789-.62 2.701a14.53 14.53 0 01-1.472 2.545c.103.105.206.196.31.274.103.078.233.145.388.196.567.262 1.174.38 1.82.352a5.927 5.927 0 001.822-.352c.62-.157 2.674-.935 3.475-1.432.8-.495.5.5 1 0s.601-.042.678.453c.078.497-.192.575-.605.783C29.5 31.5 28.136 32.135 27 32.5c-1.085.366-2.366.48-3.554.505-1.19.027-2.222-.326-3.1-1.057a25.656 25.656 0 01-2.712 2.936c-1.035.967-2.145 1.657-3.333 2.075-.879.26-1.731.274-2.557.04-.827-.236-1.422-.823-1.783-1.763-.414-1.2-.4-2.466.04-3.797a46.971 46.971 0 011.355-3.641c.258-.627.53-1.279.814-1.958a69.31 69.31 0 01.891-2.036 166.303 166.303 0 01-3.177 2.193 302.319 302.319 0 01-3.178 2.114l-4.804 2.662a6.64 6.64 0 00-.737.352c-.232.131-.478.092-.736-.117a.9.9 0 01-.426-.861c.027-.365.194-.627.504-.783zM25.384 9.788a75.833 75.833 0 002.44-3.093 12.14 12.14 0 001.822-3.485 8.11 8.11 0 00-1.24.979c-.414.391-.775.718-1.085.979-.879.888-1.718 1.8-2.519 2.74a48.522 48.522 0 00-2.286 2.898 44.951 44.951 0 00-2.13 3.132 757.806 757.806 0 00-1.977 3.21 92.261 92.261 0 003.526-3.602 460.296 460.296 0 003.449-3.758z"
                fill="#141414"
                pathLength="1"
            />
        </svg>
    );
}

SvgBbLogoSmall.propTypes = {
    title: PropTypes.string,
};
export default SvgBbLogoSmall;
