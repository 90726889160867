import { bbTheme } from 'components/styles/theme';
import React from 'react';
import styled, { css } from 'styled-components';
import { convertRems } from 'utils/stringFunctions';
import { Column, ColumnProps } from './Column';

interface RowProps {
    children?: React.ReactNode;
    className?: string;
    id?: string;
    breakpoints?: number[];
    spacing?: number[];
    flexDirections?: string;
    maxColumnCount?: number;
    onHoverEvents?: { [key: string]: (e?: any) => void };
    reverse?: boolean;
}

interface RowWrapperProps {
    customStyles?: string;
    spacingX?: number;
    standardWidth?: boolean;
    breakpoint: number;
    flexDirections: string | null;
    breakpointTwo: number;
    spacingY: number;
    maxColumnCount: number;
}

const Wrapper = styled.div<RowWrapperProps>`
    transition: ${({ theme }) => theme.transitions.base};
    transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
    ${(props) =>
        props.customStyles &&
        css`
            ${props.customStyles}
        `};
    &:not(.no-margin) {
        margin: 0 -${(props) => props.spacingX}px 0 -${(props) => props.spacingX}px;
    }
    width: ${(props) => (props.standardWidth ? '100%' : `calc(100% + ${props.spacingX || 0 * 2}px)`)};
    display: flex;
    flex-direction: column;
    max-width: 100%;
    @media (min-width: ${(props) => props.breakpoint}px) {
        flex-direction: ${(props) => (props.flexDirections ? props.flexDirections[0] || 'row' : 'row')};
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        &:not(.no-margin) {
            margin: ${(props) => (props.standardWidth ? '0' : `0 -${props.spacingX}px 0 -${props.spacingX}px`)};
        }
    }
`;

export const Row: React.FC<RowProps> = ({
    children,
    className,
    id,
    breakpoints = [769],
    spacing = [convertRems(bbTheme.spacing.xs)],
    flexDirections,
    maxColumnCount = 12,
    onHoverEvents,
    reverse,
}) => {
    const childs = reverse ? React.Children.toArray(children).reverse() : React.Children.toArray(children);
    return (
        <Wrapper
            className={`row-wrapper ${className || ''}`}
            id={id}
            breakpoint={breakpoints[0]}
            breakpointTwo={breakpoints[1] || breakpoints[0]}
            spacingY={spacing[0]}
            spacingX={typeof spacing[1] === 'number' ? spacing[1] : spacing[0]}
            flexDirections={flexDirections || null}
            maxColumnCount={maxColumnCount}
            {...onHoverEvents}
        >
            {childs.map((item, idx) => {
                // https://stackoverflow.com/questions/52524093/react-children-toarray-cant-find-props-on-children-using-typescript
                // This is needed to prevent non components rendering
                if (React.isValidElement<ColumnProps>(item)) {
                    return (
                        <Column
                            // Without the idx here, fast refresh seems to append new elements
                            // It also renders total -1 too many elements
                            // TODO Spread Props
                            key={`column-${className}-${id}-${idx}`}
                            breakpoints={breakpoints}
                            widths={item.props.widths}
                            offsets={item.props.offsets}
                            maxColumnCount={maxColumnCount}
                            className={item.props.className}
                            id={item.props.id}
                            spacing={item.props.spacing || spacing}
                            idx={idx}
                            fadeIn={item.props.fadeIn}
                        >
                            {item.props.children}
                        </Column>
                    );
                }
                return null;
            })}
        </Wrapper>
    );
};

// ${(props) =>
//   props.flexDirections &&
//   props.flexDirections[1] &&
//   css`
// @media (min-width: ${props.breakpointTwo}px) {
//   flex-direction: ${
//     props.flexDirections ? props.flexDirections[1] || 'row' : 'row'
//   };
//   display: flex;
//   justify-content: flex-start;
//   flex-wrap: wrap;
//   margin: 0 -${props.spacingX}px 0 -${props.spacingX}px;
// `};
// ${(props) =>
//   props.flexDirections &&
//   props.flexDirections[2] &&
//   css`
// @media (min-width: ${props.breakpointThree}px) {
//   flex-direction: ${
//     props.flexDirections ? props.flexDirections[2] || 'row' : 'row'
//   };
//   display: flex;
//   justify-content: flex-start;
//   flex-wrap: wrap;
//   margin: 0 -${props.spacingX}px 0 -${props.spacingX}px;
// `};
