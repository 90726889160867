import FsLightbox from 'fslightbox-react';
import React, { useContext } from 'react';
import { MyAppContext } from '../../../state/context/global';
import rmUndef from '../../../utils/rmUndef';

const modalCompStyle = { width: '90%', margin: 'auto' };
interface FsLightboxProps {
    images?: string[];
    imageGroups?: { key: string; value: string[] }[]; // TODO OPTIM Allow for async loading images by page
}

const FsLightBoxModal: React.FC<FsLightboxProps> = ({ images, imageGroups }) => {
    const app = useContext(MyAppContext);
    if (!app) return <div />;

    const modalImages = rmUndef(app?.images);

    function closeModal() {
        if (app) {
            app.setModalSrc(['']);
        }
    }

    if (app && modalImages && modalImages.length > 0 && app?.images[0]?.length) {
        const hasImages = app.images[0].length > 0;
        return (
            <>
                <FsLightbox
                    key={app.images[0]}
                    toggler={app.isModalOpen}
                    onClose={closeModal}
                    slide={app.images.length > 1 ? app.currentImage + 1 : 0}
                    type="image"
                    sources={app.images}
                    // openOnMount
                    // customSources={
                    //     hasImages
                    //         ? undefined
                    //         : [
                    //               <div style={modalCompStyle} className="modalComponent" key="modalComponent">
                    //                   {app.modalComponent}
                    //               </div>,
                    //           ]
                    // }
                />
            </>
        );
    }

    if (app.modalComponent)
        return (
            <FsLightbox
                key={app.images[0]}
                toggler={app.isModalOpen}
                onClose={() => app.toggleModalComponent(undefined)}
                slide={app.images.length > 1 ? app.currentImage + 1 : 0}
                type="image"
                sources={app.images}
                // openOnMount
                // customSources={[
                //     <div style={modalCompStyle} className="modalComponent" key="modalComponent">
                //         {app.modalComponent}
                //     </div>,
                // ]}
            />
        );
    return null;
};

export default FsLightBoxModal;
