import styled from 'styled-components';

interface TitleProps {
    noMargin?: boolean;
}

const Title = styled.h1<TitleProps>`
    margin: ${({ theme, noMargin }) => noMargin && '0'};
`;
Title.defaultProps = { className: 'title' };
export default Title;
