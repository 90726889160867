import React, { useContext, useEffect, useRef, useState } from 'react';
import { CartContext } from '../../../state/context/global';
import CartFooter from '../CartProduct/Footer';
import CartProductHeader from '../CartProduct/Header';
import CartProductList from '../CartProduct/List';
import { CartStyles, CartDrawerStyles } from './Style';
import Divider from 'components/styles/general-elements/Divider';
import CartSummary from '../CartProduct/Summary';
import useFocus from 'hooks/useFocus';
import { useRouter } from 'next/router';

const Cart: React.FC = () => {
    const cart = useContext(CartContext);
    const router = useRouter();
    if (cart === undefined) return <div />;
    const [sidebarActive, setSidebarActive] = useState(false);
    // const divRef = useRef<HTMLDivElement | null>(null);
    const [divRef, setFocus] = useFocus();

    useEffect(() => {
        if (cart.isOpen) {
            cart.toggleCart();
        }
    }, [router.pathname]);

    useEffect(() => {
        if (cart.isOpen !== undefined) {
            setSidebarActive(cart.isOpen);
        }
    }, [cart.isOpen]);

    function handleClickOutside(e: MouseEvent): void {
        if (!cart) return;
        if (null !== divRef.current && divRef.current.contains(e.target as Node)) {
            // inside click
            return;
        }

        // outside click
        if (cart.toggleCart !== undefined && cart.isOpen) {
            setSidebarActive(false);
            setTimeout(() => {
                cart.toggleCart && cart.toggleCart('close');
            }, 100);
        }
    }

    useEffect(() => {
        if (sidebarActive) {
            setFocus();
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [sidebarActive]);

    useEffect(() => {
        if (cart.items && cart.items.length > 0 && cart?.setTotal) {
            const newTotal = cart.items
                .map((item) => item.customization.totalPrice)
                .reduce((acc, val) => (parseFloat(acc) + parseFloat(val)).toFixed(2));
            cart.setTotal(newTotal);
            return;
        }
    }, [cart.items]);

    return (
        <CartDrawerStyles
            sidebarActive={sidebarActive}
            ref={divRef}
            aria-hidden={!sidebarActive}
            aria-label="Shopping Cart"
        >
            <CartStyles>
                <CartProductHeader />
                <Divider size="s" className="header-divider" />
                {cart.hasItems && <CartProductList items={cart.items} />}

                {cart.hasItems && <Divider size="s" className="footer-divider" />}
                <CartFooter />
            </CartStyles>
        </CartDrawerStyles>
    );
};

export default Cart;
