import { PageType, RobotsContent, MetaTags } from '../src/interfaces/tag';
import { concatenateStrings } from 'utils/stringFunctions';
import SC from '../public/global-data.json';
import Socials from 'public/social-data.json';
import P from 'public/pages-data.json';
import { urlFor } from 'utils/sanity/sanity';
import { Social } from 'src/types/sanity/schema';

const SiteConfig = SC[0];

export type SiteNavigation = typeof P;
export const Pages: SiteNavigation = P;
export type SiteConfigJSON = typeof SiteConfig;
export type SiteConfigNav = typeof SiteConfig.mainNavigation;
export const siteTopLineNav: SiteConfigNav = SiteConfig.mainNavigation;
export type SiteConfigFooter = typeof SiteConfig.footerNavigation;
export const siteFooterNav: SiteConfigFooter = SiteConfig.footerNavigation;

export const BRAND_NAME = 'BRAND NAME';

export const defaultMetaTags: MetaTags = {
    canonical: SiteConfig.url,
    description: SiteConfig.description,
    image: urlFor(SiteConfig.logo).url() || '',
    robots: concatenateStrings(RobotsContent.index, RobotsContent.follow),
    title: SiteConfig.title,
    type: PageType.website,
};
export { Socials };

type SocialLinks = {
    [key in Social['channel']]: Social['handle'];
};
export const socialLinks = Socials.reduce((all, currentSocial) => {
    all[currentSocial.channel as Social['channel']] = currentSocial.handle as Social['handle'];
    return all;
}, {}) as SocialLinks;

export function formatSocialLinks(channel: Social['channel']) {
    switch (channel) {
        case 'youtube':
            return `https://www.youtube.com/channel/${socialLinks[channel]}`;
        case 'instagram':
            return `https://www.instagram.com/${socialLinks[channel]}`;
        case 'facebook':
            return `https://www.facebook.com/${socialLinks[channel]}`;
        case 'twitter':
            return `https://twitter.com/${socialLinks[channel]}`;
        case 'linkedin':
            return `https://www.linkedin.com/in/${socialLinks[channel]}`;

        default:
            return `https://www.${channel}.com`;
    }
}

export const dev = {
    email: 'dev@bumblebeeflowerfarm.ie',
    website: 'https://jakeriordan.dev',
};
export const {
    contactAddress,
    contactEmail,
    contactPhone,
    title: siteTitle,
    url: siteUrl,
    description: siteDescription,
    logo: siteLogo,
} = SiteConfig;
export default SiteConfig;
