import styled from 'styled-components';
import { device, removeScrollbars } from '../../styles/mixins';
import { PreCheckoutStyles } from '../PreCheckout/PreCheckoutStyle';

interface DrawerProps {
    sidebarActive: boolean;
}

export const CartDrawerStyles = styled.aside<DrawerProps>`
    position: fixed;
    background-color: ${(props) => props.theme.colors.white};
    width: 400px;
    top: 0;
    right: -400px;
    z-index: 110;
    box-shadow: ${(props) => (props.sidebarActive ? props.theme.bShadow.dbl : 'none')};
    height: 100%;

    transition: all 300ms ease-in-out;
    transform: ${(props) => (props.sidebarActive ? 'translateX(-400px)' : 'translateX(0)')};

    @media ${device(true).small} {
        max-width: 100vw;
    }
`;

export const CartStyles = styled.div`
    padding: ${({ theme }) => theme.spacing.s} ${({ theme }) => theme.spacing.s};
    display: flex;
    flex-flow: column;
    height: 100%;
    ${PreCheckoutStyles} & {
        height: auto;
    }
    .cart-header {
        position: absolute;
        top: 0;
        box-shadow: ${({ theme }) => theme.bShadow.lg};
    }
    .scrollable {
        flex: 1;

        overflow-y: scroll;
        position: relative;
        ${removeScrollbars};
    }
    .scrollable > li:first-child {
        padding-top: ${({ theme }) => theme.spacing.s};
    }
    .scrollable > li:last-child {
        padding-bottom: ${({ theme }) => theme.spacing.s};
    }

    .cart-footer {
        position: absolute;
        bottom: 0;
        padding: 0rem 1rem 1rem 1rem;
        width: 100%;
    }

    .header-divider {
        margin-bottom: 0;
    }
    .footer-divider {
        margin-top: 0;
    }
`;
