import ThreeDotsWave from 'components/animations/loading/ThreeDotsLoader';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import Button from '../Layout/Buttons';
import BaseFormStyles from './BaseFormStyles';
import { FormField, MyFieldSet } from './FormElements';
import { postNewsLetterData } from './postNewsLetterData';

interface NewsLetterFormProps {
    hide?: {
        fname: boolean;
        lname: boolean;
        email: boolean;
        button?: boolean;
    };
}

const submitMessages = {
    success: 'Thank you for joining us!',
    error: 'It looks like something went wrong, please try again.',
};

const NewsLetterForm: React.FC<NewsLetterFormProps> = ({ hide }) => {
    const [successMessage, setSubmitMessage] = useState('');

    return (
        <BaseFormStyles className="newsletter-form">
            {<p className="form-success-message">{successMessage}</p>}
            <Formik
                initialValues={{ firstName: '', lastName: '', email: '', floristTypes: [] }}
                onSubmit={async (data, { setSubmitting, resetForm }) => {
                    setSubmitting(true);
                    // Make Call

                    try {
                        const res = await postNewsLetterData(data);

                        setTimeout(() => {
                            setSubmitMessage(submitMessages[res.success ? 'success' : 'error']);

                            setSubmitting(false);
                            resetForm();
                        }, 700);
                    } catch (error) {
                        console.error(error);
                    }
                }}
            >
                {({ values, isSubmitting }) => (
                    <Form>
                        <MyFieldSet>
                            {hide && hide.fname ? null : (
                                <FormField name="firstName" type="text" placeholder="First Name" />
                            )}
                            {hide && hide.lname ? null : (
                                <FormField name="lastName" type="text" placeholder="Last Name" />
                            )}
                            {hide && hide.email ? null : (
                                <FormField name="email" type="text" inputMode="email" placeholder="Email" />
                            )}
                        </MyFieldSet>
                        {hide && hide.button ? null : isSubmitting && successMessage === '' ? (
                            <ThreeDotsWave fluid />
                        ) : (
                            <Button
                                type="submit"
                                text={
                                    successMessage === submitMessages.success
                                        ? 'See you in the newsletter!'
                                        : 'Join Us!'
                                }
                                disabled={successMessage === submitMessages.success ? true : isSubmitting}
                                btnStyle={successMessage === submitMessages.success ? 'clear' : 'secondary'}
                            />
                        )}
                    </Form>
                )}
            </Formik>
        </BaseFormStyles>
    );
};

export default NewsLetterForm;
