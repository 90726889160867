import styled from 'styled-components';
import { banner, device, hoverDarkBg } from '../../styles/mixins';

interface AlertBarStylesProps {
    hasData: boolean;
}
const AlertBarStyles = styled.div<AlertBarStylesProps>`
    background-color: ${({ theme }) => theme.colors.backgrounds_muted_honey};
    width: 100%;
    position: fixed;
    top: 7.5rem;
    z-index: 99;
    @media ${device().medium} {
        top: calc(5.5rem + 1vw);
    }
    @media ${device().large} {
        top: calc(6rem + 1vw);
    }
    h6,
    p {
        margin: 0 5%;
    }
    .content {
        max-width: ${({ theme }) => theme.maxWidth};
        display: flex;
        position: relative;
        top: -5.5rem;
        justify-content: center;
        align-items: center;
        padding: ${({ theme }) => theme.spacing.xs} ${({ theme }) => theme.spacing.gutter};
        margin: 0 auto;
        opacity: ${({ hasData }) => (hasData ? 1 : 0)};
        transform: ${({ hasData }) => (hasData ? 'translateY(5.5rem)' : 'translateY(0)')};
        transition: all 300ms ease-in-out 3000ms;
        cursor: pointer;
        @media only screen and (max-width: 500px) {
            text-align: center;
        }
    }

    /* .close {
        position: absolute;
        bottom: 17.5%;
        left: 0;
    } */

    .mob {
        @media only screen and (min-width: 500px) {
            display: none;
            max-width: 60%;
        }
    }

    .lg {
        @media only screen and (max-width: 500px) {
            display: none;
        }
    }

    i {
        margin: 0 1rem;
    }

    i.chevron-right-icon {
        @media only screen and (max-width: 400px) {
            width: 3rem;
        }
    }
`;

AlertBarStyles.displayName = 'AlertBarStyles';

export default AlertBarStyles;
