/* eslint-disable react/display-name */
// lib/sanity.js
import {
    groq,
    createClient,
    createImageUrlBuilder,
    createPortableTextComponent,
    createPreviewSubscriptionHook,
    createCurrentUserHook,
} from 'next-sanity';
import InstagramEmbed from 'react-instagram-embed';
import getYouTubeId from 'get-youtube-id';
import YouTube from 'react-youtube';
import React from 'react';
import { nextSanityImage } from './sanityImage';
import MyImage from 'components/Layout/MyImage';
import { MyLink } from 'components/Layout/Link';
import { sentenceCase, unhyphenate } from 'utils/stringUtils';
import IGPost from 'components/Layout/social/IGPost';
import { devLog } from 'utils/logs';

export const currentDataset =
    process.env.NODE_ENV === 'production' ? process.env.NEXT_PUBLIC_SANITY_DATASET : 'staging';

export function buildSanityLink(linkType, slug) {
    return linkType == 'article' ? 'journal/' + slug : linkType === 'product' ? 'shop/products/' + slug : slug;
}
const config = {
    /**
     * Find your project ID and dataset in `sanity.json` in your studio project.
     * These are considered “public”, but you can use environment variables
     * if you want differ between local dev and production.
     *
     * https://nextjs.org/docs/basic-features/environment-variables
     **/
    dataset: currentDataset,
    projectId: process.env.NEXT_PUBLIC_SANITY_PROJECT_ID,
    useCdn: process.env.NODE_ENV === 'production',
    apiVersion: '2021-10-21',
    /**
     * Set useCdn to `false` if your application require the freshest possible
     * data always (potentially slightly slower and a bit more expensive).
     * Authenticated request (like preview) will always bypass the CDN
     **/
};
devLog('currentDataset:', currentDataset);
/**
 * Set up a helper function for generating Image URLs with only the asset reference data in your documents.
 * Read more: https://www.sanity.io/docs/image-url
 **/
export const urlFor = (source) => createImageUrlBuilder(config).image(source);

// Set up the live preview subscription hook
export const usePreviewSubscription = createPreviewSubscriptionHook(config);

export function handleSanitySlug(slug) {
    if (typeof slug === 'string') return slug;
    return slug?.current || '';
}

const serializers = {
    marks: {
        internalLink: ({ mark, children, markDefs }) => {
            const { internalLinkType = '' } = mark;
            const { slug = {}, handle = '' } = mark;
            const href = buildSanityLink(internalLinkType, slug?.current || handle);
            MyLink.displayName = 'MyLink';

            return (
                <>
                    {href ? (
                        <MyLink
                            hrefProp={href}
                            btnStyle="clear"
                            title={
                                typeof slug?.current === 'string'
                                    ? sentenceCase(unhyphenate(slug.current))
                                    : handle
                                    ? sentenceCase(unhyphenate(handle))
                                    : ''
                            }
                            // title={internalLinkType}
                        >
                            {children}
                        </MyLink>
                    ) : (
                        children
                    )}
                </>
            );
        },
        link: ({ mark, children }) => {
            const { blank, href } = mark;
            MyLink.displayName = 'MyLink';

            return (
                <MyLink
                    external
                    // TODO
                    // target={mark.blank ? '_blank' : '_self'}
                    target={mark.blank ? '_blank' : '_self'}
                    rel={mark.blank ? 'noopener' : undefined}
                    hrefProp={href}
                    btnStyle="clear"
                >
                    {children}
                </MyLink>
            );
        },
    },

    types: {
        image: ({ node }) => {
            MyImage.displayName = 'MyImage';

            const creditInstagram = node['creditInstagram'];
            const creditName = node['creditName'];
            const creditUrl = node['creditUrl'];

            return (
                <div className={`PortableText-inline-image ${node.centerImage ? 'center' : ''}`}>
                    <MyImage
                        loader="sanity"
                        photoCredits={!!creditName ? { creditInstagram, creditName, creditUrl } : undefined}
                        nextImageProps={{
                            ...nextSanityImage(node),
                            alt: node.altText || 'alternate image text',
                        }}
                    />
                </div>
            );
        },

        youtube: ({ node }) => {
            const { url } = node;
            const id = getYouTubeId(url);
            return <YouTube videoId={id} containerClassName="Youtube-container" />;
        },
        // https://graph.facebook.com/v10.0/instagram_oembed?url=https://www.instagram.com/p/CMpX4uLncds/&access_token=293556799000014|ed86230b2c6014935f9b3ce42c7353ef
        instagramPost: ({ node }) => {
            // props.name === 'instagramPost';

            return <IGPost url={node.url} />;
        },
    },
};

// Set up Portable Text serialization
export const PortableText = createPortableTextComponent({
    ...config,
    // Serializers passed to @sanity/block-content-to-react
    // (https://github.com/sanity-io/block-content-to-react)
    serializers,
});

// Set up the client for fetching data in the getProps page functions
export const sanityClient = createClient(config);
// Set up a preview client with serverless authentication for drafts
export const previewClient = createClient({
    ...config,
    useCdn: false,
    token: process.env.SANITY_API_TOKEN,
});

// Helper function for easily switching between normal client and preview client
export const getClient = (usePreview) => (usePreview ? previewClient : sanityClient);

// Helper function for using the current logged in user account
export const useCurrentUser = createCurrentUserHook(config);
