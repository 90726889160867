import { PortableTextBlock } from '@portabletext/types';
import blocksToText from 'new-studio/utils/blocksToText';
import { SelectedOptionInput } from 'src/types/sf-globalTypes';

export function constructVariantByOptions(selectedOptions: SelectedOptionInput[]): string {
    const matches = selectedOptions
        .map((opt, idx, arr) => {
            const matchStr = `$selectedOptions[${idx}].value in string::split(@->store.title, " / ")`;
            return matchStr;
        })
        .reverse();

    const specificty = matches
        .map((match, idx, arr) => {
            const str = `store.variants[ ${arr
                .slice(idx)
                .map((slice, i) => `${slice}${i < arr.length - 1 ? ' && ' : ''}`)
                .join('')} ]`;
            return idx < arr.length - 1 ? `${str}=>${str}` : str.replace('&&', '');
        })
        .join(',\n');

    const selectString = `select(${specificty})`;

    return selectString;
}

type T = keyof JSX.IntrinsicElements;

function createBlock(text: string, style: T = 'p'): PortableTextBlock[] {
    return [
        {
            _type: 'block',
            style,
            _key: Math.random().toString(36).substr(2, 9),
            markDefs: [],
            children: [
                {
                    _key: Math.random().toString(36).substr(2, 9),
                    _type: 'span',
                    marks: [],
                    text,
                },
            ],
        },
    ];
}

export function handleContent(content: string | PortableTextBlock[], output = 'string'): string | PortableTextBlock[] {
    if (output === 'string') {
        return typeof content === 'string' ? content : blocksToText(content);
    }

    if (output === 'blocks') {
        return typeof content === 'string' ? createBlock(content) : content;
    }

    return content;
}
