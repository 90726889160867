export const F_PHOTO_CREDITS = `
  'creditName' : photoCredits->name,
  'creditUrl' : photoCredits->website,
  'creditInstagram' : photoCredits->instagram,
`;

export const F_IMAGE_WITH_CREDITS = `
  image {
    ...,
      ${F_PHOTO_CREDITS}
  },
`;

export const F_LATEST_POST_BY_CAT = `
_type == 'latestPosts' => {
  ...,
  categories[]->{
    title,
    _id,
    'articles' : *[_type== 'article' && references(^._id) ][0..2]{
      ...,
      categories[]-> {
        ...
      }
    }
  }
},
`;

export const F_SECTION_LINKS = `
_type == 'sectionLinks' => {
  ...,
  links[]{
    ...,
    'linkType': link->_type,
    'slug': link->slug.current
  }
},
`;

export const F_BODY_WITH_ADD_FIELDS = `
body[]{
  ...,
 _type == 'image' => {
    ...,
     'creditName' : photoCredits->name,
    'creditUrl' : photoCredits->website,
    'creditInstagram' : photoCredits->instagram
},
  markDefs[]{
      ...,
      _type == "internalLink" => {
          "slug": @.reference->slug,
          "handle": @.reference->handle,
          'internalLinkType' : @.reference->_type,
          }
      }
  },
`;

export const F_POST_FIELDS = `
  _id,
  name,
  title,
  'date': publishedAt,
  mainImage,
  excerpt,
  'slug': slug.current,
  'coverImage': mainImage,
  'author': author->{name, 'picture': image.asset->url},
  categories[]->{
    _id,
    title
  },
`;

export const F_PAGE_FIELDS = `
_type,
title,
'slug' : slug.current,
description,
pageBuilder[]{
  ...,
  ${F_LATEST_POST_BY_CAT}
  ${F_SECTION_LINKS}
  ${F_BODY_WITH_ADD_FIELDS}
  _type =='hero' => {
    'secondaryButtonLink' : secondaryButtonLink->slug.current,
    'primaryButtonLink' : primaryButtonLink->slug.current,

    ${F_IMAGE_WITH_CREDITS}
  },
  _type== 'callToAction' => {
    'slug' : internalLink.reference->slug.current,
    'handle' : internalLink.reference->handle,
    'internalLinkType' : internalLink.reference->_type,
    'fileUrl' : file.asset->url,
  },
  _type == 'textWithImage' => {
    ...,	
    ${F_IMAGE_WITH_CREDITS}
    ${F_BODY_WITH_ADD_FIELDS}
  },
  _type == 'banner' => {
    ...,	
    ${F_IMAGE_WITH_CREDITS}
  },
  _type == 'splitText' => {
    ...,	
    ${F_BODY_WITH_ADD_FIELDS.replace(/body\[\]/, 'left[]')}
    ${F_BODY_WITH_ADD_FIELDS.replace(/body\[\]/, 'right[]')}
  },
  _type == 'gallery' => {
    ...,
     ${F_PHOTO_CREDITS}
    images[] {
      ...,
       ${F_PHOTO_CREDITS}
    }
  },

  _type =='productSection' => {
    ...,
    !defined(selectionType) => {
      'products': *[_type == "product" && store.status == "active"][]{
        ...,
        "store": store {..., "variants": variants[]->{...}}
      }
    },
    selectionType == "all-products" => {
      'products': *[_type == "product" && store.status == "active"][]{
        ...,
        "store": store {..., "variants": variants[]->{...}}
      }
    },
     selectionType == "individual-products" => {
      products[]->{
        ...,
         "store": store {..., "variants": variants[]->{...}}
      }
    },
    selectionType == "product-type" => {
      'products': *[_type == "product" && store.status == "active" && ^.productType->slug == @.store.productType][]{
        ...,
         "store": store {..., "variants": variants[]->{...}}
      }
    },

  },

  _type =='collectionSection' => {
    collections[]->{
      ...,
    }
  },
  
},
`;
// Image Gallery
// Rich Text
export const F_NEXT_PREV_ARTICLE = `
'nextArticle': *[_type == 'article' && publishedAt > ^.publishedAt] | order(publishedAt asc)[0]{
  ${F_POST_FIELDS}
},
'previousArticle': *[_type == 'article' && publishedAt < ^.publishedAt][0]{
  ${F_POST_FIELDS}
},
`;

export const F_PRODUCT_FIELDS = `
...,
"store": store {..., "variants": variants[]->{...}},
`;
