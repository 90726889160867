export function ensure<T>(argument: T | undefined | null, message = 'This value was promised to be there.'): T {
    if (argument === undefined || argument === null) {
        throw new TypeError(message);
    }

    return argument;
}
export function matchLabelToOption(label: string, options: { label: string; value: string | number }[]) {
    return options.find((op) => op.value === label);
}
