import styled from 'styled-components';
import { BBTheme } from '../interface';
import { bbTheme } from '../theme';

interface DividerProps {
    size: keyof BBTheme['spacing'] | { top: keyof BBTheme['spacing']; bottom: keyof BBTheme['spacing'] };
    color?: keyof BBTheme['colors'];
}

const Divider = styled.hr<DividerProps>`
    display: block;
    height: 0.5px;
    padding: 0 !important;
    border: 0.5px solid ${({ color, theme }) => (color ? bbTheme[color] : theme.colors.light_grey_soft_lines)};
    margin: ${({ size, theme }) =>
        typeof size === 'string'
            ? theme.spacing[size] + ' 0'
            : theme.spacing[size.top] + ' 0 ' + theme.spacing[size.bottom] + ' 0'};
`;
Divider.defaultProps = { className: 'divider' };
export default Divider;
