import styled from 'styled-components';
import { device } from '../../styles/mixins';

export const PreCheckoutStyles = styled.section`
    position: relative;
    z-index: 1;
    display: grid;
    grid-template-columns: repeat(12, minmax(3rem, 1fr));
    grid-template-rows: repeat(12, minmax(2rem, max-content));
    gap: 1rem 3rem;
    justify-items: start;
    align-items: start;

    &.checkout-started {
        margin-top: 15rem;
    }

    @media only screen and (max-width: 900px) {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
    }

    /* Grid Layout */
    .page-title {
        grid-column: 1 / span 8;
        grid-row: 1 / span 1;
    }

    .navigation-top {
        grid-column: 1 / span 7;
        grid-row: 2 / span 1;
    }
    .user-details {
        grid-column: 1 / span 7;
        grid-row: 3 / 11;
    }

    #cart-items {
        grid-column: 8 / 13;
        grid-row: 2 / 11;

        &.single-item {
            grid-row: 2 / 6;
        }
        &.two-items {
            grid-row: 2 / 9;
        }
        &.multiple-items {
            grid-row: 2 / 11;

            @media ${device().medium_large} {
                overflow-y: scroll;
                grid-row: 2 / 9;
                max-height: 57rem;
            }
        }
    }
    #cart-summary {
        grid-column: 8 / 13;
        grid-row: 11 / 13;

        &.single-item {
            grid-row: 6 / 11;
        }
        &.two-items {
            grid-row: 9 / 11;
        }
        &.multiple-items {
            grid-row: 11 / 13;
        }
    }

    /* Element styles */

    .navigation-btm,
    .navigation-top {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    .navigation-top {
        position: fixed;
        flex-direction: column;
        justify-content: center;
        align-items: baseline;
        top: 7.5rem;
        left: 0;
        z-index: 100;
        background-color: ${({ theme }) => theme.colors.white};
        box-shadow: ${({ theme }) => theme.bShadow.btm};
        padding: 0 ${({ theme }) => theme.spacing.gutter};
        @media ${device().small} {
            top: 6.5rem;
        }
        @media ${device().medium} {
            top: calc(5.5rem + 1vw);
        }
        @media ${device().xl} {
            top: calc(6.5rem + 1.2vw);
        }
    }

    .user-details {
        border: ${({ theme }) => theme.borders.base};
        padding: ${({ theme }) => theme.spacing.xs};
        padding-top: 0;
        overflow-x: hidden;
        margin: 0 auto;
        width: 100%;

        #email-form {
            max-width: 500px;
            margin: 0 auto;
        }
    }

    #cart-items {
        width: 100%;
        margin: 0 auto;
        @media ${device().medium} {
            padding-top: 5px;
        }
    }

    .cart-nav {
        position: relative;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        width: 100%;
        .popup {
            left: 50%;
        }
        button {
            --btn-padding: 1rem;
            & [title^='Next'] {
                --icon-rotate: -90deg;
            }
            & [title^='Previous'] {
                --icon-rotate: 90deg;
            }
        }
    }

    #cart-summary {
        width: 100%;
        margin: 0 auto;
    }
    .no-shipping {
        position: relative;
        top: 0;

        & > button,
        & > a {
            margin-top: 2rem;
        }
    }

    .gtc {
        width: 100%;
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        align-items: center;
    }
`;
