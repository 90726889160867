import { clampN } from './numberFunctions';

// 'hsla(42, 100%, 50.2%, 1)'
export function modifyAlpha(str: string, mod: number) {
    const values = str
        .slice()
        .replace(/hsla|\(|\)|%/, '')
        .split(',')
        .map((v: string) => parseFloat(v));

    const newAlpha = clampN(values[values.length - 1] * mod, 0, 1);

    const res = str.slice().replace(/\d*\)$/, newAlpha + ')');

    return res;
}
