import dayjs from 'dayjs';
import 'dayjs/locale/en-ie'; // import locale
import customParseFormat from 'dayjs/plugin/customParseFormat';
import React, { useContext, useEffect, useState } from 'react';
import { getCurrentAlert } from 'utils/sanity/api';
import { MyAppContext } from '../../../state/context/global';
import { bbTheme } from '../../styles/theme';
import ContentTemplate from '../../templates/ContentTemplate';
import Button from '../Buttons';
import AlertBarStyles from './Style';

dayjs.extend(customParseFormat);

const AlertBar: React.FC = ({}) => {
    const app = useContext(MyAppContext);

    const [isVisible, setIsVisible] = useState(false);
    const [contentData, setContentData] = useState({
        title: '',
        issue: '',
        solution: '',
        resolution: '',
        link: '',
    });

    function isBarVisible(start: string | null, end: string | null) {
        if (!start || !end) return false;

        const now = dayjs();
        const startDate = dayjs(start);
        const hasStarted = now.isAfter(startDate);

        const endDate = dayjs(end);
        const isRunning = now.isBefore(endDate);

        return hasStarted && isRunning;
    }

    useEffect(() => {
        const fetchAlert = async () => {
            const currentAlert = await getCurrentAlert();

            if (currentAlert) {
                const { alertTitle: title, issue, solution, resolution } = currentAlert;
                setContentData({
                    title,
                    issue: issue || '',
                    solution: solution || '',
                    resolution: resolution || '',
                    link: '',
                });
                setIsVisible(isBarVisible(currentAlert.startDate, currentAlert.endDate));
            }
            if (/\/cart/.test(location.pathname)) {
                setIsVisible(false);
            }
        };

        fetchAlert();
    }, []);

    function handleModal() {
        if (!app || !contentData.issue) return;
        app.toggleModalComponent(
            <ContentTemplate
                className="light"
                color={bbTheme.colors.white}
                contentObj={{
                    header: [{ text: '' }],
                    lead: [{ text: contentData.issue || '' }],
                    content: [{ text: contentData.solution || '' }, { text: contentData.resolution || '' }],
                    menu: [{ text: '' }],
                }}
            />,
        );
    }

    return (
        <>
            {isVisible && !app.isBannerDismissed && (
                <AlertBarStyles hasData={!!contentData.title} className="">
                    <div className="content">
                        <Button
                            className="close"
                            btnStyle="clear"
                            icon={{ name: 'close' }}
                            func={() => {
                                setIsVisible(false);
                                app.setBannerDismissed();
                            }}
                        />

                        <div className="mob">
                            <h6>{contentData.title}</h6>
                            {contentData.issue && <p> Find out more here </p>}
                        </div>

                        <h6 className="lg">{contentData.title}</h6>

                        {contentData.issue && (
                            <p className="lg">
                                <Button text="Find out more here" func={handleModal} />
                            </p>
                        )}
                    </div>
                </AlertBarStyles>
            )}
        </>
    );
};

export default AlertBar;
