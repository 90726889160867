import React, { ReactNode } from 'react';
import { CartItemProductNode, GiftCardStatus } from '../../components/shop/interfaces';
import { dfGiftCardStatus } from '../../core/defaults';
import { _ContextMenuType, User, UserNote } from '../../src/types/globalTypes';
import { CookieStatus } from '../../utils/analytics';

export type CartContextType = {
    isOpen: boolean;
    hasItems: boolean;
    items: CartItemProductNode[];
    total: string | undefined;
    modalComponent: CartItemProductNode | null;
    // checkoutId: string;
    // webUrl: string;
    discountCode: string;
    giftCards: GiftCardStatus[];
    setTotal: (total: string | undefined) => void;
    addMultipleItems: (items: CartItemProductNode[]) => void;
    toggleCartModal: (component: CartItemProductNode | undefined) => void;
    toggleCart: (action?: string) => void;
    addItem: (item: CartItemProductNode) => void;
    removeItem: (item: CartItemProductNode, items: CartItemProductNode[]) => void;
    updateCartQuantity: (item: CartItemProductNode, list: CartItemProductNode[], action: string) => void;
    setCheckoutCode: (discountCode?: string, giftCards?: GiftCardStatus[]) => void;
    updateCartVariant: (
        e: any,
        item: CartItemProductNode,
        list: CartItemProductNode[],
    ) => // customization: ProductCustomization,
    void;
};

export const CartContext = React.createContext<CartContextType>({
    isOpen: false,
    hasItems: false,
    items: [],
    total: '0',
    modalComponent: null,
    // checkoutId: '',
    // webUrl: '',
    discountCode: '',
    giftCards: [dfGiftCardStatus],
    setTotal: (total: string | undefined) => null,
    toggleCartModal: (component: CartItemProductNode | undefined) => null,
    toggleCart: (action?: string) => null,
    addItem: (item: CartItemProductNode) => null,
    addMultipleItems: (items: CartItemProductNode[]) => null,
    removeItem: (item: CartItemProductNode, items: CartItemProductNode[]) => null,
    updateCartQuantity: (item: CartItemProductNode, list: CartItemProductNode[], action: string) => null,
    setCheckoutCode: (discountCode?: string, giftCards?: GiftCardStatus[]) => null,
    updateCartVariant: (
        e: any,
        item: CartItemProductNode,
        list: CartItemProductNode[], // customization: ProductCustomization,
    ) => null,
});

export type UserContextType = {
    details: User;
    isLoggedIn: boolean;
    note: { from: string; to: string; note: string };
    userDiscountCode: string;
    userGiftCards: GiftCardStatus[];
    cookieStatus: CookieStatus;

    setCookieStatus: (action: CookieStatus) => void;
    setUser: (user: User) => void;
    setNote: (note: UserNote) => void;
};
// TODO change user type & shipping type to be better in line with expected results

export const UserContext = React.createContext<UserContextType>({
    details: { firstName: '', lastName: '' },
    isLoggedIn: false,
    note: { from: '', to: '', note: '' },
    userDiscountCode: '',
    userGiftCards: [dfGiftCardStatus],
    cookieStatus: '',
    setCookieStatus: () => null,
    setUser: (user: User) => null,
    setNote: (note: UserNote) => null,
});

export type MyAppContext = {
    setModalSrc: (images: string[] | undefined) => void;
    toggleModalComponent: (component: ReactNode | undefined) => void;
    toggleAppModal: (images: string[] | undefined, currentImage?: number | undefined) => void;
    toggleNavDrawer: (action?: 'close' | 'open' | undefined) => void;
    setCheckoutId: (id: string | null) => void;
    increment: () => void;
    setBannerDismissed: () => void;
    setPopupProduct: (productHandle: string) => void;
    setPopupProductDismissed: () => void;
    setContextMenu: (menuType: _ContextMenuType) => void;
    popupProduct: string | undefined;
    contextMenu: _ContextMenuType;
    isPopupProductDismissed: boolean;
    isBannerDismissed: boolean;
    checkoutId: string | null;
    modalCount: number;
    isModalOpen: boolean;
    isNavOpen: boolean;
    currentImage: number;
    images: string[];
    modalComponent: null | ReactNode;
    loaded: boolean;
};

export const MyAppContext = React.createContext<MyAppContext>({
    isModalOpen: false,
    contextMenu: 'main',
    images: [''],
    currentImage: 0,
    isNavOpen: false,
    modalComponent: null,
    modalCount: 0,
    loaded: false,
    checkoutId: null,
    isBannerDismissed: false,
    popupProduct: undefined,
    isPopupProductDismissed: false,
    setContextMenu: (menuType: _ContextMenuType) => null,
    setPopupProduct: (productHandle: string) => null,
    setPopupProductDismissed: () => null,
    setBannerDismissed: () => null,
    setModalSrc: (images: string[] | undefined) => null,
    toggleModalComponent: (component: ReactNode | undefined) => null,
    toggleAppModal: (images: string[] | undefined, currentImage?: number | undefined) => null,
    toggleNavDrawer: (action?: 'close' | 'open' | undefined) => null,
    increment: () => null,
    setCheckoutId: (id: string | null) => null,
});
