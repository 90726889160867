import CircleNumber from 'components/styles/general-elements/CircleNumber';
import Title from 'components/styles/Title';
import React, { ReactText, useContext } from 'react';
import { CartContext } from '../../../state/context/global';
import Button from '../../Layout/Buttons';
import { FixedPlaceholderStyles } from '../../Layout/Header';
import { CartHeaderStyles } from './CartProductStyles';
import { CartLocationProps } from './Single';

const CartProductHeader: React.FC<CartLocationProps> = ({ isCheckoutPage, isExpanded, isModalCart }) => {
    const cart = useContext(CartContext);
    return (
        <>
            <CartHeaderStyles className="">
                <CircleNumber number={cart.items.length} bgColor={cart.items.length == 0 ? 'transparent' : undefined} />
                <Title as="h5">{cart.hasItems ? 'Items in my cart' : 'Your cart is empty'}</Title>
                {isCheckoutPage ? null : (
                    <Button
                        className="close"
                        btnStyle="clear"
                        icon={{ name: 'close' }}
                        text=""
                        func={() => cart.toggleCart()}
                        tabIndex={cart.isOpen ? 0 : -1}
                    />
                )}
            </CartHeaderStyles>
        </>
    );
};

export default CartProductHeader;
