import styled from 'styled-components';
import mixins, { device, link, removeScrollbars } from '../styles/mixins';

const ToplineNavStyles = styled.nav`
    & ul {
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-around;

        a,
        button {
            font-size: 100%;
            ${link}
            &.selected {
                border-bottom-color: currentColor;
            }
            @media only screen and (max-width: ${({ theme }) => theme.breakpoints.small}px) {
                display: none;
            }
        }
    }
`;

const FloatingNavStyles = styled.nav`
    position: fixed;
    z-index: 99;
    top: 5rem;
    left: 0;
    min-height: 5rem;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.white};
    border: 3px solid transparent;
    border-bottom: 1px solid ${({ theme }) => theme.colors.light_grey_soft_lines};
    transition: ${({ theme }) => theme.transitions.base};

    &.hide {
        transition: ${({ theme }) => theme.transitions.base};
        opacity: 0;
        transform: translate3d(0, -15rem, 0);
    }
    &.show {
        opacity: 1;
        transition: ${({ theme }) => theme.transitions.base};
        transform: translate3d(0, 0, 0);
    }

    .links {
        grid-column: 3 / -1;
        ${mixins.flex}
        justify-content: space-evenly;
        & a {
            ${mixins.link};
            &.active {
                border-color: black;
            }
            &.selected {
                border-bottom-color: currentColor;
            }
        }
    }

    [aria-label='breadcrumbs'] {
        grid-column: 1 / 3;
        place-self: center;
    }
`;

const SidebarNavStyles = styled.nav`
    ul {
        display: flex;
        flex-direction: column;
    }

    a,
    button {
        --btn-padding: 0.4rem 0.6rem;
        &.selected {
            border-bottom-color: currentColor;
        }
    }
`;

interface DrawerProps {
    sidebarActive: boolean;
}

const DrawerStyles = styled.aside<DrawerProps>`
    position: fixed;
    min-height: 100%;
    background-color: ${(props) => props.theme.colors.white};
    width: 400px;
    max-width: 100vw;
    top: 0;
    left: -400px;
    z-index: 99;
    box-shadow: ${(props) => (props.sidebarActive ? props.theme.bShadow.dbl : 'none')};
    height: 90vh;
    max-height: 1000px;
    overflow: ${(props) => (props.sidebarActive ? 'scroll' : 'visible')};
    transition: all 400ms ease-in-out;
    transform: ${(props) => (props.sidebarActive ? 'translateX(400px)' : 'translateX(0)')};
    ${removeScrollbars}
    padding: calc(var(--nav-height) + calc(var(--nav-height)/2)) ${({ theme }) => theme.spacing.s} 0 ${({ theme }) =>
        theme.spacing.s};
    li {
        grid-column: 2 / -2;
    }
    [aria-label='breadcrumbs'] {
        margin: 0 0 ${({ theme }) => theme.spacing.m} 0;
    }
    .sidebar-content {
        padding: 1rem;
        background-color: ${(props) => props.theme.colors._grey[500]};
    }
`;

export { SidebarNavStyles, ToplineNavStyles, FloatingNavStyles, DrawerStyles };
