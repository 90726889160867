import useInView from 'hooks/useInView';
import React from 'react';
import styled, { css } from 'styled-components';

export interface ColumnProps {
    className?: string;
    id?: string;
    children?: React.ReactNode;
    spacing?: number | number[];
    breakpoints?: number[];
    widths?: (number | string)[];
    offsets?: number[];
    maxColumnCount?: number;
    colKey?: string;
    idx?: number;
    fadeIn?: boolean;
}

interface ColumnWrapperProps {
    maxColumnCount: number;
    spacingY: number;
    spacingX: number;

    breakpointOne: number | null;
    breakpointTwo: number | null;
    breakpointThree: number | null;

    widthOne: string | number | null;
    widthTwo: string | number | null;
    widthThree: string | number | null;

    offsetOne: number | null;
    offsetTwo: number | null;
    offsetThree: number | null;
}

const Wrapper = styled.div<ColumnWrapperProps>`
    /* transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275); */
    width: 100%;
    flex: 1 1 0;
    flex-basis: ${(props) => (12 / props.maxColumnCount / 12) * 100}% !important;
    padding: ${(props) => props.spacingY}px ${(props) => props.spacingX}px;

    @media (min-width: ${(props) => props.breakpointOne}px) and (max-width: ${(props) =>
            props.breakpointTwo || 9999}px) {
        width: ${(props) => (typeof props.widthOne === 'number' ? `${(props.widthOne / 12) * 100}%` : null)};
        flex: ${(props) => (typeof props.widthOne === 'number' ? 'none !important' : null)};
        margin-left: ${(props) => (props.offsetOne ? `${(props.offsetOne / 12) * 100}% !important` : null)};
        &:not(.no-padding) {
            &:first-child {
                padding-left: 0;
            }
            &:last-child {
                padding-right: 0;
            }
        }
    }
    @media (min-width: ${(props) => props.breakpointTwo}px) and (max-width: ${(props) =>
            props.breakpointThree || 9999}px) {
        width: ${(props) => (typeof props.widthTwo === 'number' ? `${(props.widthTwo / 12) * 100}%` : null)};
        flex: ${(props) => (typeof props.widthTwo === 'number' ? 'none !important' : null)};
        margin-left: ${(props) => (props.offsetTwo ? `${(props.offsetTwo / 12) * 100}% !important` : null)};
    }
    @media (min-width: ${(props) => props.breakpointThree}px) and (max-width: 99999px) {
        width: ${(props) => (typeof props.widthThree === 'number' ? `${(props.widthThree / 12) * 100}%` : null)};
        flex: ${(props) => (props.widthThree !== 'auto' ? 'none !important' : null)};
        margin-left: ${(props) => (props.offsetThree ? `${(props.offsetThree / 12) * 100}% !important` : null)};
    }
`;

export const Column: React.FC<ColumnProps> = ({
    className,
    id,
    children,
    spacing = 0,
    breakpoints = [0],
    widths = ['auto'],
    offsets = [0],
    maxColumnCount = 12,
    colKey,
    idx,
    fadeIn = false,
}) => {
    // Breakpoints
    const breakpointOne = breakpoints[0];
    const breakpointTwo = breakpoints.length >= 1 ? breakpoints[1] : null;
    const breakpointThree = breakpoints.length >= 2 ? breakpoints[2] : null;

    // Widths
    const widthOne = widths[0];
    const widthTwo = widths.length >= 1 ? widths[1] : null;
    const widthThree = widths.length >= 2 ? widths[2] : null;

    // Offsets
    const offsetOne = offsets && offsets.length > 0 ? offsets[0] : null;
    const offsetTwo = offsets && offsets.length >= 1 ? offsets[1] : null;
    const offsetThree = offsets && offsets.length >= 2 ? offsets[2] : null;
    const [ref, isVisible] = useInView({
        root: null,
        rootMargin: '0px',
        threshold: 0.3,
        freezeOnceVisible: true,
        deactivate: !fadeIn,
    });
    return (
        <Wrapper
            maxColumnCount={maxColumnCount}
            breakpointOne={breakpointOne}
            breakpointTwo={breakpointTwo}
            breakpointThree={breakpointThree}
            widthOne={widthOne}
            widthTwo={widthTwo}
            widthThree={widthThree}
            offsetOne={offsetOne}
            offsetTwo={offsetTwo}
            offsetThree={offsetThree}
            spacingY={spacing[0] || spacing}
            spacingX={typeof spacing[1] === 'number' ? spacing[1] : spacing[0]}
            key={colKey}
            className={`column-${typeof idx === 'number' ? idx + 1 : 0} column-wrapper ${className || ''} ${
                fadeIn ? 'fadeIn' : ''
            } ${isVisible && fadeIn ? 'fade-in' : !isVisible && fadeIn ? 'hide' : ''}`}
            ref={ref}
        >
            {children}
        </Wrapper>
    );
};
