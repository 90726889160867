import { Product } from 'components/shop/types';
import SocialIcon from 'components/styles/general-elements/SocialIcon';
import { device } from 'components/styles/mixins';
import { formatSocialLinks } from 'core/constants';
import React from 'react';
import { Article } from 'src/types/sanity/schema';
import styled from 'styled-components';
import webShare, { genJournalShareData, genProductShareData, genShareLink, popupWindow } from '../../../core/share';
import { isBrowser } from '../../../utils/booleans';
// import dynamic from 'next/dynamic'

// const ClientOnlySocialWidgetsLinkStyle = dynamic(()=>import(''))

interface SocialWidgetStyleProps {
    type: 'vertical' | 'horizontal' | 'square';
    color?: string;
    product?: Product;
    article?: Article;
    className?: string;
    text?: string;
    iconBorder?: boolean;
}
interface SocialWidgetLinkProps {
    color?: string;
}

const SocialWidgetsStyle = styled.div<SocialWidgetStyleProps>`
    display: inline-flex;
    align-items: flex-start;
    flex-flow: column;

    .widget-wrapper {
        height: ${({ type }) => (type === 'square' ? '9rem' : 'auto')};
        width: ${({ type }) => (type === 'square' ? '9rem' : 'auto')};
        display: inline-flex;
        justify-content: center;
        align-items: center;
        flex-flow: ${({ type }) =>
            type === 'horizontal'
                ? 'row nowrap'
                : type === 'vertical'
                ? 'column nowrap'
                : type === 'square'
                ? 'row wrap'
                : 'column nowrap'};
    }

    .widget {
        padding: ${({ theme }) => theme.spacing.xs};
        @media ${device().medium} {
            padding: ${({ theme }) => theme.spacing.s};
        }
        margin: var(--social-widgets-margin);
        border: ${({ theme, iconBorder }) => (iconBorder ? theme.borders.base : 'none')};
        border-radius: 100px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
    }

    .text {
        max-width: 20ch;
        margin-bottom: ${({ theme }) => theme.spacing.s};
    }
`;

export const SocialWidgetsLinkStyle = styled.a<SocialWidgetLinkProps>`
    text-align: center;
    color: ${({ theme, color }) => color || theme.colors.white};

    svg {
        transition: ${({ theme }) => theme.transitions.base};
    }

    & svg:hover {
        cursor: pointer;
        transition: ${({ theme }) => theme.transitions.base};
        transform: scale(1.3);
        transform-origin: center;
    }
`;

const SocialWidgets: React.FC<SocialWidgetStyleProps> = ({ type, color, product, article, text, iconBorder }) => {
    let asShare = false;
    const links = { facebook: '', twitter: '' };
    if (article) {
        asShare = true;
        const data = genJournalShareData(article);
        links.facebook = genShareLink({ data, platform: 'facebook' });
        links.twitter = genShareLink({ data, platform: 'twitter' });
    }
    if (product) {
        asShare = true;
        const data = genProductShareData(product);
        links.facebook = genShareLink({ data, platform: 'facebook' });
        links.twitter = genShareLink({ data, platform: 'twitter' });
    }

    return (
        <SocialWidgetsStyle color={color} className="social-widgets" type={type} iconBorder={iconBorder}>
            {text && <p className="text heading-6 alt">{text}</p>}
            <div className="widget-wrapper">
                <SocialWidgetsLinkStyle
                    title={asShare ? 'Share on Facebook' : 'Visit us on Facebook'}
                    color={color}
                    className="widget"
                    target="_blank"
                    rel="noopener nofollow"
                    href={product || article ? links.facebook : formatSocialLinks('facebook')}
                    onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                        if (product || article) {
                            e.preventDefault();
                            popupWindow(links.facebook);
                        }
                    }}
                >
                    <SocialIcon name="facebook" />
                </SocialWidgetsLinkStyle>
                <SocialWidgetsLinkStyle
                    title={asShare ? 'Share on Twitter' : 'Visit us on Twitter'}
                    color={color}
                    className="widget"
                    target="_blank"
                    rel="noopener nofollow"
                    href={product || article ? links.twitter : formatSocialLinks('twitter')}
                    onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                        if (product || article) {
                            e.preventDefault();
                            popupWindow(links.twitter);
                        }
                    }}
                >
                    <SocialIcon name="twitter" />
                </SocialWidgetsLinkStyle>
                {!product && (
                    <SocialWidgetsLinkStyle
                        title={'Visit us on Instagram'}
                        color={color}
                        className="widget"
                        target="_blank"
                        rel="noopener nofollow"
                        href={formatSocialLinks('instagram')}
                    >
                        <SocialIcon name="instagram" />
                    </SocialWidgetsLinkStyle>
                )}
                {!product && (
                    <SocialWidgetsLinkStyle
                        title={'Visit us on Youtube'}
                        color={color}
                        className="widget"
                        target="_blank"
                        rel="noopener nofollow"
                        href={formatSocialLinks('youtube')}
                    >
                        <SocialIcon name="youtube" />
                    </SocialWidgetsLinkStyle>
                )}

                <SocialWidgetsLinkStyle
                    title={'Share'}
                    color={color}
                    className="widget webshare"
                    onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
                        if (isBrowser) {
                            event.preventDefault();
                            product
                                ? webShare(genProductShareData(product))
                                : article
                                ? webShare(genJournalShareData(article))
                                : null;
                        }
                    }}
                >
                    <SocialIcon name="share" />
                </SocialWidgetsLinkStyle>
            </div>
        </SocialWidgetsStyle>
    );
};

export default SocialWidgets;
