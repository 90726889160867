import { Product } from 'components/shop/types';
import ReactGA from 'react-ga';
import { getVariantById } from '../components/shop/helpers//variants/getVariant';
import { CartItemProductNode } from '../components/shop/interfaces';
import { isBrowser } from './booleans';
import { devLog } from './logs';
import { capitalize } from './stringFunctions';
import { unhyphenate } from './stringUtils';

export const GA_ID = process.env.GA_ID;
export const sgapikey = process.env.SENDGRID_API_KEY;
export type CookieStatus = 'accept' | 'decline' | '';

const GACommercePreFunnel = {
    viewProducts: { name: 'viewProducts', option: '' },
    viewPDP: { name: 'viewPDP', option: '' },
    modifyProduct: { name: 'modifyProduct', option: '' },
    addToCart: { name: 'addToCart', option: '' },
};
// const GACommerceFunnel = {
//     viewCart: { name: 'viewCart', step: 1, option: '' },
//     enterShippingAddress: { name: 'enterShippingAddress', step: 2, option: '' },
//     enterDeliveryDate: { name: 'enterDeliveryDate', step: 3, option: '' },
//     proceedToCheckout: { name: 'proceedToCheckout', step: 4, option: '' },
// };

export type GAEECPreFunnelType = 'viewProducts' | 'viewPDP' | 'modifyProduct' | 'imageView' | 'addToCart';
export type GAEECCheckoutFunnelType =
    | 'cart'
    | 'shipping-info'
    | 'email'
    | 'shipping-details'
    | 'note'
    | 'delivery-date-info'
    | 'delivery-date'
    | 'checkout';
export type CheckoutSection = 'bbff' | 'shopify';

export const initGA = (debug: boolean, exception?: boolean) => {
    if (!isBrowser || exception) return;

    if (window['GA_INITIALIZED'] !== true) {
        ReactGA.initialize('UA-137934618-1', {
            debug,
            // testMode: true,
            gaOptions: {
                // sampleRate: 5,
                // siteSpeedSampleRate: 10,
                // cookieDomain: 'auto',
                cookieFlags: 'SameSite=None; Secure',
                allowLinker: true,
            },
        });
        ReactGA.plugin.require('linker');
        ReactGA.plugin.execute('linker', 'autoLink', ['bumblebee-flower-farm.myshopify.com']);
        window['GA_INITIALIZED'] = true;
    }
};

function ensureGA() {
    if (isBrowser && !window['GA_INITIALIZED']) initGA(false);
}

export const logPageView = () => {
    if (!isBrowser) return;

    if (!window['GA_INITIALIZED']) return;

    devLog('logged pageview');

    // } else {
    ReactGA.set({ page: window.location.pathname });
    ReactGA.pageview(window.location.pathname);
    // }
};

export function logModalView(name: string) {
    if (!isBrowser) return;
    if (!window['GA_INITIALIZED']) return;
    devLog('Logged Modal view', name);
    ReactGA.modalview(name);
}

export const logEvent = (category = '', action = '', value: number, label = '', nonInteraction: boolean) => {
    if (category && action) {
        if (!window['GA_INITIALIZED']) return;
        devLog(`Logged Event`, category, action, value, label, nonInteraction);
        ReactGA.event({ category, action, value, label, nonInteraction });
    }
};

export const logException = (description = '', fatal = false) => {
    if (description) {
        if (!window['GA_INITIALIZED']) return;
        devLog('GA - Logged Exception', description, fatal);
        ReactGA.exception({ description, fatal });
    }
};

/**
 *
 * E-Ecommerce
 */

/**
 *
 * View Handler for Product Index's
 * Adding
 */

export function logProductIndexImpression(
    products: Product[],
    page?: string,
    label?: GAEECPreFunnelType,
    options?: { quantity: number; variantId: string; variantTitle: string },
): void {
    try {
        if (!window['GA_INITIALIZED']) return;
        products.map((p) => {
            let cv = p.store.variants[0];
            if (options && options.variantId) {
                const v = getVariantById(options.variantId, p);
                cv = v || cv;
            }
            ReactGA.plugin.require('ec');
            const data = {
                id: p.store.id,
                name: p.store.title + ' - ' + cv.store.title,
                sku: cv.store.sku,
                brand: 'Bumblebee Flower Farm',
                variant: cv.store.title,
                list: page || 'Product Index Page',
                price: cv.store.price,
                category: p.store.productType,
                quantity: (options && options.quantity) || 1,
            };
            ReactGA.plugin.execute('ec', 'addImpression', data);

            ReactGA.pageview(window.location.pathname);
            devLog('GA - Logged Product Index Impression');
        });
    } catch (err) {
        console.error('Error Logging Index Impression', err);
    }
}

/**
 *
 * Click handler for SingleProduct - BEFORE PDP
 */
export function logSingleProductClick(
    product: Product,
    options?: { quantity: number; variantId: string },
    page?: string,
    label?: GAEECPreFunnelType,
    handler?: () => void,
    debugMsg?: string,
) {
    try {
        if (!window['GA_INITIALIZED']) return;
        let cv = product.store.variants[0];
        if (options && options.variantId) {
            const v = getVariantById(options.variantId, product);
            cv = v || cv;
        }

        ReactGA.plugin.require('ec');
        const data = {
            id: product.store.id,
            name: product.store.title,
            sku: cv.store.sku, // seems to be required
            brand: 'Bumblebee Flower Farm', // Product brand (string).
            variant: cv.store.title, // Product variant (string).
            price: cv.store.price,
            category: product.store.productType,
            quantity: options?.quantity || 1,
        };
        ReactGA.plugin.execute('ec', 'addProduct', data);

        ReactGA.plugin.execute('ec', 'setAction', 'click', { list: page || '' });

        ReactGA.event({
            category: 'UX',
            action: 'Click on Product',
            label: label || '',
        });
        handler && handler();
        devLog('GA - Logged Single product click');
    } catch (err) {
        console.error('Error Logging Single Product Impression', err);
    }
}

/**
 *
 * View handler for PDP
 */
export function logPDPView(product: Product, page?: string, options?: { quantity: number; variantId: string }) {
    try {
        if (!window['GA_INITIALIZED']) return;
        let cv = product.store.variants[0];
        if (options && options.variantId) {
            const v = getVariantById(options.variantId, product);
            cv = v || cv;
        }
        ReactGA.plugin.require('ec');
        const data = {
            id: product.store.id,
            name: product.store.title + ' - ' + cv.store.title,
            sku: cv.store.sku,
            brand: 'Bumblebee Flower Farm',
            variant: cv.store.title,
            list: page || 'Product Index Page',
            price: cv.store.price,
            category: product.store?.productType,
        };
        ReactGA.plugin.execute('ec', 'addProduct', data);
        ReactGA.plugin.execute('ec', 'setAction', 'detail');
        // ReactGA.pageview(window.location.pathname);
        devLog('GA - Logged PDP View');
    } catch (err) {
        console.error('Error Logging PDP Impression', err);
    }
}

/**
 * @description Run once Item is in cart
 * @param cartItem
 * @param label
 *
 */
export function logAddToCart(
    cartItem: CartItemProductNode,
    // cartItems?: CartContextType['items'],
    label?: GAEECPreFunnelType,
): void {
    try {
        if (!window['GA_INITIALIZED']) return;
        const variant = getVariantById(cartItem.customization.currentVariant.store.gid, cartItem);
        if (!variant) return;
        ReactGA.plugin.require('ec');

        const data = {
            id: variant.store.gid,
            name: cartItem.store.title,
            category: cartItem.store.productType,
            sku: variant.store.sku, // seems to be required
            price: variant.store.price,
            quantity: cartItem.customization.quantity,
            brand: 'Bumblebee Flower Farm', // Product brand (string).
            variant: variant.store.title, // Product variant (string).
        };
        ReactGA.plugin.execute('ec', 'addProduct', data);

        ReactGA.plugin.execute('ec', 'setAction', 'add');

        ReactGA.event({
            category: 'UX',
            action: 'Add to Cart',
            label: label || '',
        });
        devLog('GA - Logged Add to cart');
    } catch (err) {
        console.error('Error Logging ATC Action', err);
    }
}

/**
 *
 * @param cartItems
 * @param name
 * @param section
 * @param option Additional Checkout Option - Shipping handle, free, etc.
 *
 * Called after step is complete
 */
export function logCheckoutStep(
    cartItems: CartItemProductNode[],
    step: number,
    name: GAEECCheckoutFunnelType,
    section: CheckoutSection,
    option?: string,
): void {
    try {
        if (!window['GA_INITIALIZED']) return;
        // Initialize Plugin
        ReactGA.plugin.require('ec');

        // Add cart items
        cartItems.map((i) => {
            const tax = (parseFloat(i.customization.totalPrice) * 13.5).toFixed(2);
            const sp = /subscription/i.test(i.store.title) ? 20 : 10;
            ReactGA.plugin.execute('ec', 'setAction', 'addProduct', {
                id: i.customization.currentVariant.store.gid,
                name: i.store.title,
                category: i.store.productType,
                brand: 'Bumblebee Flower Farm',
                variant: i.customization.currentVariant.store.title,
                price: i.customization.currentVariant.store.price,
                quantity: i.customization.quantity,
            });
        });

        // Register Action // TODO Add Shipping Options
        ReactGA.plugin.execute('ec', 'setAction', 'checkout', {
            step,
            option: option || name,
        });

        // Send to GA
        switch (name) {
            case 'cart':
            case 'shipping-info':
                ReactGA.pageview(window.location.pathname);
                break;
            default:
                ReactGA.event({
                    category: 'Checkout',
                    action: capitalize(unhyphenate(name)),
                });
                break;
        }

        devLog(`GA Logged Checkout step - ${capitalize(unhyphenate(name))}`);
    } catch (err) {
        console.error('Error Logging CheckoutStep', err);
    }
}

export function logPurchase(items: CartItemProductNode[]): void {
    try {
        if (!window['GA_INITIALIZED']) return;
        ReactGA.plugin.require('ec');

        items.map((cartItem, idx) => {
            const tax = parseInt((parseFloat(cartItem.customization.totalPrice) * 13.5).toFixed(2));

            const sp = idx > 0 ? 0 : /subscription/gi.test(cartItem.store.title) ? 20 : 10;
            ReactGA.plugin.execute('ec', 'setAction', 'addProduct', {
                id: cartItem.customization.currentVariant.store.gid,
                name: cartItem.store.title,
                category: cartItem.store.productType,
                brand: 'Bumblebee Flower Farm',
                variant: cartItem.customization.currentVariant.store.title,
                price: cartItem.customization.currentVariant.store.price,
                quantity: cartItem.customization.quantity,
            });

            const transData = {
                id: cartItem.customization.currentVariant.store.id, // (Required) Transaction id (string).
                affiliation: 'Bumblebee Front End', // Affiliation (string).
                revenue: parseInt(cartItem.customization.totalPrice), // Revenue (number).
                tax, // Tax (number).
                shipping: sp, // Sh  // User added a coupon at checkout.
            };
            ReactGA.plugin.execute('ec', 'setAction', 'purchase', transData);
        });

        ReactGA.pageview(window.location.pathname);
        devLog('Log Purchase');
    } catch (err) {
        console.error('Error Logging Purchase', err);
    }
}
