import { DefaultTheme } from 'styled-components';

export const bbTheme: DefaultTheme = {
    animations: {
        beziers: {
            bounceOut: 'cubic-bezier(0.52, -0.4, 0, 1.28)',
        },
    },
    breakpoints: {
        xxs: 360,
        xs: 450,
        small: 600,
        medium_small: 768,
        medium: 900,
        medium_large: 1024,
        large: 1200,
        xl: 1440,
        xxl: 1800,
    },
    spacing: {
        none: '0',
        xs: '1.20rem',
        s: '2.40rem',
        m: '3.60rem',
        l: '4.80rem',
        xl: '6.40rem',
        xxl: '7.5rem',
        xxxl: '15rem',
        gutter: '3.9vw',
    },
    borderRadius: {
        sm: '5px',
        md: '15px',
        lg: '25px',
    },
    font: {
        heading: 'Lora',
        body: 'Source Sans Pro',
    },
    bShadow: {
        sm: '0px 4px 10px 0 rgba(0, 0, 0, 0.25)',
        md: '0 11px 20px 0 rgba(0, 0, 0, 0.25)',
        lg: '0 11px 40px 0 rgba(0, 0, 0, 0.25)',
        btm: '0 30px 40px 0 rgba(0, 0, 0, 0.20)',
        dbl: '0 11px 40px 0 rgba(0, 0, 0, 0.25), 0 2px 10px 0 rgba(0, 0, 0, 0.12)',
        logo: '100px 25px 74px #FFFFFF, -80px 25px 100px #FFFFFF',
        main_shadow: '250px 0px 4px rgba(0, 0, 0, 1)',
    },
    borders: {
        base: '1px solid hsla(0, 0%, 91.4%, 1)',
        transparent: '1px solid transparent',
    },
    transitions: { base: 'all 300ms ease-in-out' },
    maxWidth: '1280px',
    colors: {
        _primary: 'hsl(30, 55.6%, 96.5%)',
        _primaryOpaque: 'hsla(30, 55.6%, 96.5%, 0.7)',
        _secondary: 'hsl(248, 9.8%, 30%)',
        _secondaryOpaque: 'hsl(248, 9.8%, 30%, 0.7)',
        _red: 'hsl(0, 60%, 40%)',
        _text: { reg: '#393939', sec: '#514c4c', light: '#717171' },
        _lightGrey: '#E1E1E1',
        _darkGrey: '#4f4f4f',
        _offWhite: '#EDEDED',
        _bodySecondary: '#EFEFEF',
        _overlay: 'rgba(79, 79, 79, .5)',
        _submitBtn: '#e0c588',
        _christmasRed: '#a01229',
        _grey: {
            100: 'hsla(193, 16%, 89%, 0.6);',
            200: '#EEEEEE',
            300: '#CCCCCC',
            400: '#CCCCCC',
            500: '#999999',
            600: '#999999',
            700: '#666666',
            800: '#666666',
            900: '#333333',
            1000: '#333333',
        },
        primary_black: 'hsla(0, 0%, 7.8%, 1)',
        bumblebee_yellow: 'hsla(42, 100%, 50.2%, 1)',
        light_grey_soft_lines: 'hsla(0, 0%, 91.4%, 1)',
        grey_text_dark: 'hsla(0, 5.9%, 20%, 1)',
        grey_text: 'hsla(0, 0%, 50.2%, 1)',
        grey_text_light: 'hsla(0, 0%, 72.5%, 1)',
        black: 'hsla(0, 0%, 7.8%, 1)',
        white: 'hsla(0, 0%, 100%, 1)',
        success: 'hsla(163, 100%, 32.9%, 1)',
        error: 'hsla(2, 60.4%, 35.7%, 1)',
        backgrounds_light_cream: 'hsla(30, 55.6%, 96.5%, 1)',
        backgrounds_dark_cream: 'hsla(27, 37.3%, 80%, 1)',
        backgrounds_muted_honey: 'hsla(38, 100%, 87.8%, 1)',

        gradients: {
            muted_honey: 'linear-gradient(180deg, rgba(255, 232, 193, 0.75) 22.4%, rgba(255, 239, 212, 0.5) 70.83%)',
            soft_pink: 'linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), #FBF6F1',
            footer: 'linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(0deg, #FBF6F1, #FBF6F1)',
        },
    },

    typography: {
        maxPWidth: '75ch',
        font_family: {
            heading: 'Source Sans Pro',
            body: 'Source Sans Pro',
        },
        weight: {
            light: 300,
            normal: 400,
            medium: 500,
            bold: 600,
            extra_bold: 700,
        },
        size: {
            h1: '10.59rem',
            h2: '5.20rem',
            h3: '3.81rem',
            h4: '3.01rem',
            h5: '2.00rem',
            h6: '1.30rem',
            h6_alternate: '1.30rem',
            header_navigation_titles: '1.20rem',
            navigation: '1.60rem',
            featured_paragraph: '2.20rem',
            paragraph: '1.70rem',
            paragraph_italics: '1.60rem',
            dropcaps: '8.80rem',
            article_quotes: '3.01rem',
            caption_text: '1.41rem',
            caption_text_bold: '1.41rem',
            micro_caption_text: '1.41rem',
            alternate_display_sans_serif: '10.80rem',
            mobile_h1: '4.00rem',
            mobile_h2: '3.01rem',
            mobile_h3: '2.59rem',
            mobile_h4: '2.10rem',
            mobile_featured_paragraph: '1.90rem',
            footer_links: '1.60rem',
            loose_track: '1.79rem',
        },
    },
};
