import React from 'react';
import { motion } from 'framer-motion';

const loadingContainer = {
    width: '2rem',
    height: '2rem',
    display: 'flex',
    justifyContent: 'space-around',
    margin: '20rem auto',
};

const loadingCircle = {
    display: 'block',
    width: '0.5rem',
    height: '0.5rem',
    backgroundColor: 'black',
    borderRadius: '0.25rem',
};

const loadingContainerVariants = {
    start: {
        transition: {
            staggerChildren: 0.2,
        },
    },
    end: {
        transition: {
            staggerChildren: 0.2,
        },
    },
};

const loadingCircleVariants = {
    start: {
        y: '50%',
    },
    end: {
        y: '150%',
    },
};

const loadingCircleTransition = {
    duration: 0.5,
    yoyo: Infinity,
    ease: 'easeInOut',
};

interface ThreeDotsWaveProps {
    fluid?: boolean;
}

const ThreeDotsWave: React.FC<ThreeDotsWaveProps> = ({ fluid }) => {
    return (
        <motion.div
            className="three-dots-wave"
            style={fluid ? { ...loadingContainer, margin: 0 } : loadingContainer}
            variants={loadingContainerVariants}
            initial="start"
            animate="end"
        >
            <motion.span style={loadingCircle} variants={loadingCircleVariants} transition={loadingCircleTransition} />
            <motion.span style={loadingCircle} variants={loadingCircleVariants} transition={loadingCircleTransition} />
            <motion.span style={loadingCircle} variants={loadingCircleVariants} transition={loadingCircleTransition} />
        </motion.div>
    );
};

export default ThreeDotsWave;
