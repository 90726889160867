import mixins from 'components/styles/mixins';
import { Field } from 'formik';
import React from 'react';
import styled from 'styled-components';

interface CheckProps {
    checked: boolean;
}

const CheckIcon = styled.svg`
    fill: none;
    stroke: ${({ theme }) => theme.colors.white};
    stroke-width: 2px;
    position: relative;
    top: -4px;
`;

const StyledCheckbox = styled.div<CheckProps>`
    display: inline-block;
    width: 2rem;
    height: 2rem;
    background: ${({ checked, theme }) => (checked ? theme.colors.black : theme.colors.white)};
    border: ${({ theme }) => theme.borders.base};
    transition: all 150ms;
    input[type='checkbox']:focus + & {
        ${mixins.focusShadow}
    }
    ${CheckIcon} {
        visibility: ${({ checked }) => (checked ? 'visible' : 'hidden')};
    }
`;

const CheckboxContainer = styled.div`
    display: inline-flex;
    vertical-align: middle;
    cursor: pointer;

    input[type='checkbox'] {
        ${mixins.hideVisually}
    }
`;

interface CheckBoxInputProps {
    className?: string;
    checked: boolean;
    name: string;
    id: string;
    value: string | number | boolean;
}

const CheckboxInput: React.FC<CheckBoxInputProps> = ({ className, checked, name, id, value }) => (
    <CheckboxContainer className={className}>
        <Field id={id} type="checkbox" name={name} value={value} />
        <StyledCheckbox checked={checked}>
            <CheckIcon viewBox="0 0 24 24">
                <polyline points="20 6 9 17 4 12" />
            </CheckIcon>
        </StyledCheckbox>
    </CheckboxContainer>
);

export default CheckboxInput;
